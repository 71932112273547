import { Formik } from "formik";
import React, { useState } from "react";
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import Select from "react-select";
import * as yup from "yup";
import { useUser } from "./contexts/UserContext.tsx";
import LoadingIndicatorButton from "./ressources/LoadingIndicatorButton.js";
import { urls } from "./ressources/urls.js";
import { ImageProvider } from "./ressources/ImageProvider.js";

const schema = yup.object().shape({
	short_description: yup
		.string()
		.required("Required")
		.max(2000, `Should not exceed ${2000} characters`),
	email: yup.string().email(),
});

const typeOptions = [
	"Bug",
	"Feature suggestion",
	"Change suggestion",
	"Question",
	"Positive vibes",
	"Negative vibes",
].map((type) => {
	let renderIcon = <></>;
	if (type === "Positive vibes") {
		renderIcon = (
			<img
				src={ImageProvider.icons["herz.png"]}
				width="20"
				alt="Test"
				className="ms-1"
			/>
		);
	}
	if (type === "Negative vibes") {
		renderIcon = (
			<img
				src={ImageProvider.icons["sturm.png"]}
				width="20"
				alt="Test"
				className="ms-1"
			/>
		);
	}
	return Object({
		value: type,
		label: (
			<>
				{type}
				{renderIcon}
			</>
		),
	});
});

function FeedbackPage() {
	const { user, setUser } = useUser();
	const [selectedType, setSelectedType] = useState("Bug");
	const [showAlert, setShowAlert] = useState(false);

	function handleTypeChange(state) {
		setSelectedType(state.value);
	}

	return (
		<div className="FeedbackPage">
			<Container fluid className="mt-3 themed-text">
				<Row className="justify-content-center">
					<Col xs={12} lg={6}>
						<div className="display-6">Send feedback</div>
						<Formik
							validationSchema={schema}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								const requestOptions = {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify({
										email: values.email,
										content: `${selectedType}\n\n${values.short_description}`,
									}),
								};
								const response = await trackPromise(
									fetch(urls.baseUrl + urls.createFeedback, requestOptions),
								);
								const data = await response.json();
								if (data.code === 200) {
									setShowAlert(true);
								}

								setSubmitting(false);
								resetForm();
							}}
							initialValues={{
								email: "",
								short_description: "",
							}}
						>
							{({ handleSubmit, handleChange, values, touched, errors }) => (
								<Form noValidate onSubmit={handleSubmit}>
									<Form.Group controlId="email">
										<InputGroup className="mt-3">
											<InputGroup.Text>
												<b>E-mail (optional)</b>
											</InputGroup.Text>
											<Form.Control
												type="email"
												name="email"
												placeholder="Enter e-mail"
												value={values.email}
												onChange={handleChange}
												isInvalid={touched.email && errors.email !== undefined}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.email}
											</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>

									<Form.Group
										className="form-group-margin mt-2"
										controlId="type"
									>
										<b>Type</b>
										<div style={{ color: "black" }}>
											<Select
												className="basic-single mt-1"
												classNamePrefix="select"
												isSearchable={true}
												name="Type"
												defaultValue={typeOptions[0]}
												onChange={handleTypeChange}
												options={typeOptions}
											/>
										</div>
									</Form.Group>

									{selectedType === "Negative vibes" ? (
										<>
											<div className="display-6">;)</div>
										</>
									) : (
										<>
											<Form.Group
												className="form-group-margin mt-2"
												controlId="formShortDescription"
											>
												<Form.Label>
													<b>Short Description</b>
												</Form.Label>
												<Form.Control
													as="textarea"
													maxLength={2000}
													placeholder="Give a description"
													name="short_description"
													value={values.short_description}
													onChange={handleChange}
													isValid={
														touched.short_description &&
														!errors.short_description
													}
													isInvalid={!!errors.short_description}
												/>
												<Form.Text className="text-muted">
													Max. length: {values.short_description.length}/{2000}
												</Form.Text>
											</Form.Group>
											<div className="d-flex justify-content-center">
												<Button size="lg" variant="primary" type="submit">
													<LoadingIndicatorButton />
													Send
												</Button>
											</div>
										</>
									)}
									{showAlert && (
										<Alert className="mt-2">
											<Alert.Heading>Thank you</Alert.Heading>
											Feedback received.
										</Alert>
									)}
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default FeedbackPage;
