import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useUser } from "./contexts/UserContext.tsx";
import PendingRequestSpinner from "./ressources/PendingRequestSpinner.js";
import { urls } from "./ressources/urls.js";

function VerificationPage() {
	const { user, setUser } = useUser();
	const { token } = useParams(); // Extract token from URL
	const [alertMessage, setAlertMessage] = useState("");
	const [success, setSuccess] = useState(false);
	const [pendingVerification, setPendingVerification] = useState(true);

	useEffect(() => {
		async function verifyToken() {
			const requestUrl = `${urls.baseUrl + urls.verify}/${token}`;
			fetch(requestUrl)
				.then((response) => response.json())
				.then((data) => {
					setAlertMessage(data.message);
					if (data.code === 200) {
						setSuccess(true);
						setUser((prevUser) => {
							if (!prevUser) return prevUser;
							return {
								...prevUser,
								is_verified: true,
							};
						});
					}
				});
			setPendingVerification(false);
		}
		if (token) {
			verifyToken();
		}
	}, [setUser, token]);

	return (
		<div className="VerificationPage">
			<Container className="mt-3">
				{pendingVerification ? (
					<PendingRequestSpinner />
				) : (
					<Alert variant={success ? "success" : "warning"}>
						<Alert.Heading>
							{success ? "Account verified" : "Something went wrong"}
						</Alert.Heading>
						{alertMessage}
						<div>
							<Link to="/profile">Return to profile</Link>
						</div>
					</Alert>
				)}
			</Container>
		</div>
	);
}

export default VerificationPage;
