import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";
import {
	Navigate,
	Outlet,
	RouterProvider,
	createBrowserRouter,
	useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AboutPage from "./pages/AboutPage.tsx";
import AdminPage from "./pages/AdminPage.tsx";
import ChangelogPage from "./pages/ChangelogPage.tsx";
import ChatMessagePage from "./pages/ChatMessagePage.tsx";
import ContinuePage from "./pages/ContinuePage.tsx";
import CreateStoryPage from "./pages/CreateStoryPage.tsx";
import ExplorePage from "./pages/ExplorePage.tsx";
import FeedbackPage from "./pages/FeedbackPage.tsx";
import LandingPage from "./pages/LandingPage.tsx";
import LeaderboardPage from "./pages/LeaderboardPage.tsx";
import LoginPage from "./pages/LoginPage.tsx";
import NotFoundPage from "./pages/NotFoundPage.tsx";
import ProfilePage from "./pages/ProfilePage.tsx";
import RegistrationPage from "./pages/RegistrationPage.tsx";
import ReplacePartPage from "./pages/ReplacePartPage.tsx";
import ResetPasswordPage from "./pages/ResetPasswordPage.tsx";
import SearchPage from "./pages/SearchPage.tsx";
import StoryPage from "./pages/StoryPage.tsx";
import VerificationPage from "./pages/VerificationPage.tsx";
import VotePage from "./pages/VotePage.tsx";
import { UserProvider, useUser } from "./pages/contexts/UserContext.tsx";
import NavbarComponent from "./pages/ressources/NavbarComponent";
import PendingRequestSpinner from "./pages/ressources/PendingRequestSpinner.js";
import { urls } from "./pages/ressources/urls";
import CookieConsent from "./CookieConsent.tsx";
import Footer from "./pages/ressources/FooterComponent.js";
import ImpressumPage from "./pages/ImpressumPage.tsx";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage.tsx";

export default function App() {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			try {
				const response = await fetch(urls.baseUrl + urls.loadSessionUserUrl);
				const userData = await response.json();
				if (userData !== null) {
					setUser(userData);
				}
				if (response.status === 401) {
					setUser(null);
				}
			} catch (error) {
				setUser(null);
			}
			setIsLoading(false);
		}

		let cookiePreferences = localStorage.getItem("cookiePreferences");
		if (cookiePreferences) {
			cookiePreferences = JSON.parse(cookiePreferences);
			if (cookiePreferences.googleAnalytics === true) {
				initializeAnalytics();
			}
		}
		fetchData();
	}, []);

	/* Workaround for RequireAuth
  Page refresh with CTRL + R does not load user before RequireAuth is called.
  Hence Login page is served every time. "isLoading" checks when async func is ready and provides proper page.
  */
	if (isLoading) {
		return <PendingRequestSpinner />;
	}

	return (
		<UserProvider user={user} setUser={setUser}>
			<RouterProvider router={router} fallbackElement={<Spinner />} />
		</UserProvider>
	);
}

export function useAuthenticatedUserContext() {
	const { user, setUser } = useUser();

	if (!user) {
		// This throw is to ensure TypeScript knows the return will never be reached if user is null.
		throw new Error("User not authenticated");
	}

	// TypeScript now understands that user cannot be null here
	return { user, setUser };
}

function RequireAuth({ children }) {
	const user = useUser();
	const location = useLocation();
	if (!user.user) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}
	return children;
}

function RequireAdmin({ children }) {
	const user = useUser();
	if (!user.user?.is_admin) {
		return <Navigate to="/" replace />;
	}
	return children;
}

function handlePageTracking(pathname) {
	ReactGA.send({ hitType: "pageview", page: pathname });
}

const applyTrackingToRoutes = (routes) => {
	for (const route of routes) {
		const originalAction = route.action;
		route.action = async (...args) => {
			if (originalAction) {
				await originalAction(...args);
			}
			handlePageTracking(route.path);
		};
		if (route.children) {
			applyTrackingToRoutes(route.children);
		}
	}
};

const routes = [
	{
		Component: Layout,
		children: [
			{ path: "/", element: <LandingPage /> },
			{
				path: "/admin",
				element: (
					<RequireAdmin>
						<AdminPage />
					</RequireAdmin>
				),
			},
			{ path: "/changelog", element: <ChangelogPage /> },
			{ path: "/feedback", element: <FeedbackPage /> },
			{
				path: "/chat_messages",
				element: (
					<RequireAuth>
						<ChatMessagePage />
					</RequireAuth>
				),
			},
			{
				path: "/create",
				element: (
					<RequireAuth>
						<CreateStoryPage />
					</RequireAuth>
				),
			},
			{ path: "/search", element: <SearchPage /> },
			{ path: "/explore", element: <ExplorePage /> },
			{
				path: "/leaderboard",
				element: (
					<RequireAuth>
						<LeaderboardPage />
					</RequireAuth>
				),
			},
			{ path: "/about", element: <AboutPage /> },
			{ path: "/privacypolicy", element: <PrivacyPolicyPage /> },
			{ path: "/impressum", element: <ImpressumPage /> },
			{
				path: "/story/:paramStoryId?/:paramBranchId?",
				element: (
					<RequireAuth>
						<StoryPage />
					</RequireAuth>
				),
			},
			{ path: "/login", element: <LoginPage /> },
			{ path: "/register", element: <RegistrationPage /> },
			{ path: "/reset_password/:token?", element: <ResetPasswordPage /> },
			{ path: "/verify/:token?", element: <VerificationPage /> },
			{
				path: "/continue",
				element: (
					<RequireAuth>
						<ContinuePage />
					</RequireAuth>
				),
			},
			{
				path: "/replace_part",
				element: (
					<RequireAuth>
						<ReplacePartPage />
					</RequireAuth>
				),
			},
			{
				path: "/vote",
				element: (
					<RequireAuth>
						<VotePage />
					</RequireAuth>
				),
			},
			{
				path: "/profile/:paramUserName?",
				element: (
					<RequireAuth>
						<ProfilePage />
					</RequireAuth>
				),
			},
			{ path: "/path_error", element: <NotFoundPage /> },
			{ path: "*", element: <NotFoundPage /> },
		],
	},
];

applyTrackingToRoutes(routes[0].children);

const router = createBrowserRouter(routes);

const initializeAnalytics = () => {
	ReactGA.initialize("G-PNV8CCTFWD");
	// Optionally track a page view here
	ReactGA.send({ hitType: "pageview", page: "/" });
};

function Layout() {
	const [darkMode, setDarkMode] = useState(false);

	const toggleDarkMode = () => {
		window.localStorage.setItem("displayMode", !darkMode ? "dark" : "light");
		setDarkMode(!darkMode);
	};

	useEffect(() => {
		if (window.localStorage.getItem("displayMode") === "dark") {
			setDarkMode(true);
		}
		if (darkMode) {
			document.documentElement.setAttribute("data-theme", "dark");
		} else {
			document.documentElement.removeAttribute("data-theme");
		}
	}, [darkMode]);
	return (
		<>
			<div
				className={darkMode ? "dark-mode" : ""}
				style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
			>
				<ScrollToTop />
				<header />
				<NavbarComponent toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
				<main style={{ flex: 1 }}>
					<CookieConsent onAccept={initializeAnalytics} onReject={null} />
					<Outlet />
				</main>
				<Footer />
			</div>
		</>
	);
}
