import { Card, Badge, Button } from "react-bootstrap";
import CycleProgressBar from "../CycleProgressBar";
import { Link } from "react-router-dom";
import { ImageProvider } from "./ImageProvider";
import { getThresholdColorForRatio, postJson, shortenDate } from "./utils";
import { EyeFill } from "react-bootstrap-icons";
import { LanguageWithFlag } from "./MicroComponents.tsx";
import { useState, useEffect } from "react";
import { urls } from "./urls.js";

function StoryCard({ story, maxWidth = "350px" }) {
	const [imageSource, setImageSource] = useState(null);

	async function loadImage() {
		if (story.picture.length < 20) {
			setImageSource(ImageProvider.storybanner[story.picture]);
		} else {
			setImageSource(`https://cornuvotia.net/images/${story.picture}`);
		}
	}

	useEffect(() => {
		loadImage();
	}, [story]);

	return (
		<Card
			className="m-2 shadow-sm"
			style={{ borderRadius: "15px", maxWidth: maxWidth, minWidth: "200px" }}
		>
			<div
				style={{
					width: "100%",
					height: 0,
					paddingTop: "100%",
					position: "relative",
				}}
			>
				<Card.Img
					variant="left"
					src={imageSource}
					className="custom-image"
					style={{
						borderTopLeftRadius: "15px",
						borderTopRightRadius: "15px",
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
					}}
				/>
			</div>
			<Card.Header className="bg-light border-0">
				<div className="d-flex align-items-center justify-content-between">
					<div>
						<Badge
							bg={story.starts_at === null ? "info" : "warning"}
							className="me-2"
						>
							{story.starts_at === null ? "Original" : "Branch"}
						</Badge>
					</div>
					<div>
						<Badge bg="secondary" className="me-2">
							<EyeFill /> {story.views}
						</Badge>
						<Link
							to={`/story/${story.id}/${story.branch_id}`}
							state={{ story: story }}
						>
							<Button size="sm" variant="outline-primary">
								Show Story
							</Button>
						</Link>
					</div>
				</div>
				<div>
					{story.is_in_last_cycle && story.remaining_cycles > 0 && (
						<Badge className="" bg="warning">
							Last {story.is_in_last_cycle} cycle
						</Badge>
					)}
				</div>
			</Card.Header>
			<Card.Body>
				<Card.Title>
					<span>{story.title}</span>
					<span
						className="text-muted"
						style={{ fontSize: "0.8rem", fontStyle: "italic" }}
					>
						{" "}
						by {story.creator_name}
					</span>
				</Card.Title>
				<Card.Subtitle
					className="text-muted mb-3"
					style={{ fontSize: "0.95rem" }}
				>
					{story.short_description}
				</Card.Subtitle>
				<div className="badge-container mb-3">
					{story.tags.map((tag, index) => (
						<Badge pill bg="primary" text="light" className="mx-1" key={tag}>
							{tag}
						</Badge>
					))}
				</div>
				<div className="info-badges mb-1">
					{story.n_votes !== 0 && (
						<Badge bg="light" text="dark" className="me-2">
							Votes: {story.n_votes}
						</Badge>
					)}
					<Badge bg="light" text="dark" className="me-2">
						<LanguageWithFlag withTitle={true} language={story.language} />
					</Badge>
					<Badge bg="light" text="dark" className="me-2">
						Parts: {story.n_parts} ({story.n_tokens} words)
					</Badge>
					<Badge
						bg={getThresholdColorForRatio(story.n_writers / story.max_writers)}
						text="dark"
						className="me-2"
					>
						Writers: {story.n_writers}/{story.max_writers}
					</Badge>
					<Badge
						bg={getThresholdColorForRatio(
							story.n_proposals / story.max_proposals,
						)}
						text="dark"
						className="me-2"
					>
						Proposals: {story.n_proposals}/{story.max_proposals}
					</Badge>
					<Badge bg="light" text="dark" className="me-2">
						Words: {story.n_tokens_min}-{story.n_tokens_max}
					</Badge>
					<Badge bg="light" text="dark" className="me-2">
						Remaining cycles: {story.remaining_cycles}/{story.n_cycles}
					</Badge>
					<Badge bg="light" text="dark" className="me-2">
						Branches: {story.n_branches}/{story.max_branches}
					</Badge>
					<Badge bg="light" text="dark" className="me-2">
						End date: {shortenDate(story.end_date)}
					</Badge>
				</div>
				<div className="mb-2">
					{story.only_voted_voters && (
						<Badge bg="secondary" className="me-2">
							Only voted voters
						</Badge>
					)}
					{story.writers_can_always_propose && (
						<Badge bg="secondary" className="me-2">
							Writers can always propose
						</Badge>
					)}
					{story.nsfw && (
						<Badge bg="danger" className="me-2">
							NSFW
						</Badge>
					)}
					{story.allow_ai_proposals && (
						<Badge bg="secondary" className="me-2">
							AI proposals
						</Badge>
					)}
				</div>
				<CycleProgressBar story={story} sameRow={false} className="mb-3" />
			</Card.Body>
		</Card>
	);
}

export default StoryCard;
