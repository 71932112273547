import React from "react";
import AchievementJson from "../../../shared_ressources/achievements.json";
import { Tooltip } from "react-tooltip";

const AchievementColorMapping = AchievementJson.ACHIEVEMENT_COLOR_MAPPING;
const NameCategoryMapping = AchievementJson.NAME_CATEGORY_MAPPING;
const AchievementColors = AchievementJson.ACHIEVEMENT_COLORS;
const AchievementLevel = AchievementJson.ACHIEVEMENT_LEVEL;
const AchievementExplanation = AchievementJson.ACHIEVEMENT_EXPLANATIONS;
const AchievementCategory = AchievementJson.ACHVIEVEMENT_CATEGORY;

const AchievementBadgesComponent = ({ userAchievements }) => {
	return (
		<div>
			{userAchievements.map((achievement, index) => (
				<React.Fragment key={`${achievement}-${index}`}>
					<span
						className="me-1 badge"
						style={{
							backgroundColor:
								AchievementColors[AchievementColorMapping[achievement]],
						}}
						data-tooltip-id={`badge-tooltip-${index}`}
						data-tooltip-variant="dark"
					>
						{achievement}
					</span>
					<Tooltip
						id={`badge-tooltip-${index}`}
						place="bottom-start"
						style={{ maxWidth: "350px" }}
					>
						<div>
							{achievement !== "Noble Donor" && "More than "}
							<span
								style={{
									color:
										AchievementColors[AchievementColorMapping[achievement]],
									fontWeight: "bold",
								}}
							>
								{
									AchievementLevel[NameCategoryMapping[achievement]][
										AchievementColorMapping[achievement]
									]
								}{" "}
								{AchievementCategory[NameCategoryMapping[achievement]]}
							</span>
						</div>
						<hr />
						{AchievementExplanation[NameCategoryMapping[achievement]]}
					</Tooltip>
				</React.Fragment>
			))}
		</div>
	);
};

export default AchievementBadgesComponent;
