import React from "react";
import { createRoot } from "react-dom/client";
import "./App.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");

// Create a root.
const root = createRoot(container);

// Initial render
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

reportWebVitals();
