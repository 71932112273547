import React from "react";
import { Container } from "react-bootstrap";
import { PrivacyPolicyEnglish } from "./ressources/PrivacyPolicy";

function PrivacyPolicyPage() {
	return (
		<div className="PrivacyPolicyPage">
			<Container fluid className="mt-3 themed-text">
				<PrivacyPolicyEnglish />
			</Container>
		</div>
	);
}

export default PrivacyPolicyPage;
