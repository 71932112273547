import { Button, NavDropdown } from "react-bootstrap";
import {
	Book,
	Discord,
	GraphUp,
	House,
	InfoSquare,
	MoonFill,
	People,
	PersonCircle,
	RocketTakeoff,
	Search,
	SunFill,
	Twitter,
} from "react-bootstrap-icons";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo2 from "../../images/icon_white.svg";
import { urls } from "./urls";

import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/UserContext.tsx";
import MessagesIndicator from "./MessageIndicator";

const NavbarComponent = ({ darkMode, toggleDarkMode }) => {
	const { user, setUser } = useUser();
	const [expanded, setExpanded] = useState(false);
	const iconSize = 35;

	useEffect(() => {}, []);

	const handleLogout = async () => {
		const response = await fetch(urls.baseUrl + urls.logoutUrl, {
			method: "GET",
		});

		const logoutResponse = await response.json();

		if (logoutResponse.error) {
			console.log(logoutResponse.error);
		} else {
			setUser(null);
		}
	};

	return (
		<Navbar
			className="Navbar"
			data-bs-theme="dark"
			expand="lg"
			expanded={expanded}
			sticky="top"
			style={{ backgroundColor: "#6A2C70" }}
		>
			<Navbar.Brand
				as={Link}
				to="/"
				className="ms-3 app-logo d-flex align-items-center"
				style={{ color: "white" }}
			>
				<img
					src={logo2}
					width="32"
					height="32"
					className="d-inline-block align-top"
					alt="React Bootstrap logo"
				/>
				<span style={{ color: "#FFFFFF" }}>Cornu</span>
				<span style={{ color: "#ffa600" }}>votia</span>
				{/* <Link to="/changelog"><Badge bg='secondary' className='me-3' style={{ fontSize: 12 }}>v. 0.1</Badge></Link> */}
			</Navbar.Brand>
			<Navbar.Toggle
				aria-controls="basic-navbar-nav"
				onClick={() => setExpanded(!expanded)}
			/>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="me-auto">
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/"
					>
						<House className="mx-1" /> Home
					</Nav.Link>
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/create"
					>
						<Book className="mx-1" /> Create
					</Nav.Link>
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/search"
					>
						<Search className="mx-1" /> Search
					</Nav.Link>
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/explore"
					>
						<RocketTakeoff className="mx-1" /> Explore
					</Nav.Link>
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/leaderboard"
					>
						<GraphUp className="mx-1" /> Leaderboard
					</Nav.Link>
					<NavDropdown
						className="ms-2"
						title={
							<span style={{ color: "white" }}>
								<People className="mx-1" />
								Socials
							</span>
						}
						id="dd"
					>
						<NavDropdown.Item
							href="https://discord.gg/grwJAzg8PH"
							target="_blank"
						>
							<Discord className="me-1" size={"1.5rem"} />
							Discord
						</NavDropdown.Item>
						<NavDropdown.Item
							href="https://www.twitter.com/cornuvotia"
							target="_blank"
						>
							<Twitter className="me-1" size={"1.5rem"} />
							Twitter
						</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link
						onClick={() => setExpanded(false)}
						as={Link}
						className="ms-2"
						style={{ color: "white" }}
						to="/about"
					>
						<InfoSquare className="mx-1" /> Information
					</Nav.Link>
				</Nav>
			</Navbar.Collapse>

			<Nav className="justify-content-end me-2">
				<div>
					{user ? (
						<>
							<Link to="/profile" className="ms-1">
								<Navbar.Text>
									<MessagesIndicator />
								</Navbar.Text>
							</Link>
							<Button
								className="ms-2"
								variant="secondary"
								as={Link}
								to="/"
								onClick={() => {
									handleLogout();
								}}
							>
								Logout
							</Button>
						</>
					) : (
						<>
							<Button as={Link} className="ms-1" to="/login" variant="success">
								Sign in
							</Button>
						</>
					)}
					<span
						className="ms-2 mt-1"
						style={{ cursor: "pointer", color: "orange" }}
						onClick={() => {
							toggleDarkMode();
						}}
						onKeyUp={() => {
							toggleDarkMode();
						}}
					>
						{darkMode ? (
							<SunFill size={iconSize - 4} />
						) : (
							<MoonFill size={iconSize - 4} />
						)}
					</span>
				</div>
			</Nav>
		</Navbar>
	);
};

export default NavbarComponent;
