import React from "react";
import { Container } from "react-bootstrap";

function ImpressumPage() {
	return (
		<div className="AboutePage">
			<Container fluid className="mt-3 themed-text">
				<div className="display-6 mb-2">Impressum</div>
				<p>
					Fabian Fischer
					<br />
					c/o Postflex #5672
					<br />
					Emsdettener Str. 10
					<br />
					48268 Greven
					<br />
					Germany
					<br />
					Keine Pakete oder Päckchen - Annahme wird verweigert!
					<br />
					E-mail: mail@cornuvotia.net
					<br />
				</p>
			</Container>
		</div>
	);
}

export default ImpressumPage;
