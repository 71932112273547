import React, { useEffect, useState, useRef } from "react";
import {
	Alert,
	Badge,
	Button,
	Card,
	Container,
	ListGroup,
} from "react-bootstrap";
import {
	Bezier2,
	ChatSquareHeart,
	FilePlus,
	HandThumbsUp,
} from "react-bootstrap-icons";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { trackPromise } from "react-promise-tracker";
import { Link, useLocation } from "react-router-dom";
import "./Components.css";
import { useUser } from "./contexts/UserContext.tsx";
import { GrayBackgroundBox, TextBoxStyle } from "./ressources/CustomStyles.js";
import LoadingIndicatorButton from "./ressources/LoadingIndicatorButton.js";
import SlateReadOnly from "./ressources/SlateReadOnly.js";
import StoryAuthorsCard from "./ressources/StoryAuthorsCard.js";
import StoryCard from "./ressources/StoryCard.js";
import { VerticallyCenteredModal } from "./ressources/VerticallyCenteredModal.js";
import { urls } from "./ressources/urls.js";
import {
	createOffenseReport,
	postJson,
	shortenDate,
} from "./ressources/utils.js";
import type StoryBranch from "./types/types.StoryBranch.ts";

export default function VotePage() {
	const { user } = useUser();
	const location = useLocation();
	const [story, setStory] = useState<StoryBranch>(
		location.state ? location.state.story : null,
	);
	const [alertState, setAlertState] = useState({
		showAlert: false,
		variant: "success",
		message: "",
		heading: "",
	});
	const [hasVoted, setHasVoted] = useState(null);
	const [proposals, setProposals] = useState<Array<any>>([]);
	const [storyParts, setStoryParts] = useState([]);
	const [page, setPage] = useState(0);

	const [modalShow, setModalShow] = useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [userReportedProposal, setUserReportedProposal] = useState(false);
	const reportCacheRef = useRef({});

	useEffect(() => {
		if (!story?.is_data_complete) {
			postJson(urls.getStoryBranch, {
				story_id: story.id,
				branch_id: story.branch_id,
			}).then((data) => {
				setStory(data.story);
			});
		} else {
			postJson(urls.loadProposalsUrl, {
				story_id: story.id,
				branch_id: story.branch_id,
			}).then((data) => {
				setProposals(data.proposals);
				setHasVoted(data.hasVoted);
				if (data.hasVoted) {
					setPage(
						data.proposals.findIndex(
							(proposal) => proposal.id === data.hasVoted,
						),
					);
				}

				postJson(urls.storyAsOneUrl, {
					story_id: story.id,
					branch_id: story.branch_id,
				}).then((storyPartData) => setStoryParts(storyPartData.parts));
				if (data.error) {
					setAlertState({
						showAlert: true,
						variant: "warning",
						message: data.error,
						heading: "Voting message",
					});
				} else {
					if (data.proposals.length === 0) {
						setAlertState({
							showAlert: true,
							variant: "info",
							message: "No proposals were submitted to continue the story :(",
							heading: "No proposals to vote on",
						});
					}
				}
			});
		}
	}, [story, hasVoted]);

	useEffect(() => {
		if (proposals.length === 0) return;
		const cacheKey = `${proposals[page].id}-proposal`;
		console.log(reportCacheRef.current[cacheKey]);
		if (reportCacheRef.current[cacheKey] !== undefined) {
			setUserReportedProposal(reportCacheRef.current[cacheKey]);
			return;
		}
		postJson(urls.getUserOffenseReportForId, {
			reported_id: proposals[page].id,
			reported_type: "proposal",
		}).then((reportData) => {
			reportCacheRef.current[cacheKey] = reportData?.reports.length > 0;
			setUserReportedProposal(reportData?.reports.length > 0);
		});
	}, [page, proposals]);

	const handleVote = () => {
		const storyRequestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				story_id: story.id,
				branch_id: story.branch_id,
				proposal_id: proposals[page].id,
			}),
		};
		trackPromise(
			fetch(urls.baseUrl + urls.voteProposalUrl, storyRequestOptions)
				.then((response) => response.json())
				.then((data) => {
					if (data.error) {
						setAlertState({
							showAlert: true,
							variant: "warning",
							message: data.error,
							heading: "Can't vote right now",
						});
					} else {
						setHasVoted(data.hasVoted);
					}
				}),
		);
	};

	const handlePraiseClick = () => {
		postJson(urls.praise, { proposal_id: proposals[page].id });
		setProposals(
			proposals.map((proposal, index) =>
				index === page ? { ...proposal, is_praised: true } : proposal,
			),
		);
	};

	function handleReportProposal(text: string) {
		createOffenseReport(proposals[page].id, "proposal", text).then((data) => {
			const cacheKey = `${proposals[page].id}-proposal`;
			reportCacheRef.current[cacheKey] = true;
			setReportModalShow(false);
			setUserReportedProposal(true);
		});
	}

	// const handleVoteRetraction = () => {
	//     const storyRequestOptions = {
	//         method: 'POST',
	//         headers: { 'Content-Type': 'application/json' },
	//         body: JSON.stringify({ proposal_id: proposals[page].id, branch_id: story.branch_id, story_id: story.id })
	//     }
	//     trackPromise(fetch(urls.baseUrl + urls.voteRetractionUrl, storyRequestOptions)
	//         .then(response => response.json())
	//         .then(data => { setHasVoted(data.hasVoted); setAlertState({ showAlert: false }) }));
	// };

	return (
		<div className="VotePage">
			<Container fluid className="pt-3">
				<div>
					<Row>
						<Col xs={12} xl={2} className="d-flex justify-content-center mb-2">
							{story.is_data_complete && (
								<div>
									<StoryCard story={story} maxWidth="450px" />
								</div>
							)}
						</Col>
						<Col xl={1} lg={0} />
						<Col xs={12} xl={6}>
							{proposals[page] && (
								<div>
									<div className="display-6 mb-2 themed-text">
										<FilePlus />
										Proposals
									</div>
									<Row className="justify-content-md-center mt-2">
										<Col>
											<div className="d-flex justify-content-center">
												<PaginationControl
													page={page + 1}
													between={3}
													total={proposals.length}
													limit={1}
													changePage={(page) => {
														setPage(page - 1);
													}}
													ellipsis={1}
													last={true}
												/>
											</div>
										</Col>
									</Row>
									<Row className="justify-content-md-center mb-5">
										<Col>
											<Card>
												<Card.Body>
													<Card.Text
														style={{
															...TextBoxStyle,
															fontSize: user?.font_size,
															height: "auto",
															maxHeight: "900px",
														}}
													>
														<SlateReadOnly
															key={page}
															initialValue={proposals[page].content}
														/>
													</Card.Text>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</div>
							)}
						</Col>
						<Col xl={1} lg={0} />
						<Col xs={12} xl={2} className="mb-2">
							{alertState.showAlert && (
								<div>
									<Alert
										className=""
										variant={alertState.variant}
										onClose={() =>
											setAlertState((prevState) => ({
												...prevState,
												showAlert: false,
											}))
										}
										dismissible
									>
										<Alert.Heading>{alertState.heading}</Alert.Heading>
										<p>{alertState.message}</p>
									</Alert>
								</div>
							)}
							{proposals.length !== 0 && (
								<div
									className={GrayBackgroundBox}
									style={{ minWidth: "200px" }}
								>
									<h5>
										Proposal by{" "}
										<Link to={`/profile/${proposals[page].creator_name}`}>
											<Badge bg="secondary">
												{proposals[page].creator_name}{" "}
											</Badge>
										</Link>
									</h5>
									<div className="d-flex flex-column">
										<div className="d-flex flex-column">
											<Button
												className="display-9 fw-bold"
												disabled={
													hasVoted || proposals[page].creator === user?.id
												}
												size="lg"
												variant="vote"
												onClick={() => setModalShow(true)}
											>
												<LoadingIndicatorButton />
												<HandThumbsUp size={28} /> Upvote{" "}
												{hasVoted && `(${proposals[page].n_votes})`}
											</Button>
											<VerticallyCenteredModal
												show={modalShow}
												onHide={() => setModalShow(false)}
												onConfirm={() => {
													handleVote();
													setModalShow(false);
												}}
												title="Vote confirmation"
												body="Are you sure you want to vote for this proposal? Your vote is permanent and you can not vote for other proposals."
											/>
										</div>
										<Button
											className="display-9 fw-bold mt-2"
											disabled={
												proposals[page].is_praised ||
												proposals[page].creator === user?.id
											}
											size="sm"
											variant="vote"
											onClick={() => handlePraiseClick()}
										>
											<LoadingIndicatorButton />
											<ChatSquareHeart size={20} /> Praise
										</Button>
									</div>
									<hr />
									{proposals[page].id === hasVoted && (
										<Badge className="mb-2" bg="warning">
											Your vote
										</Badge>
									)}
									{proposals[page].creator === user?.id && (
										<Badge className="mb-2" bg="warning">
											Your proposal
										</Badge>
									)}
									<div>
										<Button
											size="sm"
											variant="outline-danger"
											onClick={() => setReportModalShow(true)}
											disabled={userReportedProposal}
										>
											Report proposal
										</Button>
										<VerticallyCenteredModal
											show={reportModalShow}
											onHide={() => setReportModalShow(false)}
											onConfirm={(text) => {
												handleReportProposal(text);
											}}
											hastextinput="true"
											title="Create a report"
											body="Give your reasons for the report. It will be reviewed asap."
										/>
									</div>
									<hr />
									<div>
										<b>Upvote: </b>
										<span className="text-muted">
											Give the author your vote to say 'I want the story to be
											continued like this'. The proposal with the most votes
											will be the continuation. If the story has a branching
											option more than one proposal may win and the story splits
											into different branches. You can only vote for one
											proposal and not retract your vote. You can see the vote
											count after you voted.
										</span>
									</div>
									<div>
										<b>Praise: </b>
										<span className="text-muted">
											Give the author some praise. This is a kind gesture to
											show you liked the proposal but maybe didn't vote for it.
											You can praise any number of proposals.
										</span>
									</div>
								</div>
							)}
						</Col>
					</Row>
					<Row className="mb-5">
						<Col xs={12} xl={2}>
							{story.is_data_complete && (
								<>
									{story.sibling_branches.length > 1 && (
										<div className="themed-text mt-3 mb-1">
											Vote on other Branches
										</div>
									)}
									<ListGroup>
										{story.sibling_branches.map((sibling, index) => {
											if (sibling[0] !== story.branch_id) {
												return (
													<ListGroup.Item
														onClick={() =>
															setStory({ id: story.id, branch_id: sibling[0] })
														}
														style={{ cursor: "pointer" }}
														variant="primary"
														action
														key={sibling[0]}
													>
														<Bezier2 className="me-1" />
														<b>#{index} -</b> {shortenDate(sibling[1])}
													</ListGroup.Item>
												);
											}
										})}
									</ListGroup>
								</>
							)}
						</Col>
						<Col xl={1} lg={0} />
						<Col xs={12} xl={6}>
							<StoryAuthorsCard
								storyParts={storyParts}
								story={story}
								userIsCreator={false}
							/>
						</Col>
						<Col xl={1} lg={0} />
						<Col xs={12} xl={2} />
					</Row>
				</div>
			</Container>
		</div>
	);
}
