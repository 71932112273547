import { Formik } from "formik";
// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect, useState, useMemo, useRef } from "react";
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Image,
	InputGroup,
	Modal,
	Row,
} from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useUser } from "./contexts/UserContext.tsx";
import ReCAPTCHA from "react-google-recaptcha";
import { CommunityGuidelines } from "./ressources/CommunityGuidlines.js";
import { GrayBackgroundBox } from "./ressources/CustomStyles.js";
import { ImageProvider } from "./ressources/ImageProvider.js";
import LoadingIndicatorButton from "./ressources/LoadingIndicatorButton.js";
import { PrivacyPolicyEnglish } from "./ressources/PrivacyPolicy.js";
import { urls } from "./ressources/urls.js";
import {
	getBackgroundImageStyle,
	generateUsername,
} from "./ressources/utils.js";
import logo2 from "../images/icon.svg";
import { EyeSlash, Eye, Lightbulb } from "react-bootstrap-icons";
import { Tooltip } from "react-tooltip";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Auth from "./ressources/GoogleAuth.js";

const schema = yup.object().shape({
	email: yup.string().email().required(),
	username: yup
		.string()
		.required()
		.matches(
			/^\w+$/,
			"Username can only contain letters, numbers and underscores.",
		),
	password: yup.string().min(8).required(),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), undefined], "Passwords must match"),
	community_guidlines: yup.bool().oneOf([true], "Field must be checked"),
	privacy_policy: yup.bool().oneOf([true], "Field must be checked"),
});

const RegisterForm = () => {
	const { user, setUser } = useUser();
	const [error, setError] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [eyeToggle, setEyeToggle] = useState(false);
	const [modalText, setModalText] = useState<React.JSX.Element | null>(null);
	const [presettings, setPresettings] = useState({
		username: "",
	});
	const recaptchaRef = useRef<ReCAPTCHA | null>(null);

	function handleOpenModal(privacyPolicy: boolean) {
		if (privacyPolicy) {
			setModalText(<PrivacyPolicyEnglish />);
		} else {
			setModalText(<CommunityGuidelines />);
		}
		setShowModal(true);
	}
	const handleCloseModal = () => setShowModal(false);

	function handleGenerateUsernameClick(setFieldValue) {
		setFieldValue("username", generateUsername());
	}

	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	const initialValues = useMemo(
		() => ({
			email: "",
			username: presettings.username,
			password: "",
			confirmPassword: "",
			avatar: "questionmark.svg",
			community_guidlines: false,
			privacy_policy: false,
		}),
		[presettings.username],
	);

	return (
		<div
			className="RegistrationPage"
			style={getBackgroundImageStyle("register", 150)}
		>
			<Container fluid className="pt-3">
				<Row className="justify-content-md-center ">
					<Col xs={12} md={6} xl={4} xxl={3}>
						<div className={GrayBackgroundBox}>
							<div
								className="app-logo d-flex justify-content-center align-items-center"
								style={{ fontSize: "3rem" }}
							>
								<img
									src={logo2}
									height="80"
									className="d-inline-block align-top"
									alt="Cornuvotia logo"
								/>
								<div className="mt-3">
									<span>Cornu</span>
									<span style={{ color: "#ffa600" }}>votia</span>
								</div>
							</div>
							<div className="display-6 mb-3 d-flex justify-content-center">
								Sign up
							</div>
							<GoogleOAuthProvider clientId="886787602006-83qsc5suf010nthh9qs4cp2u05m56mqh.apps.googleusercontent.com">
								<Auth setUser={setUser} />
							</GoogleOAuthProvider>
							<div className="d-flex justify-content-center mt-2">
								<Col xs={5}>
									<hr />
								</Col>
								<Col xs={2} className="text-center">
									OR
								</Col>
								<Col xs={5}>
									<hr />
								</Col>
							</div>
							<Formik
								validationSchema={schema}
								onSubmit={async (values, { setSubmitting }) => {
									if (!recaptchaRef.current) {
										setError("Please solve the reCAPTCHA!");
										return false;
									}
									const recaptchaValue = recaptchaRef.current.getValue();
									if (!recaptchaValue) {
										setError("Please solve the reCAPTCHA!");
										return false;
									}

									const response = await trackPromise(
										fetch(urls.baseUrl + urls.registrationUrl, {
											method: "POST",
											headers: { "Content-Type": "application/json" },
											body: JSON.stringify({
												registrationForm: values,
												token: recaptchaValue,
											}),
										}),
									);

									const userData = await response.json();

									if (userData.error) {
										recaptchaRef.current.reset();
										setError(userData.error);
									} else {
										setUser(userData);
										navigate("/");
									}
									setSubmitting(false);
								}}
								initialValues={initialValues}
								enableReinitialize
							>
								{({
									handleSubmit,
									handleChange,
									setFieldValue,
									values,
									touched,
									errors,
								}) => (
									<Form noValidate onSubmit={handleSubmit}>
										{error && (
											<div>
												<Alert variant="danger">{error}</Alert>
											</div>
										)}
										<Form.Group controlId="email">
											<Form.Label>E-mail</Form.Label>
											<Form.Control
												type="email"
												name="email"
												placeholder="Enter e-mail"
												maxLength={100}
												value={values.email}
												onChange={handleChange}
												isInvalid={touched.email && errors.email !== undefined}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.email}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group controlId="username" className="mt-2">
											<Form.Label>Username</Form.Label>
											<div style={{ position: "relative" }}>
												<Form.Control
													type="text"
													name="username"
													placeholder="Choose a username"
													value={values.username}
													onChange={handleChange}
													minLength={2}
													maxLength={20}
													isInvalid={
														touched.username && errors.username !== undefined
													}
												/>
												<span
													style={{
														cursor: "pointer",
														position: "absolute",
														top: "50%",
														right: "10px",
														transform: "translateY(-50%)",
													}}
													onClick={(event) =>
														handleGenerateUsernameClick(setFieldValue)
													}
													onKeyUp={(event) =>
														handleGenerateUsernameClick(setFieldValue)
													}
													data-tooltip-id="generate-tooltip"
													data-tooltip-content={"Generate a unique username"}
													data-tooltip-place="top"
													data-tooltip-variant="dark"
												>
													{<Lightbulb size={"1.5rem"} />}
												</span>
												<Tooltip id="generate-tooltip" />
											</div>
											<Form.Control.Feedback type="invalid">
												{errors.username}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group controlId="password" className="mt-2">
											<Form.Label>Password</Form.Label>
											<Row>
												<Col xs={12}>
													<div style={{ position: "relative" }}>
														<Form.Control
															type={eyeToggle ? "text" : "password"}
															name="password"
															placeholder="Choose a secure password"
															maxLength={100}
															value={values.password}
															onChange={handleChange}
															isInvalid={
																touched.password &&
																errors.password !== undefined
															}
														/>
														<span
															style={{
																cursor: "pointer",
																position: "absolute",
																top: "50%",
																right: "10px",
																transform: "translateY(-50%)",
															}}
															onClick={() => setEyeToggle(!eyeToggle)}
															onKeyUp={() => setEyeToggle(!eyeToggle)}
														>
															{eyeToggle ? (
																<EyeSlash size={"1.75rem"} />
															) : (
																<Eye size={"1.75rem"} />
															)}
														</span>
													</div>
												</Col>
											</Row>
											<Form.Control.Feedback type="invalid">
												{errors.password}
											</Form.Control.Feedback>

											<PasswordStrengthBar password={values.password} />
										</Form.Group>

										<Form.Group controlId="confirmPassword">
											<Form.Label>Confirm Password</Form.Label>
											<Form.Control
												type="password"
												name="confirmPassword"
												placeholder="Confirm your chosen password"
												value={values.confirmPassword}
												maxLength={100}
												onChange={handleChange}
												isInvalid={
													touched.confirmPassword &&
													errors.confirmPassword !== undefined
												}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.confirmPassword}
											</Form.Control.Feedback>
										</Form.Group>
										<Row>
											<Form.Group as={Col} controlId="formavatar">
												<InputGroup className="mt-3">
													<InputGroup.Text>
														<b>Avatar</b>
													</InputGroup.Text>
													<Form.Control
														as="select"
														name="avatar"
														value={values.avatar}
														onChange={handleChange}
													>
														{Object.keys(ImageProvider.avatars).map(
															(keyName, i) => (
																<option key={keyName} value={keyName}>
																	{keyName.replace(".svg", "")}
																</option>
															),
														)}
													</Form.Control>
												</InputGroup>
											</Form.Group>
											<div
												style={{ height: "10rem" }}
												className="d-flex justify-content-center align-items-center"
											>
												<Image
													height="100%"
													src={ImageProvider.avatars[values.avatar]}
												/>
											</div>
										</Row>
										<hr />
										<div className="mt-3 d-flex justify-content-center">
											<ReCAPTCHA
												ref={recaptchaRef}
												sitekey="6Lfg0okpAAAAAF4Bg9XzYfvV-o-hjH6MZCRP-PBu"
												onChange={() => setError("")}
											/>
										</div>
										<div className="mt-2">
											<Form.Check
												name="community_guidlines"
												isInvalid={
													touched.community_guidlines &&
													errors.community_guidlines !== undefined
												}
												checked={values.community_guidlines}
												onChange={handleChange}
												type={"checkbox"}
												label={
													<span>
														I have read an accept the&nbsp;
														<u
															style={{ cursor: "pointer" }}
															onClick={() => handleOpenModal(false)}
															onKeyUp={() => handleOpenModal(false)}
														>
															Community Guidelines
														</u>
													</span>
												}
											/>
											<Form.Check
												name="privacy_policy"
												isInvalid={
													touched.privacy_policy &&
													errors.privacy_policy !== undefined
												}
												checked={values.privacy_policy}
												onChange={handleChange}
												type={"checkbox"}
												label={
													<span>
														I have read an accept the&nbsp;
														<u
															style={{ cursor: "pointer" }}
															onClick={() => handleOpenModal(true)}
															onKeyUp={() => handleOpenModal(true)}
														>
															Privacy Policy
														</u>
													</span>
												}
											/>
											<Modal show={showModal} onHide={handleCloseModal}>
												<Modal.Header closeButton>
													<Modal.Title>Guidelines</Modal.Title>
												</Modal.Header>
												<Modal.Body>{modalText}</Modal.Body>
											</Modal>
										</div>
										<div className="d-flex justify-content-center my-2">
											<Button
												className="mt-3"
												size="lg"
												variant="primary"
												type="submit"
											>
												<LoadingIndicatorButton />
												Sign up
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default RegisterForm;
