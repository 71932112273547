const urls = {
	baseUrl: "/api/",
	loginUrl: "login",
	googleLoginUrl: "googlelogin",
	logoutUrl: "logout",
	registrationUrl: "register",
	loadBranchPartsUrl: "branchparts",
	saveTextUrl: "saveproposal",
	createStoryUrl: "createstory",
	loadProposalsUrl: "getproposals",
	deleteProposalUrl: "deleteproposal",
	voteProposalUrl: "vote",
	voteRetractionUrl: "voteretraction",
	storyAsOneUrl: "storyasone",
	userVotedUrl: "uservoted",
	loadSessionUserUrl: "loadsessionuser",
	getUserStories: "getuserstories",
	getUserStoryParts: "getuserstoryparts",
	getUserStoryProposals: "getuserstoryproposals",
	getUserVotes: "getuservotes",
	getUserBookmarks: "getuserbookmarks",
	getUserCollaborations: "getusercollaborations",
	getStoryBranch: "getstorybranch",
	storySearch: "searchstories",
	getStoryDetails: "getstorydetails",
	resetPassword: "resetpassword",
	changePassword: "changepassword",
	deleteAccount: "deleteaccount",
	deleteUser: "deleteuser",
	deleteStory: "deletestory",
	deleteNewStory: "deletenewstory",
	deletePart: "deletepart",
	updatePart: "updatepart",
	getOtherUser: "getotheruser",
	getLoadingPageInfo: "getloadingpageinfo",
	getUserToasts: "getusertoasts",
	deleteUserToasts: "deleteusertoasts",
	getUserStatistics: "getuserstatistics",
	createOffenseReport: "createoffensereport",
	getAllOffenseReports: "getalloffensereports",
	deleteOffenseReport: "deleteoffensereport",
	getOffenseReportData: "getoffensereportdata",
	banUser: "banuser",
	getAllUserBans: "getalluserbans",
	liftUserBan: "liftuserban",
	praise: "praise",
	incrementView: "incrementview",
	updateUserMail: "updateusermail",
	updateFontSize: "updatefontsize",
	updateUserLanguages: "updateuserlanguages",
	updateUserBio: "updateuserbio",
	createBookmark: "createbookmark",
	deleteBookmark: "deletebookmark",
	createChatMessage: "createchatmessage",
	getChatMessages: "getchatmessages",
	deleteChatMessage: "deletechatmessage",
	deleteAllChatMessages: "deleteallchatmessages",
	checkForNewChatMessages: "checkfornewchatmessages",
	getUserRunningStoryCount: "getuserrunningstorycount",
	getUserArchivedProposals: "getuserarchivedproposals",
	deleteArchivedProposal: "deletearchivedproposal",
	deleteArchivedProposals: "deletearchivedproposals",
	addCollaborator: "addcollaborator",
	removeCollaborator: "removecollaborator",
	getPrivateStoryData: "getprivatestorydata",
	getLeaderboards: "getleaderboards",
	deleteFeedback: "deletefeedback",
	createFeedback: "createfeedback",
	getAllFeedback: "getallfeedback",
	sendVerificationLink: "sendverificationlink",
	verify: "verify",
	getStoryOfTheWeek: "getstoryoftheweek",
	verifyCaptcha: "verifycaptcha",
	getUserGenerationCredits: "getusergenerationcredits",
	generateImage: "generateimage",
	suspendStory: "suspendstory",
	liftStorySuspension: "liftstorysuspension",
	addPartArtificially: "addpartartificially",
	addProposalArtificially: "addproposalartificially",
	getBranchInfoAdmin: "getbranchinfoadmin",
	getNewUsers: "getnewusers",
	changelog: "changelog",
	getUserOffenseReportForId: "getuseroffensereportforid",
};

export { urls };
