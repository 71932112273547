// biome-ignore lint/style/useImportType: <explanation>
import React from "react";
import type { FunctionComponent } from "react";
import ReactCountryFlag from "react-country-flag";
import { languages } from "./languages";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import MultiValue from "react-select/creatable";
import { useField, useFormikContext } from "formik";
import type { SingleValue } from "react-select";

type LanguageWithFlagProps = {
	language: string;
	withTitle?: boolean;
};

const LanguageWithFlag: FunctionComponent<LanguageWithFlagProps> = ({
	language,
	withTitle,
}) => {
	return (
		<span className="d-flex align-items-center">
			{withTitle && "Language:"}
			<ReactCountryFlag
				className="ms-1"
				countryCode={languages[language]}
				svg
			/>
			<span className="ms-1">{` ${language}`}</span>
		</span>
	);
};

const OfficialGoogleButton: FunctionComponent = ({ onClick }) => {
	return (
		// biome-ignore lint/a11y/useButtonType: <explanation>
		<button className="gsi-material-button" onClick={onClick}>
			<div className="gsi-material-button-state" />
			<div className="gsi-material-button-content-wrapper">
				<div className="gsi-material-button-icon">
					{/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
					<svg
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						xmlnsXlink="http://www.w3.org/1999/xlink"
						style={{ display: "block" }}
					>
						<path
							fill="#EA4335"
							d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
						/>
						<path
							fill="#4285F4"
							d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
						/>
						<path
							fill="#FBBC05"
							d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
						/>
						<path
							fill="#34A853"
							d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
						/>
						<path fill="none" d="M0 0h48v48H0z" />
					</svg>
				</div>
				<span className="gsi-material-button-contents">
					Sign in with Google
				</span>
				<span style={{ display: "none" }}>Sign in with Google</span>
			</div>
		</button>
	);
};

type SelectOption = {
	label: any;
	value: string;
};

const FormikSelect = ({
	name,
	options,
	...props
}: { name: string; options: SelectOption[]; props: any }) => {
	const { setFieldValue } = useFormikContext();
	const [field] = useField(name);

	return (
		<Select
			{...field}
			{...props}
			classNamePrefix="select"
			options={options}
			name={name}
			value={options.find((option) => option.value === field.value) || null}
			onChange={(option: SingleValue<SelectOption>) => {
				setFieldValue(name, option ? option.value : "");
			}}
			onBlur={field.onBlur}
		/>
	);
};

interface FormikCreatableSelectProps {
	name: string;
	options: SelectOption[];
}

const FormikCreatableSelect: React.FC<FormikCreatableSelectProps> = ({
	name,
	options,
	...props
}) => {
	const [field, , helpers] = useField(name);
	const { setFieldValue } = useFormikContext();
	const handleChange = (value) => {
		setFieldValue(name, value);
	};

	const handleBlur = () => {
		helpers.setTouched(true);
	};

	return (
		<CreatableSelect
			{...field}
			{...props}
			isMulti
			options={options}
			onChange={handleChange}
			onBlur={handleBlur}
			value={field.value}
		/>
	);
};

export {
	LanguageWithFlag,
	FormikSelect,
	FormikCreatableSelect,
	OfficialGoogleButton,
};
