import { usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "react-bootstrap";

const LoadingIndicatorButton = (props) => {
	const { promiseInProgress } = usePromiseTracker({
		area: props.area,
		delay: 0,
	});
	return (
		promiseInProgress && (
			<Spinner
				as="span"
				animation="border"
				size="sm"
				role="status"
				aria-hidden="true"
				className="mx-1"
			/>
		)
	);
};

export default LoadingIndicatorButton;
