import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import React, { useEffect, useState } from "react";
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Container,
	Form,
	Image,
	ListGroup,
	Modal,
	ProgressBar,
	Row,
	Spinner,
	Tab,
	Tabs,
} from "react-bootstrap";
import {
	Archive,
	Book,
	BookFill,
	Bookmark,
	BookmarkFill,
	Brush,
	Check2,
	Envelope,
	EnvelopeAt,
	FileCheck,
	FilePlus,
	Gear,
	GearFill,
	HandThumbsUp,
	Key,
	Lock,
	Pencil,
	Stars,
	Trash,
	TrashFill,
} from "react-bootstrap-icons";
import { trackPromise } from "react-promise-tracker";
import { Link, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuthenticatedUserContext } from "../App.js";
import CycleProgressBar from "./CycleProgressBar.js";
import AchievementBadgesComponent from "./ressources/AchievementBadgesComponent.js";
import AchievementProgressComponent from "./ressources/AchievementProgressComponent.js";
import { ImageProvider } from "./ressources/ImageProvider.js";
import LoadingIndicatorButton from "./ressources/LoadingIndicatorButton.js";
import PendingRequestSpinner from "./ressources/PendingRequestSpinner.js";
import SlateReadOnly from "./ressources/SlateReadOnly.js";
import { VerticallyCenteredModal } from "./ressources/VerticallyCenteredModal.js";
import { languages } from "./ressources/languages.js";
import { urls } from "./ressources/urls.js";
import {
	compareDbDateToNow,
	createOffenseReport,
	levelToIcon,
	postJson,
	removeItemFromList,
	shortenDate,
	slateObjectToText,
	toastColors,
	toastHeaders,
} from "./ressources/utils.js";
import type Story from "./types/types.Story.ts";
import type User from "./types/types.UserType.ts";
import { LanguageWithFlag } from "./ressources/MicroComponents.tsx";
import dayjs from "dayjs";
import { Tooltip } from "react-tooltip";
import type ChangelogEntry from "./types/types.ChangelogEntry.ts";

const languageOptions = Object.keys(languages).map((language) =>
	Object({ value: language, label: <LanguageWithFlag language={language} /> }),
);

interface Toast {
	id: number;
	user_id: number;
	story_id?: number;
	branch_id?: number;
	part_id?: number;
	category: string;
	content: string;
	creation_date: string;
}

function ProfilePage() {
	const { paramUserName } = useParams();
	const { user, setUser } = useAuthenticatedUserContext();
	const [displayUser, setDisplayUser] = useState<User>();

	const [key, setKey] = useState("stories");
	const [stories, setStories] = useState<Array<Story>>([]);
	const [collaborations, setCollaborations] = useState<Array<any>>([]);
	const [parts, setParts] = useState<Array<any>>([]);
	const [proposals, setProposals] = useState<Array<any>>([]);
	const [votes, setVotes] = useState<Array<any>>([]);
	const [bookmarks, setBookmarks] = useState<Array<any>>([]);
	const [archivedProposals, setArchivedProposals] = useState<Array<any>>([]);
	const [toasts, setToasts] = useState<Array<Toast>>([]);
	const [userStatistics, setUserStatistics] = useState(() => {
		return JSON.parse(
			window.sessionStorage.getItem("UserStatistics") || "null",
		);
	});
	const [userAchievementCount, setUserAchievementCount] = useState(() => {
		return JSON.parse(
			window.sessionStorage.getItem("UserAchievementCount") || "null",
		);
	});
	const [userAchievementCountPercentage, setUserAchievementCountPercentage] =
		useState(() => {
			return JSON.parse(
				window.sessionStorage.getItem("UserAchievementCountPercentage") ||
					"null",
			);
		});
	const [statisticsServerError, setStatisticsServerError] = useState(false);
	const [loadedKeys, setLoadedKeys] = useState(new Set());
	const loadedObjects = {
		stories: { url: urls.getUserStories, setter: setStories, getter: stories },
		collaborations: {
			url: urls.getUserCollaborations,
			setter: setCollaborations,
			getter: collaborations,
		},
		parts: { url: urls.getUserStoryParts, setter: setParts, getter: parts },
		proposals: {
			url: urls.getUserStoryProposals,
			setter: setProposals,
			getter: proposals,
		},
		votes: { url: urls.getUserVotes, setter: setVotes, getter: votes },
		bookmarks: {
			url: urls.getUserBookmarks,
			setter: setBookmarks,
			getter: bookmarks,
		},
		archivedProposals: {
			url: urls.getUserArchivedProposals,
			setter: setArchivedProposals,
			getter: archivedProposals,
		},
	};
	const [sortOption, setSortOption] = useState("creation_date");
	const [sortAscending, setSortAscending] = useState(false);
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
	const [showChangeMailModal, setShowChangeMailModal] = useState(false);
	const [archivedProposalModalShow, setArchivedProposalModalShow] =
		useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [userReportedUser, setUserReportedUser] = useState(false);
	const [fontSize, setFontSize] = useState(user.font_size);
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const [userBio, setUserBio] = useState(user.bio ? user.bio : "");
	const [editingBio, setEditingBio] = useState(false);
	const [updatedBioMessage, setUpdatedBioMessage] = useState<string | null>(
		null,
	);
	const [changedAvatar, setChangedAvatar] = useState("alien.svg");
	const [changedUserName, setChangedUserName] = useState("");
	const [showVerificationAlert, setShowVerificationAlert] = useState(false);
	const [activeMenuKey, setActiveMenuKey] = useState("Stories");
	const [deletionPassword, setDeletionPassword] = useState("");
	const [deletionMessage, setDeletionMessage] = useState("");
	const [newMail, setNewMail] = useState("");
	const [showNewMailSpinner, setShowNewMailSpinner] = useState(false);
	const [filteredChangelog, setFilteredChangelog] = useState<ChangelogEntry[]>(
		[],
	);
	const [showChangelogModal, setShowChangelogModal] = useState<boolean>(false);
	const [changedLanguages, setChangedLanguages] = useState<boolean>(false);
	const [changedFontSize, setChangedFontSize] = useState<boolean>(false);

	const navigate = useNavigate();

	const handleMenuSelect = (selectedKey) => {
		setUpdatedBioMessage(null);
		setActiveMenuKey(selectedKey);
		if (selectedKey === "Achievements" && userStatistics === null) {
			setStatisticsServerError(false);
			getAndSetUserStatistics();
		}
	};

	async function getAndSetUserStatistics(
		setShowRefreshSpinner?: (bool: boolean) => void,
	): Promise<void> {
		if (setShowRefreshSpinner) {
			setShowRefreshSpinner(true);
		}
		postJson(urls.getUserStatistics, { user_id: displayUser?.id }).then(
			(data) => {
				if (data.error) {
					setStatisticsServerError(true);
					return;
				}
				setUserStatistics(data.user_statistics);
				setUserAchievementCount(data.user_achievement_count);
				setUserAchievementCountPercentage(
					data.user_achievement_count_percentage,
				);
				window.sessionStorage.setItem(
					"UserStatistics",
					JSON.stringify(data.user_statistics),
				);
				window.sessionStorage.setItem(
					"UserAchievementCount",
					JSON.stringify(data.user_achievement_count),
				);
				window.sessionStorage.setItem(
					"UserAchievementCountPercentage",
					JSON.stringify(data.user_achievement_count_percentage),
				);
				if (setShowRefreshSpinner) {
					setShowRefreshSpinner(false);
				}
			},
		);
	}

	const level_icon_info = levelToIcon(displayUser?.level);

	useEffect(() => {
		const initializeData = async () => {
			setLoadedKeys(new Set());

			if (paramUserName && paramUserName !== user.name) {
				await postJson(urls.getOtherUser, {
					user_name: paramUserName,
				}).then((fetchedUser) => {
					if (!fetchedUser) return navigate("path_error");
					setDisplayUser(fetchedUser);
					fetchStateData("stories", fetchedUser.id, true);
					postJson(urls.getUserOffenseReportForId, {
						reported_id: fetchedUser.id,
						reported_type: "user",
					}).then((data) => {
						if (data?.reports.length > 0) setUserReportedUser(true);
					});
				});
			} else {
				setDisplayUser(user);
				fetchStateData("stories", user.id, true);

				fetch(urls.baseUrl + urls.getUserToasts)
					.then((response) => response.json())
					.then((data) => {
						setToasts(data.toasts);
					});
			}

			setShowVerificationAlert(!user.is_verified);
			setChangedAvatar(user.avatar);
			setChangedUserName(user.name);
		};

		initializeData();
	}, [paramUserName]);

	useEffect(() => {
		if (displayUser?.name !== user.name) {
			return;
		}
		const fetchChangelogData = async () => {
			try {
				const response = await fetch(urls.baseUrl + urls.changelog);
				const data = await response.json();

				// Filter changelog entries newer than the user's latest login
				const latestLoginDate = dayjs(user.latest_login);
				const newEntries = data.changelog.filter((entry: ChangelogEntry) =>
					dayjs(entry.creation_date).isAfter(latestLoginDate),
				);

				setFilteredChangelog(newEntries);
				if (newEntries.length > 0) {
					setShowChangelogModal(true);
				}
				setUser((prevUser) => {
					if (!prevUser) return prevUser;
					return {
						...prevUser,
						latest_login: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					};
				});
			} catch (error) {
				console.error("Error fetching changelog:", error);
			}
		};

		fetchChangelogData();
	}, [displayUser, user.latest_login, user.name, setUser]);

	function handleReportUser(text: string) {
		createOffenseReport(displayUser?.id, "user", text).then((data) => {
			setReportModalShow(false);
			setUserReportedUser(true);
		});
	}

	function handleDeleteAccount() {
		postJson(urls.deleteAccount, { password: deletionPassword }).then(
			(data) => {
				if (data.code === 200) {
					setUser(null);
					window.location.href = "https://www.cornuvotia.net";
				} else {
					setDeletionMessage(data.error);
				}
			},
		);
	}

	function handleChangeMail() {
		if (deletionPassword.length < 4 || newMail.length < 5) {
			setDeletionMessage("Invalid mail or password input");
			return;
		}
		setShowNewMailSpinner(true);
		postJson(urls.updateUserMail, {
			password: deletionPassword,
			email: newMail,
		}).then((data) => {
			if (data.error) {
				setDeletionMessage(data.error);
			} else {
				setUser((prevUser) => {
					if (!prevUser) return prevUser;
					return {
						...prevUser,
						email: newMail,
					};
				});
				setShowChangeMailModal(false);
				setNewMail("");
				setDeletionPassword("");
			}
			setShowNewMailSpinner(false);
		});
	}

	function handleResendVerificationLinkClick() {
		trackPromise(fetch(urls.baseUrl + urls.sendVerificationLink))
			.then((response) => response.json())
			.then((data) => {
				if (data.code === 200) {
				}
			});
	}

	const updateFontSizeTemp = (newSize) => {
		if (newSize < 5 || newSize > 30) {
			return;
		}
		setFontSize(newSize);
	};

	const updateFontSize = async () => {
		if (fontSize === user.font_size) return;
		setChangedFontSize(true);
		await postJson(urls.updateFontSize, { font_size: fontSize }).then(
			(data) => {
				setUser((prevUser) => {
					if (!prevUser) return prevUser;
					return {
						...prevUser,
						font_size: fontSize,
					};
				});
			},
		);
		setTimeout(() => {
			setChangedFontSize(false);
		}, 3000);
	};

	const updateUserBio = async () => {
		await postJson(urls.updateUserBio, {
			bio: userBio,
			username: changedUserName,
			avatar: changedAvatar,
		}).then((data) => {
			if (data.code === 200) {
				setUser((prevUser) => {
					if (!prevUser) return prevUser;
					return {
						...prevUser,
						bio: userBio,
						name: changedUserName,
						avatar: changedAvatar,
					};
				});
				setDisplayUser((prevUser) => {
					if (!prevUser) return prevUser;
					return {
						...prevUser,
						bio: userBio,
						name: changedUserName,
						avatar: changedAvatar,
					};
				});
				setEditingBio(false);
				setUpdatedBioMessage("Update successfull");
			}
			if (data.message) {
				setUpdatedBioMessage(data.message);
			}
		});
	};

	async function onClickSetUserLanguages() {
		setChangedLanguages(true);
		await postJson(urls.updateUserLanguages, {
			languages: selectedLanguages,
		}).then((data) => {
			setUser((prevUser) => {
				if (!prevUser) return prevUser;
				return {
					...prevUser,
					languages: selectedLanguages,
				};
			});
		});
		setTimeout(() => {
			setChangedLanguages(false);
		}, 3000);
	}

	const handleLanguageChange = (selectedOption) => {
		setSelectedLanguages(selectedOption.map((option) => option.value));
	};

	const handleBookmarkRemoveClick = async (
		branchId,
		setter,
		getter,
		bookmarkId,
	) => {
		removeItemFromList(setter, getter, bookmarkId);
		postJson(urls.deleteBookmark, { branch_id: branchId });
	};

	const handleArchivedProposalRemoveClick = async (
		archiveId,
		setter,
		getter,
	) => {
		removeItemFromList(setter, getter, archiveId);
		postJson(urls.deleteArchivedProposal, { archive_id: archiveId });
	};

	const handleToastBodyClick = (toast: Toast) => {
		if (toast.story_id && toast.branch_id) {
			navigate(`/story/${toast.story_id}/${toast.branch_id}`, {
				state: { preselected_part_id: toast.part_id },
			});
		}
	};

	async function fetchStateData(key, userId, cleanKeys = false) {
		if (cleanKeys || !loadedKeys.has(key)) {
			postJson(loadedObjects[key].url, { user_id: userId }).then((data) => {
				loadedObjects[key].setter(data.user_entities);
			});
			setLoadedKeys((prevKeys) => new Set([...prevKeys, key]));
		}
	}

	async function changeTab(k) {
		fetchStateData(k, displayUser?.id);
	}

	const rankingTooltip = (
		<Tooltip id="ranking-tooltip" place="bottom-end">
			<h4>
				<b>Ranking</b>
			</h4>
			<span>
				Your rank represents your contribution to public stories.
				<br />
				The rank is a combination of:
				<br />
				<ul>
					<li>Published parts</li>
					<li>Written words</li>
				</ul>
			</span>
			{[
				[1, "Novice"],
				[10, "Apprentice"],
				[100, "Journeyman"],
				[500, "Master"],
				[1000, "B.S."],
			].map((rank, index, array) => {
				const level = rank[0];
				const rankName = rank[1];
				const [icon, repetitions] = levelToIcon(level);
				const nextLevel = array[index + 1]
					? Number(array[index + 1][0]) - 1
					: "";
				return (
					<Row key={level} className="mt-2">
						<Col className="d-flex justify-content-start">
							{[...Array(repetitions)].map((_, i) => (
								<Image
									key={`${level}-${rankName}`}
									src={icon}
									width="24"
									className="me-1"
								/>
							))}
							<b>
								({rankName}) {`${level}-${nextLevel}`}
							</b>
						</Col>
					</Row>
				);
			})}
		</Tooltip>
	);

	function sortByKeyWrapper(key, ascending) {
		return function sortByKey(a, b) {
			let comparison = 0;
			switch (key) {
				case "creation_date":
					comparison = dayjs(a.creation_date).diff(dayjs(b.creation_date));
					break;
				case "voting_cycle_end":
					comparison = dayjs(a.voting_cycle_end).diff(
						dayjs(b.voting_cycle_end),
					);
					break;
				case "writing_cycle_end":
					comparison = dayjs(a.writing_cycle_end).diff(
						dayjs(b.writing_cycle_end),
					);
					break;
				case "title":
					comparison = a.title.localeCompare(b.title);
					break;
			}
			return ascending ? comparison : -comparison;
		};
	}

	function sortUserEntities(sortKey, ascending) {
		for (const [key, value] of Object.entries(loadedObjects)) {
			const sortedArray = [...value.getter].sort(
				sortByKeyWrapper(sortKey, ascending),
			);
			value.setter(sortedArray);
		}
	}

	const handleToastClose = (index) => {
		postJson(urls.deleteUserToasts, { toast_ids: [toasts[index].id] });
		setToasts(toasts.filter((_, toastIndex) => toastIndex !== index));
	};

	const handleDeleteAllUpdatesClick = () => {
		postJson(urls.deleteUserToasts, {
			toast_ids: toasts.map((toast) => toast.id),
		});
		setToasts([]);
	};

	const handleDeleteAllArchivedProposalsClick = () => {
		postJson(urls.deleteArchivedProposals, {});
		setArchivedProposals([]);
	};

	return (
		<div className="ProfilePage">
			<Container fluid>
				{displayUser && (
					<Row>
						<Col xs={12} xl={2} style={{ padding: "0px" }} className="mb-3">
							<ListGroup as="ul" variant="flush">
								<ListGroup.Item
									as="li"
									action
									active={activeMenuKey === "Stories"}
									onClick={() => handleMenuSelect("Stories")}
									style={{ cursor: "pointer" }}
									variant="primary"
								>
									<BookFill /> Stories
								</ListGroup.Item>
								<ListGroup.Item
									as="li"
									action
									active={activeMenuKey === "Achievements"}
									onClick={() => handleMenuSelect("Achievements")}
									style={{ cursor: "pointer" }}
									variant="primary"
								>
									<Stars /> Achievements
								</ListGroup.Item>
								{displayUser.name === user.name && (
									<ListGroup.Item
										as="li"
										action
										active={activeMenuKey === "Settings"}
										onClick={() => handleMenuSelect("Settings")}
										style={{ cursor: "pointer" }}
										variant="primary"
									>
										<GearFill /> Settings
									</ListGroup.Item>
								)}
							</ListGroup>
							<div className="bg-white">
								<div
									className="px-3 py-2"
									style={{
										borderStyle: "solid",
										borderWidth: "1px",
										borderColor: "#D9DADB",
									}}
								>
									<Row>
										<Col xs={6} xl={12}>
											<Card.Img
												variant="top"
												src={
													editingBio
														? ImageProvider.avatars[changedAvatar]
														: ImageProvider.avatars[displayUser.avatar]
												}
											/>
										</Col>
										<Col>
											<Card.Body>
												{editingBio && (
													<Form.Control
														className="mb-1"
														as="select"
														name="avatar"
														value={changedAvatar}
														onChange={(e) => {
															setChangedAvatar(e.target.value);
														}}
													>
														{Object.keys(ImageProvider.avatars).map(
															(keyName, i) => (
																<option key={keyName} value={keyName}>
																	{keyName.replace(".svg", "")}
																</option>
															),
														)}
													</Form.Control>
												)}
												<div className="d-flex align-items-center">
													{editingBio ? (
														<Form.Control
															size="sm"
															className="mt-2"
															id="inputUsername"
															aria-describedby="usernameBlock"
															value={changedUserName}
															maxLength={20}
															onChange={(e) =>
																setChangedUserName(e.target.value)
															}
														/>
													) : (
														<>
															{displayUser.name.length < 10 ? (
																<span
																	className="me-2"
																	style={{
																		fontWeight: 300,
																		lineHeight: 1.2,
																		fontSize: "2rem",
																	}}
																>
																	{displayUser.name}
																</span>
															) : (
																<span
																	className="me-2"
																	style={{
																		fontWeight: 300,
																		lineHeight: 1.2,
																		fontSize: "1.25rem",
																	}}
																>
																	{displayUser.name}
																</span>
															)}
														</>
													)}
												</div>
												{editingBio ? (
													<>
														<Form.Control
															size="sm"
															className="mt-2"
															id="inputBio"
															aria-describedby="bioBlock"
															value={userBio}
															maxLength={100}
															onChange={(e) => setUserBio(e.target.value)}
														/>
													</>
												) : (
													<div className="text-muted mb-2">
														Bio: {displayUser.bio}
													</div>
												)}
												<div className="d-flex">
													<Link
														to="/chat_messages"
														state={{ receiverName: displayUser.name }}
														style={{ textDecoration: "none" }}
													>
														<Button
															size="sm"
															className="d-flex align-items-center my-1"
														>
															<Envelope size={20} className="me-1" />
															<b>PM</b>
														</Button>
													</Link>
													{displayUser.name === user.name && editingBio ? (
														<Button
															className="ms-2 my-1"
															size="sm"
															type="button"
															onClick={() => updateUserBio()}
														>
															Save
														</Button>
													) : (
														<Button
															size="sm"
															className="ms-2 d-flex align-items-center my-1"
															onClick={() => setEditingBio(true)}
														>
															<Brush className="me-1" />
															Edit profile
														</Button>
													)}
												</div>
												{updatedBioMessage && (
													<div className="text-success my-1">
														{updatedBioMessage}
													</div>
												)}
												<div>
													<div
														className="mb-2"
														data-tooltip-id="ranking-tooltip"
														data-tooltip-variant="dark"
													>
														<b>Rank ({Math.floor(displayUser?.level)}): </b>{" "}
														{[...Array(level_icon_info[1])].map((e, i) => (
															<Image
																src={level_icon_info[0]}
																key={e}
																width="24"
																alt="Test"
															/>
														))}
													</div>
												</div>
												<div className="mb-1">
													<b>Progress:</b>
													<ProgressBar
														className="mt-2"
														now={100 * (displayUser.level % 1)}
														label={`${Math.floor(100 * (displayUser.level % 1))}%`}
														variant="success"
													/>
												</div>
												<AchievementBadgesComponent
													userAchievements={displayUser.achievements}
												/>
												{displayUser.name !== user.name && (
													<div className="mt-2">
														<Button
															variant="outline-danger"
															onClick={() => setReportModalShow(true)}
															disabled={userReportedUser}
														>
															{userReportedUser ? "Reported" : "Report user"}
														</Button>
														<VerticallyCenteredModal
															show={reportModalShow}
															onHide={() => setReportModalShow(false)}
															onConfirm={(text) => {
																handleReportUser(text);
															}}
															hastextinput="true"
															title="Create a report"
															body="Give your reasons for the report. It will be reviewed asap."
														/>
													</div>
												)}
											</Card.Body>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
						<Col xl={7} xs={12} className="mb-3">
							<div className="mt-3">
								{showVerificationAlert && displayUser.name === user.name && (
									<Alert variant="info" dismissible>
										<Alert.Heading>Account verification</Alert.Heading>
										You haven't verified you account yet. Check your mail or
										resend the verfication{" "}
										<u
											style={{ cursor: "pointer" }}
											onClick={() => {
												handleResendVerificationLinkClick();
												setShowVerificationAlert(false);
											}}
											onKeyUp={() => {
												handleResendVerificationLinkClick();
												setShowVerificationAlert(false);
											}}
										>
											link
										</u>
										.
										<br />
										Your account will be suspended if not verified 7 days after
										registration (Registration date:{" "}
										{shortenDate(user.creation_date)}).
									</Alert>
								)}
								{displayUser.name === user.name && (
									<Modal
										show={showChangelogModal}
										onHide={() => setShowChangelogModal(false)}
										centered
									>
										<Modal.Header closeButton>
											<Modal.Title>
												Welcome back! We did some things
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											{filteredChangelog.map((entry) => (
												<div key={entry.id} className="mt-2">
													<h5>
														[{dayjs(entry.creation_date).format("YYYY-MM-DD")}]
													</h5>
													<ReactMarkdown>{entry.content}</ReactMarkdown>
												</div>
											))}
										</Modal.Body>
										<Modal.Footer>
											<Link to="/changelog">Show full changelog</Link>
											<Button
												variant="secondary"
												onClick={() => setShowChangelogModal(false)}
											>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
								)}
								{activeMenuKey === "Stories" && (
									<div>
										<Row className="mb-2">
											<Col>
												<Form.Control
													as="select"
													value={sortOption}
													onChange={(e) => {
														setSortOption(e.target.value);
														sortUserEntities(e.target.value, sortAscending);
													}}
												>
													<option value="creation_date">
														Sort by Creation Date
													</option>
													<option value="title">Sort by Title</option>
													<option value="voting_cycle_end">
														Sort by voting ends
													</option>
													<option value="writing_cycle_end">
														Sort by submission ends
													</option>
												</Form.Control>
											</Col>
											<Col>
												<Button
													onClick={() => {
														sortUserEntities(sortOption, !sortAscending);
														setSortAscending((prev) => !prev);
													}}
												>
													{sortAscending ? "Ascending" : "Descending"}
												</Button>
											</Col>
										</Row>
										<Tabs
											id="controlled-tab"
											activeKey={key}
											onSelect={(k) => {
												if (k) {
													setKey(k);
												}
												changeTab(k);
											}}
											fill
										>
											<Tab
												eventKey="stories"
												title={
													<div>
														<Book /> Stories
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{stories && (
														<div>
															{stories.map((story, index) => (
																<ListGroup.Item key={story.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{story.title}
																			</span>
																			<Link
																				to={`/story/${story.id}/${story.branch_id}`}
																				state={{ story: story }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																		</Col>
																	</Row>
																	<div className="mb-2">
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			<LanguageWithFlag
																				language={story.language}
																				withTitle={true}
																			/>
																		</Badge>
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			Creation date:{" "}
																			{shortenDate(story.creation_date)}
																		</Badge>
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			End date: {shortenDate(story.end_date)}
																		</Badge>
																		{story.nsfw && (
																			<Badge bg="danger">NSFW</Badge>
																		)}
																		{story.is_private && (
																			<Badge className="ms-2" bg="secondary">
																				Private
																			</Badge>
																		)}
																	</div>
																	<CycleProgressBar story={story} />
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="collaborations"
												disabled={displayUser.name !== user.name}
												title={
													<div>
														<Lock /> Collaborations
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{collaborations && (
														<div>
															{collaborations.map((collaboration, index) => (
																<ListGroup.Item key={collaboration.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{collaboration.title}
																			</span>
																			<Link
																				to={`/story/${collaboration.id}/${collaboration.branch_id}`}
																				state={{ story: collaboration }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																		</Col>
																	</Row>
																	<div className="mb-2">
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			Language: {collaboration.language}
																		</Badge>
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			Creation date:{" "}
																			{shortenDate(collaboration.creation_date)}
																		</Badge>
																		<Badge
																			bg="light"
																			text="dark"
																			className="me-2"
																		>
																			End date:{" "}
																			{shortenDate(collaboration.end_date)}
																		</Badge>
																		{collaboration.nsfw && (
																			<Badge bg="danger">NSFW</Badge>
																		)}
																	</div>
																	<CycleProgressBar story={collaboration} />
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="parts"
												title={
													<div>
														<FileCheck /> Parts
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "scroll" }}
											>
												<ListGroup>
													{parts && (
														<div>
															{parts.map((part, index) => (
																<ListGroup.Item key={part.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{part.title}
																			</span>
																			<Link
																				to={`/story/${part.id}/${part.branch_id}`}
																				state={{ story: part }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																		</Col>
																	</Row>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Creation date:{" "}
																		{shortenDate(part.creation_date)}
																	</Badge>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Word count: {part.n_tokens}
																	</Badge>
																	{part.nsfw && <Badge bg="danger">NSFW</Badge>}
																	{part.is_private && (
																		<Badge className="ms-2" bg="secondary">
																			Private
																		</Badge>
																	)}
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="proposals"
												disabled={displayUser.name !== user.name}
												title={
													<div>
														<FilePlus /> Proposals
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{proposals && displayUser.name === user.name && (
														<div>
															{proposals.map((proposal, index) => (
																<ListGroup.Item key={proposal.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{proposal.title}
																			</span>
																			<Link
																				to={`/story/${proposal.id}/${proposal.branch_id}`}
																				state={{ story: proposal }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																			{compareDbDateToNow(
																				proposal.writing_cycle_end,
																				true,
																			) ? (
																				<Link
																					to="/continue"
																					state={{ story: proposal }}
																					className="ms-2"
																				>
																					<Button size="sm" variant="write">
																						Show proposal
																					</Button>
																				</Link>
																			) : (
																				<Link
																					to="/vote"
																					state={{ story: proposal }}
																					className="ms-2"
																				>
																					<Button size="sm" variant="vote">
																						Show proposal
																					</Button>
																				</Link>
																			)}
																		</Col>
																	</Row>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Creation date:{" "}
																		{shortenDate(proposal.creation_date)}
																	</Badge>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Submission end:{" "}
																		{shortenDate(proposal.writing_cycle_end)}
																	</Badge>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Voting end:{" "}
																		{shortenDate(proposal.voting_cycle_end)}
																	</Badge>
																	{proposal.nsfw && (
																		<Badge bg="danger">NSFW</Badge>
																	)}
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="votes"
												disabled={displayUser.name !== user.name}
												title={
													<div>
														<HandThumbsUp /> Votes
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{votes && displayUser.name === user.name && (
														<div>
															{votes.map((vote, index) => (
																<ListGroup.Item key={vote.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{vote.title}
																			</span>
																			<Link
																				to={`/story/${vote.id}/${vote.branch_id}`}
																				state={{ story: vote }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																			<Link
																				to="/vote"
																				state={{ story: vote }}
																				className="ms-2"
																			>
																				<Button size="sm" variant="vote">
																					Show vote
																				</Button>
																			</Link>
																		</Col>
																	</Row>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Creation date:{" "}
																		{shortenDate(vote.creation_date)}
																	</Badge>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Voting end:{" "}
																		{shortenDate(vote.voting_cycle_end)}
																	</Badge>
																	{vote.nsfw && <Badge bg="danger">NSFW</Badge>}
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="bookmarks"
												disabled={displayUser.name !== user.name}
												title={
													<div>
														<Bookmark /> Bookmarks
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{bookmarks && displayUser.name === user.name && (
														<div>
															{bookmarks.map((bookmark, index) => (
																<ListGroup.Item key={bookmark.id}>
																	<Row className="mb-2">
																		<Col>
																			<span className="profile-tab-card-heading">
																				{bookmark.title}
																			</span>
																			<Link
																				to={`/story/${bookmark.id}/${bookmark.branch_id}`}
																				state={{ story: bookmark }}
																				className="ms-2"
																			>
																				<Button
																					size="sm"
																					variant="outline-primary"
																				>
																					Show story
																				</Button>
																			</Link>
																			<Button
																				className="ms-1"
																				size="sm"
																				onClick={() =>
																					handleBookmarkRemoveClick(
																						bookmark.branch_id,
																						loadedObjects.bookmarks.setter,
																						loadedObjects.bookmarks.getter,
																						bookmark.id,
																					)
																				}
																			>
																				<BookmarkFill /> Remove bookmark
																			</Button>
																		</Col>
																	</Row>
																	<Badge
																		bg="light"
																		text="dark"
																		className="me-2"
																	>
																		Creation date:{" "}
																		{shortenDate(bookmark.creation_date)}
																	</Badge>
																	{bookmark.nsfw && (
																		<Badge bg="danger">NSFW</Badge>
																	)}
																</ListGroup.Item>
															))}
														</div>
													)}
												</ListGroup>
											</Tab>
											<Tab
												eventKey="archivedProposals"
												disabled={displayUser.name !== user.name}
												title={
													<div>
														<Archive /> Archived Proposals
													</div>
												}
												style={{ maxHeight: "75vh", overflowY: "auto" }}
											>
												<ListGroup>
													{archivedProposals &&
														displayUser.name === user.name && (
															<div>
																<div className="d-flex justify-content-end">
																	{" "}
																	<Button
																		className="my-1 me-1"
																		size="sm"
																		variant="secondary"
																		onClick={() => {
																			handleDeleteAllArchivedProposalsClick();
																		}}
																	>
																		<TrashFill /> Delete all
																	</Button>
																</div>
																{archivedProposals.map(
																	(archivedProposal, index) => (
																		<div key={archivedProposal.id}>
																			<ListGroup.Item>
																				<Row className="mb-2">
																					<Col>
																						<span className="profile-tab-card-heading">
																							{archivedProposal.story_title}
																						</span>
																						<Button
																							className="ms-2"
																							size="sm"
																							variant="primary"
																							onClick={() =>
																								setArchivedProposalModalShow(
																									true,
																								)
																							}
																						>
																							Show proposal
																						</Button>
																						<Button
																							className="ms-1"
																							size="sm"
																							variant="secondary"
																							onClick={() =>
																								handleArchivedProposalRemoveClick(
																									archivedProposal.id,
																									loadedObjects
																										.archivedProposals.setter,
																									loadedObjects
																										.archivedProposals.getter,
																								)
																							}
																						>
																							<TrashFill /> Delete
																						</Button>
																					</Col>
																				</Row>
																				<Badge
																					bg="light"
																					text="dark"
																					className="me-2"
																				>
																					Creation date:{" "}
																					{shortenDate(
																						archivedProposal.creation_date,
																					)}
																				</Badge>
																			</ListGroup.Item>
																			<Modal
																				show={archivedProposalModalShow}
																				onHide={() =>
																					setArchivedProposalModalShow(false)
																				}
																			>
																				<Modal.Header closeButton>
																					<Modal.Title>
																						Proposal for "
																						{archivedProposal.story_title}"
																					</Modal.Title>
																				</Modal.Header>
																				<Modal.Body>
																					<div
																						style={{
																							overflowY: "auto",
																							maxHeight: "900px",
																						}}
																					>
																						<SlateReadOnly
																							key={archivedProposal.id}
																							initialValue={
																								archivedProposal.content
																							}
																						/>
																					</div>
																					<Button
																						className="mt-2"
																						onClick={() =>
																							navigator.clipboard.writeText(
																								slateObjectToText(
																									archivedProposal.content,
																								),
																							)
																						}
																					>
																						Copy
																					</Button>
																				</Modal.Body>
																			</Modal>
																		</div>
																	),
																)}
															</div>
														)}
												</ListGroup>
											</Tab>
										</Tabs>
									</div>
								)}
								{activeMenuKey === "Settings" &&
									displayUser.name === user.name && (
										<Card>
											<Card.Body>
												<h4>
													<Gear /> Global settings
												</h4>
												<div className="mt-1">
													<b>Font size </b> (for story text)
													<Button
														type="button"
														className="ms-1"
														variant="secondary"
														size="sm"
														onClick={() => updateFontSizeTemp(fontSize - 1)}
													>
														-
													</Button>
													<Button
														className="ms-1"
														variant="secondary"
														size="sm"
														type="button"
														onClick={() => updateFontSizeTemp(fontSize + 1)}
													>
														+
													</Button>
													<Button
														className="ms-1"
														variant="secondary"
														size="sm"
														type="button"
														onClick={() => updateFontSizeTemp(14)}
													>
														Default
													</Button>
													<Button
														className="ms-1"
														size="sm"
														type="button"
														onClick={() => updateFontSize()}
														disabled={changedFontSize}
													>
														Save changes
													</Button>
													{changedFontSize && (
														<span
															className="ms-1 mt-2"
															style={{ color: "green" }}
														>
															<Check2 /> Saved changes!
														</span>
													)}
													<Card className="mt-1">
														<Card.Body
															style={{
																fontSize: `${fontSize}px`,
																minHeight: "100px",
															}}
														>
															This is an example text with the font size set.
														</Card.Body>
													</Card>
												</div>
												<div className="mt-3">
													<b>Languages </b>(for defaults and explore)
													<Button
														className="ms-1"
														size="sm"
														type="button"
														onClick={() => onClickSetUserLanguages()}
														disabled={changedLanguages}
													>
														Save changes
													</Button>
													{changedLanguages && (
														<span
															className="ms-1 mt-2"
															style={{ color: "green" }}
														>
															<Check2 /> Saved changes!
														</span>
													)}
													<Select
														className="basic-multi-select mt-1"
														classNamePrefix="select"
														isMulti
														isClearable={true}
														isSearchable={true}
														name="Language"
														defaultValue={user.languages.map((language) =>
															Object({ value: language, label: language }),
														)}
														onChange={handleLanguageChange}
														options={languageOptions}
													/>
												</div>
												<hr />
												<div>
													<b>E-mail: </b>
													{user.email}
												</div>
												<div className="d-flex my-1">
													<Button
														className="me-1"
														variant="primary"
														onClick={() => setShowChangeMailModal(true)}
													>
														<EnvelopeAt /> Change Mail
													</Button>
													<Modal
														aria-labelledby="contained-modal-title-vcenter"
														centered
														show={showChangeMailModal}
														onHide={() => setShowChangeMailModal(false)}
													>
														<Modal.Header closeButton>
															<Modal.Title id="contained-modal-title-vcenter">
																Mail address change
															</Modal.Title>
														</Modal.Header>
														<Modal.Body>
															<>
																Enter your new e-mail address. You will have to
																verify your account again.
																<br />
																<Form.Control
																	className="my-2"
																	type="email"
																	placeholder="E-mail address"
																	maxLength={100}
																	value={newMail}
																	onChange={(e) => setNewMail(e.target.value)}
																	required
																/>
																Confirm your decision to change your mail with
																your login password.
																<Form.Control
																	className="my-2"
																	type="password"
																	placeholder="Password"
																	maxLength={100}
																	value={deletionPassword}
																	onChange={(e) =>
																		setDeletionPassword(e.target.value)
																	}
																	required
																/>
																<span style={{ color: "red" }}>
																	{deletionMessage}
																</span>
															</>
														</Modal.Body>
														<Modal.Footer>
															{showNewMailSpinner && (
																<Spinner className="me-2" />
															)}
															<Button
																variant="primary"
																onClick={() => handleChangeMail()}
															>
																<LoadingIndicatorButton />
																Confirm
															</Button>
														</Modal.Footer>
													</Modal>
													<div>
														<Link
															to="/reset_password"
															state={{ accessedFromProfile: true }}
														>
															<Button variant="primary">
																<Key /> Change Password
															</Button>
														</Link>
													</div>
												</div>
												<Button
													variant="outline-danger"
													onClick={() => setShowDeleteAccountModal(true)}
												>
													Delete Account
												</Button>
												<Modal
													aria-labelledby="contained-modal-title-vcenter"
													centered
													show={showDeleteAccountModal}
													onHide={() => setShowDeleteAccountModal(false)}
												>
													<Modal.Header closeButton>
														<Modal.Title id="contained-modal-title-vcenter">
															Password confirmation
														</Modal.Title>
													</Modal.Header>
													<Modal.Body>
														<>
															Confirm your decision to permanently delete your
															account with your login password.
															<Form.Control
																className="my-2"
																type="password"
																maxLength={100}
																placeholder="Password"
																value={deletionPassword}
																onChange={(e) =>
																	setDeletionPassword(e.target.value)
																}
																required
															/>
															<span style={{ color: "red" }}>
																{deletionMessage}
															</span>
														</>
													</Modal.Body>
													<Modal.Footer>
														<Button
															variant="danger"
															onClick={() => handleDeleteAccount()}
														>
															<LoadingIndicatorButton />
															Confirm
														</Button>
													</Modal.Footer>
												</Modal>
											</Card.Body>
										</Card>
									)}
								{activeMenuKey === "Achievements" &&
									(userStatistics ? (
										<AchievementProgressComponent
											userAchievements={userStatistics}
											userAchievementCount={userAchievementCount}
											userAchievementCountPercentage={
												userAchievementCountPercentage
											}
											getAndSetUserStatistics={getAndSetUserStatistics}
										/>
									) : (
										<div>
											<PendingRequestSpinner
												serverError={statisticsServerError}
											/>
										</div>
									))}
							</div>
						</Col>
						<Col xs={12} xl={3}>
							{displayUser.name === user.name && (
								<Container>
									<div className="display-6 mt-3 mb-2 themed-text">
										Updates{" "}
										<Button
											className="ms-1"
											size="sm"
											variant="secondary"
											onClick={() => handleDeleteAllUpdatesClick()}
										>
											<TrashFill className="me-1" />
											Delete all
										</Button>
									</div>
									<div style={{ maxHeight: "75vh", overflow: "auto" }}>
										{toasts.map((toast, index) => {
											const cleanContent = DOMPurify.sanitize(toast.content);
											const bgColor = toastColors[toast.category];
											const header = toastHeaders[toast.category];
											return (
												<Card key={toast.id} className="my-2 me-2">
													<Card.Header
														className={`d-flex justify-content-between ${bgColor} align-items-center`}
													>
														<strong className="me-auto">{header}</strong>
														<small>{shortenDate(toast.creation_date)}</small>
														<Trash
															className="ms-2"
															style={{ cursor: "pointer" }}
															onClick={() => handleToastClose(index)}
														/>
													</Card.Header>
													<Card.Body
														onClick={() => handleToastBodyClick(toast)}
														onKeyUp={() => handleToastBodyClick(toast)}
														style={{
															cursor:
																toast.branch_id || toast.part_id
																	? "pointer"
																	: "default",
														}}
														// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
														dangerouslySetInnerHTML={{ __html: cleanContent }}
													/>
												</Card>
											);
										})}
									</div>
								</Container>
							)}
						</Col>
					</Row>
				)}
				{rankingTooltip}
			</Container>
		</div>
	);
}

export default ProfilePage;
