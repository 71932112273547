import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { CommunityGuidelines } from "./ressources/CommunityGuidlines";
import {
	PrivacyPolicyEnglish,
	PrivacyPolicyGerman,
} from "./ressources/PrivacyPolicy";
import { IntroductionComponent } from "./ressources/introduction";

function AboutPage() {
	const accordionMapping = {
		guidelines: "0",
		guide: "1",
		privacypolicy: "4",
	};
	const location = useLocation();
	const [openAccordion, setOpenAccordion] = useState(
		location.state ? accordionMapping[location.state.openedItem] : "0",
	);

	return (
		<div className="AboutePage">
			<Container className="mt-3 themed-text">
				<Tabs
					activeKey={openAccordion}
					onSelect={(k) => setOpenAccordion(k)}
					className="mt-3 themed-text"
					justify
				>
					<Tab eventKey="0" title="Community Guidlines">
						<CommunityGuidelines />
					</Tab>
					<Tab eventKey="1" title="Platform Guide">
						<IntroductionComponent />
					</Tab>
					<Tab eventKey="4" title="Privacy Policy">
						<PrivacyPolicyEnglish />
					</Tab>
					<Tab eventKey="5" title="Privacy Policy (German)">
						<PrivacyPolicyGerman />
					</Tab>
				</Tabs>
			</Container>
		</div>
	);
}

export default AboutPage;
