import React, { useMemo, useCallback } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}
	if (leaf.italic) {
		children = <em>{children}</em>;
	}
	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

const SlateReadOnly = ({ initialValue, maxHeight = "75vh" }) => {
	const editor = useMemo(() => withReact(createEditor()), []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	return (
		<Slate editor={editor} initialValue={initialValue}>
			<Editable renderLeaf={renderLeaf} readOnly />
		</Slate>
	);
};

export default SlateReadOnly;
