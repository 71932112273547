import React, { useEffect, useState } from "react";
import {
	Alert,
	Badge,
	Button,
	Card,
	Col,
	Container,
	Row,
} from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { Link, useLocation } from "react-router-dom";
import { useAuthenticatedUserContext } from "../App.js";
import EditTextComponent from "./ressources/EditTextComponent.js";
import { urls } from "./ressources/urls.js";
import { postJson, trimSlateContentInPlace } from "./ressources/utils.js";
import StoryAuthorsCard from "./ressources/StoryAuthorsCard.js";

function ReplacePartPage() {
	const { user } = useAuthenticatedUserContext();
	const location = useLocation();
	const [storyParts, setStoryParts] = useState(
		location.state ? location.state.storyParts : [],
	);
	const [story, setStory] = useState(
		location.state ? location.state.story : {},
	);
	const [index, setIndex] = useState(location.state ? location.state.index : 0);
	const [showPreceeding, setShowPreceeding] = useState(true);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const handleUpdateStoryPart = async (event, slateContent) => {
		event.preventDefault();
		trackPromise(
			postJson(urls.updatePart, {
				part_id: storyParts[index].id,
				branch_id: storyParts[index].branch_id,
				story_id: story.id,
				text: trimSlateContentInPlace(slateContent),
			}),
		).then((data) => {
			window.scrollTo(0, 0);
			if (data.error) {
				setError(data.error);
				setSuccess(false);
			} else {
				setError(null);
				setSuccess(true);
			}
		});
	};

	useEffect(() => {}, []);

	return (
		<div className="ReplacePartPage">
			<Container fluid className="pt-3">
				<Card>
					<Card.Header>
						Replace part for <b>{story.title}</b>
					</Card.Header>
					<Card.Body>
						Min. words:{" "}
						<Badge bg="secondary" className="me-2">
							{story.n_tokens_min}
						</Badge>
						Max. words:{" "}
						<Badge bg="secondary" className="me-2">
							{story.n_tokens_max}
						</Badge>
					</Card.Body>
				</Card>
				{success && (
					<Alert variant="success" className="mt-2">
						<Alert.Heading>Cleaned up!</Alert.Heading>
						You successfully updated the story part.
						<Link
							to={`/story/${story.id}/${storyParts[index].branch_id}`}
							className="ms-2"
						>
							<Button size="sm" variant="outline-primary">
								Show story
							</Button>
						</Link>
					</Alert>
				)}
				{error && (
					<Alert variant="danger" className="mt-2">
						<Alert.Heading>Something went wrong</Alert.Heading>
						{error}
					</Alert>
				)}
				<Row>
					{showPreceeding && (
						<Col md={6} xs={12} className="mb-5 mt-3">
							<div>
								<Row>
									<Col>
										<h3>Preceding parts</h3>
									</Col>
									<Col className="d-flex justify-content-end">
										<Button size="sm" onClick={() => setShowPreceeding(false)}>
											Show subsequent
										</Button>
									</Col>
								</Row>
								<StoryAuthorsCard
									story={story}
									storyParts={storyParts.slice(0, index)}
									userIsCreator={true}
								/>
							</div>
						</Col>
					)}
					<Col md={6} xs={12} className="mt-3">
						<EditTextComponent
							handleUpdateStoryPart={handleUpdateStoryPart}
							beginningText={storyParts[index].content}
						/>
					</Col>
					{!showPreceeding && (
						<Col md={6} xs={12} className="mt-3">
							<div>
								<Row>
									<Col>
										<h3>Subsequent parts</h3>
									</Col>
									<Col className="d-flex justify-content-end">
										<Button size="sm" onClick={() => setShowPreceeding(true)}>
											Show preceding
										</Button>
									</Col>
								</Row>
								<StoryAuthorsCard
									story={story}
									storyParts={storyParts.slice(
										index + 1,
										storyParts.length + 1,
									)}
									userIsCreator={true}
								/>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</div>
	);
}

export default ReplacePartPage;
