import { useState } from "react";
import { Button } from "react-bootstrap";
import CookieConsent from "../../CookieConsent.tsx";

const PrivacyPolicyGerman = () => {
	const [showCookiePreferences, setShowCookiePreferences] = useState(false);
	return (
		<>
			{showCookiePreferences && (
				<CookieConsent
					onAccept={() => null}
					onReject={() => window.location.reload()}
					initialShow={true}
				/>
			)}
			<h1>Datenschutzerklärung</h1>
			<h2 id="m4158">Präambel</h2>
			<p>
				Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
				aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend auch
				kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in welchem
				Umfang im Rahmen der Bereitstellung unserer Applikation verarbeiten.
			</p>
			<p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
			<p>Stand: 16. Dezember 2023</p>
			<h2>Inhaltsübersicht</h2>{" "}
			<ul class="index">
				<li>
					<a class="index-link" href="#m4158">
						Präambel
					</a>
				</li>
				<li>
					<a class="index-link" href="#m3">
						Verantwortlicher
					</a>
				</li>
				<li>
					<a class="index-link" href="#mOverview">
						Übersicht der Verarbeitungen
					</a>
				</li>
				<li>
					<a class="index-link" href="#m2427">
						Maßgebliche Rechtsgrundlagen
					</a>
				</li>
				<li>
					<a class="index-link" href="#m27">
						Sicherheitsmaßnahmen
					</a>
				</li>
				<li>
					<a class="index-link" href="#m25">
						Übermittlung von personenbezogenen Daten
					</a>
				</li>
				<li>
					<a class="index-link" href="#m12">
						Löschung von Daten
					</a>
				</li>
				<li>
					<a class="index-link" href="#m10">
						Rechte der betroffenen Personen
					</a>
				</li>
				<li>
					<a class="index-link" href="#m225">
						Bereitstellung des Onlineangebotes und Webhosting
					</a>
				</li>
				<li>
					<a class="index-link" href="#m182">
						Kontakt- und Anfragenverwaltung
					</a>
				</li>
				<li>
					<a class="index-link" href="#m263">
						Webanalyse, Monitoring und Optimierung
					</a>
				</li>
				<li>
					<a class="index-link" href="#m136">
						Präsenzen in sozialen Netzwerken (Social Media)
					</a>
				</li>
				<li>
					<a class="index-link" href="#m328">
						Plugins und eingebettete Funktionen sowie Inhalte
					</a>
				</li>
				<li>
					<a class="index-link" href="#m555">
						Google OAuth 2.0-Authentifizierung
					</a>
				</li>
				<li>
					<a class="index-link" href="#m556">
						Verwendung der OpenAI API
					</a>
				</li>
				<li>
					<a class="index-link" href="#m15">
						Änderung und Aktualisierung der Datenschutzerklärung
					</a>
				</li>
			</ul>
			<h2 id="m3">Verantwortlicher</h2>
			<p>siehe Impressum</p>
			E-Mail-Adresse:{" "}
			<p>
				<a href="mailto:cornuvotia@gmail.com">cornuvotia@gmail.com</a>
			</p>
			<h2 id="mOverview">Übersicht der Verarbeitungen</h2>
			<p>
				Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
				die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
				Personen.
			</p>
			<h3>Arten der verarbeiteten Daten</h3>
			<ul>
				<li>Kontaktdaten.</li>
				<li>Inhaltsdaten.</li>
				<li>Nutzungsdaten.</li>
				<li>Meta-, Kommunikations- und Verfahrensdaten.</li>
			</ul>
			<h3>Kategorien betroffener Personen</h3>
			<ul>
				<li>Kommunikationspartner.</li>
				<li>Nutzer.</li>
			</ul>
			<h3>Zwecke der Verarbeitung</h3>
			<ul>
				<li>Kontaktanfragen und Kommunikation.</li>
				<li>Sicherheitsmaßnahmen.</li>
				<li>Reichweitenmessung.</li>
				<li>Verwaltung und Beantwortung von Anfragen.</li>
				<li>Feedback.</li>
				<li>Marketing.</li>
				<li>Profile mit nutzerbezogenen Informationen.</li>
				<li>
					Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
				</li>
				<li>Informationstechnische Infrastruktur.</li>
			</ul>
			<h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
			<p>
				<strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
				Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
				auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie
				zur Kenntnis, dass neben den Regelungen der DSGVO nationale
				Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
				können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
				maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
			</p>
			<ul>
				<li>
					<strong>
						Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
						lit. b) DSGVO)
					</strong>{" "}
					- Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
					Vertragspartei die betroffene Person ist, oder zur Durchführung
					vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
					betroffenen Person erfolgen.
				</li>
				<li>
					<strong>
						Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
					</strong>{" "}
					- Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
					Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
					Interessen oder Grundrechte und Grundfreiheiten der betroffenen
					Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
				</li>
			</ul>
			<p>
				<strong>Nationale Datenschutzregelungen in Deutschland: </strong>
				Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
				Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
				das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
				Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
				insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
				Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
				personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
				Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
				einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
				einzelnen Bundesländer zur Anwendung gelangen.
			</p>
			<p>
				<strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
				Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
				schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als
				auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund
				bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen
				Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden.
				Insbesondere statt der im Schweizer DSG verwendeten Begriffe
				„Bearbeitung" von „Personendaten", "überwiegendes Interesse" und
				"besonders schützenswerte Personendaten" werden die in der DSGVO
				verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie
				"berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet.
				Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
				des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
			</p>
			<h2>Externes Hosting</h2>{" "}
			<p>
				Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf
				dieser Website erfasst werden, werden auf den Servern des Hosters / der
				Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um IP-Adressen,
				Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten,
				Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die &uuml;ber
				eine Website generiert werden, handeln.
			</p>{" "}
			<p>
				Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung
				gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
				1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
				effizienten Bereitstellung unseres Online-Angebots durch einen
				professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
				entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
				ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
				&sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
				Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers
				(z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
				Einwilligung ist jederzeit widerrufbar.
			</p>{" "}
			<p>
				Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
				wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
				und unsere Weisungen in Bezug auf diese Daten befolgen.
			</p>{" "}
			<p>Wir setzen folgende(n) Hoster ein:</p>
			<p>
				netcup GmbH
				<br />
				Daimlerstraße 25
				<br />
				D-76185 Karlsruhe
			</p>
			<h2 id="m27">Sicherheitsmaßnahmen</h2>
			<p>
				Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
				Berücksichtigung des Stands der Technik, der Implementierungskosten und
				der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
				der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
				Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
				technische und organisatorische Maßnahmen, um ein dem Risiko
				angemessenes Schutzniveau zu gewährleisten.
			</p>
			<p>
				Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
				Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
				und elektronischen Zugangs zu den Daten als auch des sie betreffenden
				Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit
				und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
				eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
				Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
				berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
				Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
				entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
				durch datenschutzfreundliche Voreinstellungen.
			</p>
			<p>
				TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die über
				unsere Online-Dienste übertragen werden, zu schützen, verwenden wir
				TLS/SSL-Verschlüsselung. Secure Sockets Layer (SSL) ist die
				Standardtechnologie zur Sicherung von Internetverbindungen durch
				Verschlüsselung der zwischen einer Website oder App und einem Browser
				(oder zwischen zwei Servern) übertragenen Daten. Transport Layer
				Security (TLS) ist eine aktualisierte und sicherere Version von SSL.
				Hyper Text Transfer Protocol Secure (HTTPS) wird in der URL angezeigt,
				wenn eine Website durch ein SSL/TLS-Zertifikat gesichert ist.
			</p>
			<h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
			<p>
				Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
				dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
				Organisationseinheiten oder Personen übermittelt oder sie ihnen
				gegenüber offengelegt werden. Zu den Empfängern dieser Daten können
				z. B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von
				Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören.
				In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen
				insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz
				Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
			</p>
			<h2 id="m12">Löschung von Daten</h2>
			<p>
				Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
				Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
				Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
				(z. B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
				sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht
				gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
				erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt.
				D. h., die Daten werden gesperrt und nicht für andere Zwecke
				verarbeitet. Das gilt z. B. für Daten, die aus handels- oder
				steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
				Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
				Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
				oder juristischen Person erforderlich ist. Unsere Datenschutzhinweise
				können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten
				beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.
			</p>
			<h2 id="m10">Rechte der betroffenen Personen</h2>
			<p>
				Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
				Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere
				aus Art. 15 bis 21 DSGVO ergeben:
			</p>
			<ul>
				<li>
					<strong>
						Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
						Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
						der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.
						6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
						gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
						Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
						Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
						Widerspruch gegen die Verarbeitung der Sie betreffenden
						personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
						dies gilt auch für das Profiling, soweit es mit solcher
						Direktwerbung in Verbindung steht.
					</strong>
				</li>
				<li>
					<strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
					Recht, erteilte Einwilligungen jederzeit zu widerrufen.
				</li>
				<li>
					<strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
					darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
					Auskunft über diese Daten sowie auf weitere Informationen und Kopie
					der Daten entsprechend den gesetzlichen Vorgaben.
				</li>
				<li>
					<strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
					gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
					betreffenden Daten oder die Berichtigung der Sie betreffenden
					unrichtigen Daten zu verlangen.
				</li>
				<li>
					<strong>
						Recht auf Löschung und Einschränkung der Verarbeitung:
					</strong>{" "}
					Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
					verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
					bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
					Einschränkung der Verarbeitung der Daten zu verlangen.
				</li>
				<li>
					<strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
					Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
					der gesetzlichen Vorgaben in einem strukturierten, gängigen und
					maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
					anderen Verantwortlichen zu fordern.
				</li>
				<li>
					<strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
					unbeschadet eines anderweitigen verwaltungsrechtlichen oder
					gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
					Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
					Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
					Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
					betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO
					verstößt.
				</li>
			</ul>
			<h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
			<p>
				Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
				Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
				IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
				unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
				übermitteln.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
					besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
					Kommunikations- und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
					Identifikationsnummern, Einwilligungsstatus).
				</li>
				<li>
					<strong>Betroffene Personen:</strong> Nutzer (z. .B.
					Webseitenbesucher, Nutzer von Onlinediensten).
				</li>
				<li>
					<strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
					Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
					Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
					technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
				</li>
				<li class="">
					<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
					1 S. 1 lit. f) DSGVO).
				</li>
			</ul>
			<p>
				<strong>
					Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff
					auf unser Onlineangebot wird in Form von so genannten
					"Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
					Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
					Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
					erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
					Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
					IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles
					können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B.,
					um eine Überlastung der Server zu vermeiden (insbesondere im Fall von
					missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
					anderen, um die Auslastung der Server und ihre Stabilität
					sicherzustellen;{" "}
					<span class="">
						<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
						Abs. 1 S. 1 lit. f) DSGVO).{" "}
					</span>
					<strong>Löschung von Daten:</strong> Logfile-Informationen werden für
					die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
					anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
					erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
					Vorfalls von der Löschung ausgenommen.
				</li>
			</ul>
			<h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
			<p>
				Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
				E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
				Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
				Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen
				und etwaiger angefragter Maßnahmen erforderlich ist.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail,
					Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen);
					Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
					Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. .B.
					IP-Adressen, Zeitangaben, Identifikationsnummern,
					Einwilligungsstatus).
				</li>
				<li>
					<strong>Betroffene Personen:</strong> Kommunikationspartner.
				</li>
				<li>
					<strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
					Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback
					(z. B. Sammeln von Feedback via Online-Formular). Bereitstellung
					unseres Onlineangebotes und Nutzerfreundlichkeit.
				</li>
				<li class="">
					<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
					1 S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen
					(Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
				</li>
			</ul>
			<p>
				<strong>
					Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>Kontaktformular: </strong>Wenn Nutzer über unser
					Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in
					Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
					mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;{" "}
					<span class="">
						<strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
						vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
						Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
					</span>
				</li>
			</ul>
			<h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
			<p>
				Die Webanalyse (auch als "Reichweitenmessung" bezeichnet) dient der
				Auswertung der Besucherströme unseres Onlineangebotes und kann
				Verhalten, Interessen oder demographische Informationen zu den
				Besuchern, wie z. B. das Alter oder das Geschlecht, als pseudonyme Werte
				umfassen. Mit Hilfe der Reichweitenanalyse können wir z. B. erkennen, zu
				welcher Zeit unser Onlineangebot oder dessen Funktionen oder Inhalte am
				häufigsten genutzt werden oder zur Wiederverwendung einladen. Ebenso
				können wir nachvollziehen, welche Bereiche der Optimierung bedürfen.{" "}
			</p>
			<p>
				Neben der Webanalyse können wir auch Testverfahren einsetzen, um z. B.
				unterschiedliche Versionen unseres Onlineangebotes oder seiner
				Bestandteile zu testen und optimieren.
			</p>
			<p>
				Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
				Profile, d. h. zu einem Nutzungsvorgang zusammengefasste Daten angelegt
				und Informationen in einem Browser, bzw. in einem Endgerät gespeichert
				und aus diesem ausgelesen werden. Zu den erhobenen Angaben gehören
				insbesondere besuchte Webseiten und dort genutzte Elemente sowie
				technische Angaben, wie der verwendete Browser, das verwendete
				Computersystem sowie Angaben zu Nutzungszeiten. Sofern Nutzer in die
				Erhebung ihrer Standortdaten uns gegenüber oder gegenüber den Anbietern
				der von uns eingesetzten Dienste einverstanden erklärt haben, können
				auch Standortdaten verarbeitet werden.
			</p>
			<p>
				Es werden ebenfalls die IP-Adressen der Nutzer gespeichert. Jedoch
				nutzen wir ein IP-Masking-Verfahren (d. h., Pseudonymisierung durch
				Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die im
				Rahmen von Webanalyse, A/B-Testings und Optimierung keine Klardaten der
				Nutzer (wie z. B. E-Mail-Adressen oder Namen) gespeichert, sondern
				Pseudonyme. D. h., wir als auch die Anbieter der eingesetzten Software
				kennen nicht die tatsächliche Identität der Nutzer, sondern nur den für
				Zwecke der jeweiligen Verfahren in deren Profilen gespeicherten Angaben.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
					besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
					Kommunikations- und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
					Identifikationsnummern, Einwilligungsstatus).
				</li>
				<li>
					<strong>Betroffene Personen:</strong> Nutzer (z. .B.
					Webseitenbesucher, Nutzer von Onlinediensten).
				</li>
				<li>
					<strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung (z. B.
					Zugriffsstatistiken, Erkennung wiederkehrender Besucher). Profile mit
					nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
				</li>
				<li>
					<strong>Sicherheitsmaßnahmen:</strong> IP-Masking (Pseudonymisierung
					der IP-Adresse).
				</li>
			</ul>
			<h2 id="m328">Plugins und eingebettete Funktionen sowie Inhalte</h2>
			<p>
				Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein,
				die von den Servern ihrer jeweiligen Anbieter (nachfolgend bezeichnet
				als "Drittanbieter") bezogen werden. Dabei kann es sich zum Beispiel um
				Grafiken, Videos oder Stadtpläne handeln (nachfolgend einheitlich
				bezeichnet als "Inhalte").
			</p>
			<p>
				Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte
				die IP-Adresse der Nutzer verarbeiten, da sie ohne die IP-Adresse die
				Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit
				für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir
				bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige Anbieter
				die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden.
				Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken,
				auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke
				verwenden. Durch die "Pixel-Tags" können Informationen, wie der
				Besucherverkehr auf den Seiten dieser Webseite, ausgewertet werden. Die
				pseudonymen Informationen können ferner in Cookies auf dem Gerät der
				Nutzer gespeichert werden und unter anderem technische Informationen zum
				Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
				Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
				enthalten als auch mit solchen Informationen aus anderen Quellen
				verbunden werden.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
					besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
					Kommunikations- und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
					Identifikationsnummern, Einwilligungsstatus).
				</li>
				<li>
					<strong>Betroffene Personen:</strong> Nutzer (z. .B.
					Webseitenbesucher, Nutzer von Onlinediensten).
				</li>
				<li>
					<strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
					Onlineangebotes und Nutzerfreundlichkeit.
				</li>
				<li class="">
					<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
					1 S. 1 lit. f) DSGVO).
				</li>
			</ul>
			<p>
				<strong>
					Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>
						Einbindung von Drittsoftware, Skripten oder Frameworks (z. B.
						jQuery):{" "}
					</strong>
					Wir binden in unser Onlineangebot Software ein, die wir von Servern
					anderer Anbieter abrufen (z. B. Funktions-Bibliotheken, die wir zwecks
					Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
					verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
					Nutzer und können diese zu Zwecken der Übermittlung der Software an
					den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
					Auswertung und Optimierung ihres Angebotes verarbeiten. - Wir binden
					in unser Onlineangebot Software ein, die wir von Servern anderer
					Anbieter abrufen (z. B. Funktions-Bibliotheken, die wir zwecks
					Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
					verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der
					Nutzer und können diese zu Zwecken der Übermittlung der Software an
					den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
					Auswertung und Optimierung ihres Angebotes verarbeiten;{" "}
					<span class="">
						<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
						Abs. 1 S. 1 lit. f) DSGVO).
					</span>
				</li>
				<li>
					<strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
					Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
					wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
					Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung
					und Berücksichtigung möglicher lizenzrechtlicher Beschränkungen. Dem
					Anbieter der Schriftarten wird die IP-Adresse des Nutzers mitgeteilt,
					damit die Schriftarten im Browser des Nutzers zur Verfügung gestellt
					werden können. Darüber hinaus werden technische Daten
					(Spracheinstellungen, Bildschirmauflösung, Betriebssystem, verwendete
					Hardware) übermittelt, die für die Bereitstellung der Schriften in
					Abhängigkeit von den verwendeten Geräten und der technischen Umgebung
					notwendig sind. Diese Daten können auf einem Server des Anbieters der
					Schriftarten in den USA verarbeitet werden - Beim Besuch unseres
					Onlineangebotes senden die Browser der Nutzer ihre Browser
					HTTP-Anfragen an die Google Fonts Web API (d. h. eine
					Softwareschnittstelle für den Abruf der Schriftarten). Die Google
					Fonts Web API stellt den Nutzern die Cascading Style Sheets (CSS) von
					Google Fonts und danach die in der CCS angegebenen Schriftarten zur
					Verfügung. Zu diesen HTTP-Anfragen gehören (1) die vom jeweiligen
					Nutzer für den Zugriff auf das Internet verwendete IP-Adresse, (2) die
					angeforderte URL auf dem Google-Server und (3) die HTTP-Header,
					einschließlich des User-Agents, der die Browser- und
					Betriebssystemversionen der Websitebesucher beschreibt, sowie die
					Verweis-URL (d. h. die Webseite, auf der die Google-Schriftart
					angezeigt werden soll). IP-Adressen werden weder auf Google-Servern
					protokolliert noch gespeichert und sie werden nicht analysiert. Die
					Google Fonts Web API protokolliert Details der HTTP-Anfragen
					(angeforderte URL, User-Agent und Verweis-URL). Der Zugriff auf diese
					Daten ist eingeschränkt und streng kontrolliert. Die angeforderte URL
					identifiziert die Schriftfamilien, für die der Nutzer Schriftarten
					laden möchte. Diese Daten werden protokolliert, damit Google bestimmen
					kann, wie oft eine bestimmte Schriftfamilie angefordert wird. Bei der
					Google Fonts Web API muss der User-Agent die Schriftart anpassen, die
					für den jeweiligen Browsertyp generiert wird. Der User-Agent wird in
					erster Linie zum Debugging protokolliert und verwendet, um aggregierte
					Nutzungsstatistiken zu generieren, mit denen die Beliebtheit von
					Schriftfamilien gemessen wird. Diese zusammengefassten
					Nutzungsstatistiken werden auf der Seite „Analysen" von Google Fonts
					veröffentlicht. Schließlich wird die Verweis-URL protokolliert, sodass
					die Daten für die Wartung der Produktion verwendet und ein
					aggregierter Bericht zu den Top-Integrationen basierend auf der Anzahl
					der Schriftartenanfragen generiert werden kann. Google verwendet laut
					eigener Auskunft keine der von Google Fonts erfassten Informationen,
					um Profile von Endnutzern zu erstellen oder zielgerichtete Anzeigen zu
					schalten; <strong>Dienstanbieter:</strong> Google Ireland Limited,
					Gordon House, Barrow Street, Dublin 4, Irland;{" "}
					<span class="">
						<strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
						Abs. 1 S. 1 lit. f) DSGVO);{" "}
					</span>
					<strong>Website:</strong>{" "}
					<a href="https://fonts.google.com/" target="_blank">
						https://fonts.google.com/
					</a>
					; <strong>Datenschutzerklärung:</strong>{" "}
					<a href="https://policies.google.com/privacy" target="_blank">
						https://policies.google.com/privacy
					</a>
					; <strong>Grundlage Drittlandübermittlung:</strong>{" "}
					<span class=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
					<strong>Weitere Informationen:</strong>{" "}
					<a
						href="https://developers.google.com/fonts/faq/privacy?hl=de"
						target="_blank"
					>
						https://developers.google.com/fonts/faq/privacy?hl=de
					</a>
					.
				</li>
			</ul>
			<h2 id="m16">Cookies</h2>
			<Button className="my-1" onClick={() => setShowCookiePreferences(true)}>
				Manage preferences
			</Button>
			<p>
				<h5>Google Analytics 4</h5>
				Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website
				Google Analytics 4 eingesetzt, ein Webanalysedienst der Google LLC.
				Verantwortliche Stelle für Nutzer in der EU/ dem EWR und der Schweiz ist
				Google Ireland Limited, Google Building Gordon House, 4 Barrow St,
				Dublin, D04 E5W5, Irland („Google“). Art und Zweck der Verarbeitung
				Google Analytics verwendet Cookies, die eine Analyse der Benutzung
				unserer Webseiten durch Sie ermöglichen. Die mittels der Cookies
				erhobenen Informationen über Ihre Benutzung dieser Website werden in der
				Regel an einen Server von Google in den USA übertragen und dort
				gespeichert. Bei Google Analytics 4 ist die Anonymisierung von
				IP-Adressen standardmäßig aktiviert. Aufgrund der IP-Anonymisierung wird
				Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
				Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
				den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die
				volle IP-Adresse an einen Server von Google in den USA übertragen und
				dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser
				übermittelte IP-Adresse wird laut Google nicht mit anderen Daten von
				Google zusammengeführt. Während Ihres Website-Besuchs wird Ihr
				Nutzerverhalten in Form von „Ereignissen“ erfasst. Ereignisse können
				sein: Seitenaufrufe Erstmaliger Besuch der Website Start der Sitzung
				Besuchte Webseiten Ihr „Klickpfad“, Interaktion mit der Website Klicks
				auf externe Links interne Suchanfragen Spracheinstellung Außerdem wird
				erfasst: Ihr ungefährer Standort (Region) Datum und Uhrzeit des Besuchs
				Ihre IP-Adresse (in gekürzter Form) technische Informationen zu Ihrem
				Browser und den von Ihnen genutzten Endgeräten (z.B. Spracheinstellung,
				Bildschirmauflösung) Ihr Internetanbieter die Referrer-URL (über welche
				Website/ über welches Werbemittel Sie auf diese Website gekommen sind)
				Zwecke der Verarbeitung Im Auftrag des Betreibers dieser Website wird
				Google diese Informationen benutzen, um Ihre Nutzung der Website
				auszuwerten und um Reports über die Website-Aktivitäten
				zusammenzustellen. Die durch Google Analytics bereitgestellten Reports
				dienen der Analyse der Leistung unserer Website. Empfänger Empfänger der
				Daten sind/können sein Google Ireland Limited, Gordon House, Barrow
				Street, Dublin 4, Irland (als Auftragsverarbeiter nach Art. 28 DSGVO)
				Google LLC, 1600 Amphitheatre Parkway Mountain View, CA 94043, USA
				Alphabet Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA
				Drittlandtransfer Für die USA hat die Europäische Kommission am 10.Juli
				2023 ihren Angemessenheitsbeschluss angenommen. Google LLC ist nach dem
				EU-US Privacy Framework zertifiziert. Da Google-Server weltweit verteilt
				sind und eine Übertragung in Drittländer (beispielsweise nach Singapur)
				nicht völlig ausgeschlossen werden kann, haben wir mit dem Anbieter
				zudem die EU-Standardvertragsklauseln abgeschlossen. Speicherdauer Die
				von uns gesendeten und mit Cookies verknüpften Daten werden nach 2
				automatisch gelöscht. Die maximale Lebensdauer der Google Analytics
				Cookies beträgt 2 Jahre. Die Löschung von Daten, deren
				Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
				Rechtsgrundlage Rechtsgrundlage für diese Datenverarbeitung ist Ihre
				Einwilligung gem. Art.6 Abs.1 S.1 lit.a DSGVO und § 25 Abs. 1 S.1 TTDSG.
				Widerruf Sie können Ihre Einwilligung jederzeit mit Wirkung für die
				Zukunft widerrufen, indem Sie die Cookie-Einstellungen aufrufen und dort
				Ihre Auswahl ändern. Die Rechtmäßigkeit der aufgrund der Einwilligung
				bis zum Widerruf erfolgten Verarbeitung bleibt davon unberührt. Sie
				können die Speicherung von Cookies auch von vornherein durch eine
				entsprechende Einstellung Ihrer Browser-Software verhindern. Wenn Sie
				Ihren Browser so konfigurieren, dass alle Cookies abgelehnt werden, kann
				es jedoch zu Einschränkung von Funktionalitäten auf dieser und anderen
				Websites kommen. Sie können darüber hinaus die Erfassung der durch das
				Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl.
				Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
				Google verhindern, indem Sie a. Ihre Einwilligung in das Setzen des
				Cookies nicht erteilen oder b. das Browser-Add-on zur Deaktivierung von
				Google Analytics HIER herunterladen und installieren. Nähere
				Informationen zu Nutzungsbedingungen von Google Analytics und zum
				Datenschutz bei Google finden Sie unter
				https://marketingplatform.google.com/about/analytics/terms/de/ und unter
				https://policies.google.com/?hl=de.
			</p>
			<h2 id="m555">Google OAuth 2.0-Authentifizierung</h2>
			<p>
				Wir verwenden Google OAuth 2.0 für die Benutzerauthentifizierung, damit
				Benutzer sich mit ihrem Google-Konto anmelden und registrieren können.
				Durch die Nutzung dieser Authentifizierungsmethode sammeln wir bestimmte
				Informationen aus Ihrem Google-Profil, einschließlich, aber nicht
				beschränkt auf Ihre E-Mail-Adresse, Ihr Profilbild und Ihren Namen.
			</p>
			<h3>Datenerhebung und Nutzung</h3>
			<p>
				Die über Google OAuth 2.0 erhobenen Daten werden verwendet, um Ihre
				Identität zu authentifizieren, Ihre Benutzererfahrung zu personalisieren
				und zu Kommunikationszwecken. Wir teilen diese Daten nicht mit Dritten,
				außer wenn dies erforderlich ist, um unsere Dienste bereitzustellen,
				oder wenn dies gesetzlich vorgeschrieben ist.
			</p>
			<h3>Datenspeicherung und Schutz</h3>
			<p>
				Ihre Daten werden sicher gespeichert und mit Verschlüsselung sowie
				anderen Sicherheitsmaßnahmen geschützt, um unbefugten Zugriff zu
				verhindern. Wir nehmen die Sicherheit Ihrer persönlichen Informationen
				ernst und überprüfen regelmäßig unsere Sicherheitspraktiken.
			</p>
			<h3>Benutzerrechte</h3>
			<p>
				Sie haben das Recht, Ihre persönlichen Informationen jederzeit
				einzusehen, zu korrigieren oder zu löschen. Um diese Rechte auszuüben,
				kontaktieren Sie uns bitte unter
				<a href="mailto:mail@cornuvotia.net">mail@cornuvotia.net</a>.
			</p>
			<h3>Zustimmung</h3>
			<p>
				Durch die Nutzung von Google OAuth 2.0 zur Authentifizierung Ihrer
				Identität stimmen Sie der Erhebung und Nutzung Ihrer Daten zu, wie in
				dieser Datenschutzerklärung beschrieben.
			</p>
			<h2 id="m556">Verwendung der OpenAI API</h2>
			<h3>Funktionen mit OpenAI</h3>
			<p>
				Wir verwenden die OpenAI API, um Ihre Erfahrung auf unserer Plattform zu
				verbessern. Konkret:
				<strong>Story-Fortsetzung:</strong> Um KI-generierte Fortsetzungen für
				von Benutzern übermittelte kreative Texte bereitzustellen.
				<strong>Schreibfeedback:</strong> Um von Benutzern übermittelte kreative
				Texte zu analysieren und Feedback zum Schreibstil zu geben.
			</p>
			<h3>Übermittelte Daten an OpenAI</h3>
			<p>
				Wenn Sie die Funktionen zur Story-Fortsetzung oder zum Schreibfeedback
				nutzen, wird der von Ihnen eingereichte Klartext an die OpenAI API zur
				Verarbeitung gesendet. Es liegt in Ihrer Verantwortung sicherzustellen,
				dass die von Ihnen übermittelten Texte keine persönlichen, sensiblen
				oder vertraulichen Informationen enthalten. Durch die Nutzung dieser
				Funktionen bestätigen Sie, dass der Inhalt ausschließlich für kreative
				Zwecke bestimmt ist.
			</p>
			<p>
				Um Ihre Daten weiter zu schützen und die DSGVO einzuhalten,
				anonymisieren wir die folgenden Entitäten, bevor Texte an OpenAI
				gesendet werden:
			</p>
			<ul>
				<li>
					<strong>Kreditkartennummern:</strong> Muster und Checksummen werden
					verwendet, um Zahlen zu erkennen und zu anonymisieren, die
					Kreditkartendaten ähneln.
				</li>
				<li>
					<strong>Krypto-Wallet-Nummern:</strong> Bitcoin-Wallet-Adressen werden
					erkannt und anonymisiert.
				</li>
				<li>
					<strong>E-Mail-Adressen:</strong> E-Mail-Adressen werden mithilfe von
					Mustererkennung, Kontext und RFC-822-Validierung erkannt und
					anonymisiert.
				</li>
				<li>
					<strong>IBAN-Codes:</strong> Internationale Bankkontonummern werden
					basierend auf Mustererkennung, Kontext und Checksummen erkannt und
					anonymisiert.
				</li>
				<li>
					<strong>IP-Adressen:</strong> Sowohl IPv4- als auch IPv6-Adressen
					werden erkannt und anonymisiert.
				</li>
				<li>
					<strong>Telefonnummern:</strong> Telefonnummern werden mithilfe
					benutzerdefinierter Logik, Mustererkennung und kontextueller Analyse
					erkannt und anonymisiert.
				</li>
				<li>
					<strong>Medizinische Lizenznummern:</strong> Gängige medizinische
					Lizenznummern werden erkannt und anonymisiert.
				</li>
				<li>
					<strong>URLs:</strong> URLs werden mithilfe von Mustererkennung,
					Kontext und Validierung auf oberster URL-Ebene erkannt und
					anonymisiert.
				</li>
			</ul>
			<p>
				Diese Maßnahmen sollen das Risiko verringern, dass versehentlich
				persönliche oder sensible Daten übermittelt werden. Es bleibt jedoch in
				der Verantwortung der Benutzer sicherzustellen, dass keine sensiblen
				Informationen in ihren Einreichungen enthalten sind.
			</p>
			<h3>Zweck der Datenverarbeitung</h3>
			<p>
				Die Daten werden ausschließlich von OpenAI verarbeitet, um:
				<strong>Story-Fortsetzungen</strong> für Ihre Einreichungen zu
				generieren und
				<strong>Feedback zu Ihrem Schreibstil</strong> bereitzustellen.
			</p>
			<h3>Datenverarbeitung</h3>
			<p>
				<strong>Protokollierung:</strong> Wir erfassen Metadaten (z. B.
				Zeitstempel und Benutzer-IDs) zur Nachverfolgung von API-Aufrufen und
				zur Überwachung der Dienste. Wir speichern keine Inhalte der an OpenAI
				gesendeten oder von dort empfangenen Texte.
			</p>
			<h3>Dritte</h3>
			<p>
				Ihre Daten werden nur mit OpenAI für die oben beschriebenen Zwecke
				geteilt. OpenAI verarbeitet die Daten gemäß ihrer{" "}
				<a href="https://openai.com/policies/privacy-policy/">
					Datenschutzerklärung
				</a>
				.
			</p>
			<h3>Benutzerzustimmung und Transparenz</h3>
			<p>
				Durch die Nutzung der Funktionen zur Story-Fortsetzung und zum
				Schreibfeedback erklären Sie sich damit einverstanden, dass Ihre
				eingereichten Texte zur Verarbeitung an OpenAI gesendet werden. Sie
				werden über diese Verarbeitung beim Nutzen der Funktion informiert.
			</p>
			<h3>Ihre Rechte</h3>
			<p>
				Im Rahmen der DSGVO haben Sie die folgenden Rechte:
				<strong>Zugriff und Löschung:</strong> Sie können Ihr Konto jederzeit
				löschen, wodurch Ihre Daten dauerhaft aus unseren Systemen entfernt
				werden.
				<strong>Datenschutzanfragen:</strong> Wenn Sie Fragen zur Verarbeitung
				Ihrer Daten haben, können Sie uns unter{" "}
				<a href="mailto:mail@cornuvotia.net">mail@cornuvotia.net</a>{" "}
				kontaktieren.
				<strong>Widerruf der Zustimmung:</strong> Sie können die Nutzung der
				Funktionen, die OpenAI einbeziehen, einstellen, um Ihre Zustimmung zur
				Datenverarbeitung zu widerrufen.
			</p>
			<h2 id="m15">Änderungen und Aktualisierungen der Datenschutzerklärung</h2>
			<p>
				Wir bitten Sie, sich regelmäßig über den Inhalt unserer
				Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
				an, sobald Änderungen in der von uns durchgeführten Datenverarbeitung
				dies erfordern. Wir werden Sie informieren, sobald die Änderungen eine
				Handlung Ihrerseits (z. B. Zustimmung) oder eine individuelle
				Benachrichtigung erfordern.
			</p>
			<p>
				Falls wir in dieser Datenschutzerklärung Adressen und
				Kontaktinformationen von Unternehmen und Organisationen bereitstellen,
				beachten Sie bitte, dass sich diese Adressen im Laufe der Zeit ändern
				können, und es wird empfohlen, die Informationen vor der Kontaktaufnahme
				zu überprüfen.
			</p>
			<h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
			<p>
				Wir bitten Sie, sich regelmäßig über den Inhalt unserer
				Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
				an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
				dies erforderlich machen. Wir informieren Sie, sobald durch die
				Änderungen eine Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder
				eine sonstige individuelle Benachrichtigung erforderlich wird.
			</p>
			<p>
				Sofern wir in dieser Datenschutzerklärung Adressen und
				Kontaktinformationen von Unternehmen und Organisationen angeben, bitten
				wir zu beachten, dass die Adressen sich über die Zeit ändern können und
				bitten die Angaben vor Kontaktaufnahme zu prüfen.
			</p>
		</>
	);
};

const PrivacyPolicyEnglish = () => {
	const [showCookiePreferences, setShowCookiePreferences] = useState(false);
	return (
		<>
			{showCookiePreferences && (
				<CookieConsent
					onAccept={() => null}
					onReject={() => window.location.reload()}
					initialShow={true}
				/>
			)}
			<h1>Privacy Policy</h1>
			<h2 id="m4158">Preamble</h2>
			<p>
				With the following privacy policy, we would like to inform you about the
				types of your personal data (hereinafter also referred to briefly as
				"data") we process for what purposes and to what extent in the context
				of providing our application.
			</p>
			<p>The terms used are not gender-specific.</p>
			<p>Date: December 16, 2023</p>
			<h2>Table of Contents</h2>{" "}
			<ul class="index">
				<li>
					<a class="index-link" href="#m4158">
						Preamble
					</a>
				</li>
				<li>
					<a class="index-link" href="#m3">
						Controller
					</a>
				</li>
				<li>
					<a class="index-link" href="#mOverview">
						Overview of Processing Activities
					</a>
				</li>
				<li>
					<a class="index-link" href="#m2427">
						Relevant Legal Bases
					</a>
				</li>
				<li>
					<a class="index-link" href="#m27">
						Security Measures
					</a>
				</li>
				<li>
					<a class="index-link" href="#m25">
						Transmission of Personal Data
					</a>
				</li>
				<li>
					<a class="index-link" href="#m12">
						Deletion of Data
					</a>
				</li>
				<li>
					<a class="index-link" href="#m10">
						Rights of the Data Subjects
					</a>
				</li>
				<li>
					<a class="index-link" href="#m225">
						Provision of the Online Offer and Web Hosting
					</a>
				</li>
				<li>
					<a class="index-link" href="#m182">
						Contact and Inquiry Management
					</a>
				</li>
				<li>
					<a class="index-link" href="#m263">
						Web Analysis, Monitoring, and Optimization
					</a>
				</li>
				<li>
					<a class="index-link" href="#m136">
						Presences in Social Networks (Social Media)
					</a>
				</li>
				<li>
					<a class="index-link" href="#m328">
						Plugins and Embedded Functions and Content
					</a>
				</li>
				<li>
					<a class="index-link" href="#m555">
						Google OAuth 2.0 Authentication
					</a>
				</li>
				<li>
					<a class="index-link" href="#m556">
						Use of OpenAI API
					</a>
				</li>
				<li>
					<a class="index-link" href="#m15">
						Modification and Update of the Privacy Policy
					</a>
				</li>
			</ul>
			<h2 id="m3">Controller</h2>
			<p>see legal notice</p>
			E-mail address:{" "}
			<p>
				<a href="mailto:cornuvotia@gmail.com">cornuvotia@gmail.com</a>
			</p>
			<h2 id="mOverview">Overview of Processing Activities</h2>
			<p>
				The following overview summarizes the types of data processed and the
				purposes of their processing and refers to the data subjects.
			</p>
			<h3>Types of Processed Data</h3>
			<ul>
				<li>Contact Data.</li>
				<li>Content Data.</li>
				<li>Usage Data.</li>
				<li>Meta/communication and procedural data.</li>
			</ul>
			<h3>Categories of Data Subjects</h3>
			<ul>
				<li>Communication partners.</li>
				<li>Users.</li>
			</ul>
			<h3>Purposes of Processing</h3>
			<ul>
				<li>Contact inquiries and communication.</li>
				<li>Security measures.</li>
				<li>Reach measurement.</li>
				<li>Administration and response to inquiries.</li>
				<li>Feedback.</li>
				<li>Marketing.</li>
				<li>Profiles with user-related information.</li>
				<li>Provision of our online offer and user-friendliness.</li>
				<li>Information technology infrastructure.</li>
			</ul>
			<h2 id="m2427">Relevant Legal Bases</h2>
			<p>
				<strong>Relevant legal bases according to GDPR: </strong>Below, you will
				find an overview of the legal bases of the GDPR, on which basis we
				process personal data. Please note that in addition to the provisions of
				the GDPR, national data protection regulations may apply in your or our
				country of residence or domicile. Furthermore, if more specific legal
				bases are relevant in individual cases, we will inform you in the
				privacy policy.
			</p>
			<ul>
				<li>
					<strong>
						Contract performance and pre-contractual inquiries (Art. 6 para. 1
						s. 1 lit. b) GDPR)
					</strong>{" "}
					- The processing is necessary for the performance of a contract to
					which the data subject is a party, or for the execution of
					pre-contractual measures taken at the data subject's request.
				</li>
				<li>
					<strong>
						Legitimate interests (Art. 6 para. 1 s. 1 lit. f) GDPR)
					</strong>{" "}
					- The processing is necessary for the purposes of the legitimate
					interests pursued by the controller or by a third party, except where
					such interests are overridden by the interests or fundamental rights
					and freedoms of the data subject which require protection of personal
					data.
				</li>
			</ul>
			<p>
				<strong>National data protection regulations in Germany: </strong>In
				addition to the data protection regulations of the GDPR, national
				regulations on data protection in Germany apply. This includes in
				particular the Law to Protect Against Misuse of Personal Data in Data
				Processing (Federal Data Protection Act – BDSG). The BDSG contains
				special regulations on the right to access, the right to deletion, the
				right to object, the processing of special categories of personal data,
				processing for other purposes and transmission as well as automated
				decision-making in individual cases including profiling. Furthermore,
				data protection laws of the individual federal states may apply.
			</p>
			<p>
				<strong>Note on the applicability of the GDPR and Swiss DPA: </strong>
				These data protection notices serve both to provide information
				according to the Swiss Federal Act on Data Protection (Swiss DPA) and
				according to the General Data Protection Regulation (GDPR). For this
				reason, we ask you to note that due to the broader spatial application
				and comprehensibility, the terms of the GDPR are used. In particular,
				instead of the terms "processing" of "personal data", "predominant
				interest" and "particularly protectable personal data" used in the Swiss
				DPA, the terms "processing" of "personal data" as well as "legitimate
				interest" and "special categories of data" used in the GDPR are used.
				However, the legal meaning of the terms is still determined within the
				scope of the applicability of the Swiss DPA according to the Swiss DPA.
			</p>
			<h2>External Hosting</h2>{" "}
			<p>
				This website is hosted externally. The personal data collected on this
				website are stored on the servers of the host(s). This may include, but
				is not limited to, IP addresses, contact inquiries, meta and
				communication data, contract data, contact details, names, website
				accesses, and other data generated through a website.
			</p>{" "}
			<p>
				The external hosting is carried out for the purpose of contract
				fulfillment towards our potential and existing customers (Art. 6 para. 1
				lit. b GDPR) and in the interest of a secure, fast, and efficient
				provision of our online offer by a professional provider (Art. 6 para. 1
				lit. f GDPR). If a corresponding consent has been requested, the
				processing is carried out exclusively on the basis of Art. 6 para. 1
				lit. a GDPR and § 25 para. 1 TTDSG, insofar as the consent includes the
				storage of cookies or access to information in the user's end device
				(e.g., device fingerprinting) within the meaning of the TTDSG. The
				consent can be revoked at any time.
			</p>{" "}
			<p>
				Our host(s) will only process your data to the extent necessary to
				fulfill their service obligations and follow our instructions regarding
				these data.
			</p>{" "}
			<p>We employ the following host(s):</p>
			<p>
				netcup GmbH
				<br />
				Daimlerstraße 25
				<br />
				D-76185 Karlsruhe
			</p>
			<h2 id="m27">Security Measures</h2>
			<p>
				We take appropriate technical and organizational measures in accordance
				with legal requirements, taking into account the state of the art, the
				costs of implementation, and the nature, scope, circumstances, and
				purposes of processing as well as the varying likelihood and severity of
				the risk to the rights and freedoms of natural persons, to ensure a
				level of security appropriate to the risk.
			</p>
			<p>
				Among the measures are ensuring the confidentiality, integrity, and
				availability of data by controlling physical and electronic access to
				the data as well as access to, input, transfer, ensuring availability,
				and their separation. Furthermore, we have established procedures to
				ensure the exercise of data subjects' rights, deletion of data, and
				reaction to data risks. Moreover, we consider the protection of personal
				data already in the development or selection of hardware, software, and
				procedures, according to the principle of privacy by design and privacy
				by default.
			</p>
			<p>
				TLS/SSL encryption (https): To protect the data transmitted via our
				online services, we use TLS/SSL encryption. Secure Sockets Layer (SSL)
				is the standard technology for securing internet connections by
				encrypting data transmitted between a website or app and a browser (or
				between two servers). Transport Layer Security (TLS) is an updated and
				more secure version of SSL. Hyper Text Transfer Protocol Secure (HTTPS)
				is displayed in the URL when a website is secured by an SSL/TLS
				certificate.
			</p>
			<h2 id="m25">Transmission of Personal Data</h2>
			<p>
				In the course of our processing of personal data, it happens that the
				data is transferred to other places, companies, legally independent
				organizational units, or persons. The recipients of these data can
				include, for example, service providers entrusted with IT tasks or
				providers of services and content that are embedded in a website. In
				such cases, we observe the legal requirements and conclude appropriate
				contracts or agreements, which serve the protection of your data, with
				the recipients of your data.
			</p>
			<h2 id="m12">Deletion of Data</h2>
			<p>
				The data processed by us are deleted in accordance with legal
				requirements as soon as their consents allowed for processing are
				revoked or other permissions no longer apply (e.g., if the purpose of
				processing these data has ceased to apply or they are not required for
				the purpose). If the data are not deleted because they are required for
				other and legally permissible purposes, their processing will be
				restricted to these purposes. That is, the data are locked and not
				processed for other purposes. This applies, for example, to data that
				must be kept for commercial or tax reasons or whose storage is necessary
				for the assertion, exercise, or defense of legal claims or to protect
				the rights of another natural or legal person. Our privacy notices may
				also contain further information on the retention and deletion of data,
				which prevail for the respective processing operations.
			</p>
			<h2 id="m10">Rights of the Data Subjects</h2>
			<p>
				Rights of the data subjects according to the GDPR: You have various
				rights under the GDPR, which arise in particular from Articles 15 to 21
				GDPR:
			</p>
			<ul>
				<li>
					<strong>
						Right to object: You have the right to object at any time, on
						grounds relating to your particular situation, to the processing of
						personal data concerning you which is based on Article 6(1)(e or f)
						GDPR, including profiling based on those provisions. If the personal
						data concerning you are processed for direct marketing purposes, you
						have the right to object at any time to the processing of personal
						data concerning you for such marketing, which includes profiling to
						the extent that it is related to such direct marketing.
					</strong>
				</li>
				<li>
					<strong>Right to withdraw consents:</strong> You have the right to
					withdraw consents at any time.
				</li>
				<li>
					<strong>Right to access:</strong> You have the right to request a
					confirmation as to whether data concerned are being processed and to
					request information about these data as well as further information
					and a copy of the data in accordance with legal requirements.
				</li>
				<li>
					<strong>Right to rectification:</strong> You have the right, in
					accordance with legal requirements, to request the completion of the
					data concerning you or the correction of the incorrect data concerning
					you.
				</li>
				<li>
					<strong>Right to erasure and restriction of processing:</strong> You
					have the right, under the conditions of the legal requirements, to
					demand that relevant data be erased immediately, or, alternatively, to
					demand a restriction of the processing of the data in accordance with
					legal requirements.
				</li>
				<li>
					<strong>Right to data portability:</strong> You have the right to
					receive data concerning you, which you have provided to us, in a
					structured, common, and machine-readable format in accordance with
					legal requirements, or to request their transmission to another
					controller.
				</li>
				<li>
					<strong>Complaint to supervisory authority:</strong> You have the
					right to complain to a supervisory authority, in particular in the
					member state of your habitual residence, place of work, or place of
					the alleged infringement, if you consider that the processing of
					personal data relating to you infringes the GDPR.
				</li>
			</ul>
			<h2 id="m225">Provision of the Online Offer and Web Hosting</h2>
			<p>
				We process the data of users to be able to offer them our online
				services. For this purpose, we process the IP address of the user, which
				is necessary to transmit the contents and functions of our online
				services to the browser or device of the user.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Types of Processed Data:</strong> Usage data (e.g., visited
					websites, interest in content, access times); Meta-, communication,
					and procedural data (e.g., IP addresses, timestamps, identification
					numbers, consent status).
				</li>
				<li>
					<strong>Data Subjects:</strong> Users (e.g., website visitors, users
					of online services).
				</li>
				<li>
					<strong>Purposes of Processing:</strong> Provision of our online offer
					and user-friendliness; Information technology infrastructure
					(operation and provision of information systems and technical devices
					(computers, servers, etc.)). Security measures.
				</li>
				<li class="">
					<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1 s.
					1 lit. f) GDPR).
				</li>
			</ul>
			<p>
				<strong>
					Further Information on Processing Operations, Procedures and Services:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>Collection of Access Data and Log Files: </strong>Access to
					our online offering is logged in the form of so-called "server log
					files". Server log files may include the address and name of the
					accessed web pages and files, date and time of access, transferred
					data volumes, notification of successful retrieval, browser type and
					version, the user's operating system, referrer URL (the previously
					visited page) and, as a rule, IP addresses and the requesting
					provider. Server log files can be used for security purposes, e.g., to
					prevent overload of the servers (especially in the case of abusive
					attacks, so-called DDoS attacks) and on the other hand, to ensure the
					utilization of the servers and their stability;{" "}
					<span class="">
						<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1
						s. 1 lit. f) GDPR).{" "}
					</span>
					<strong>Deletion of Data:</strong> Log file information is stored for
					a maximum of 30 days and then deleted or anonymized. Data whose
					further retention is necessary for evidential purposes are excluded
					from deletion until the respective incident has been finally
					clarified.
				</li>
			</ul>
			<h2 id="m182">Contact and Inquiry Management</h2>
			<p>
				When contacting us (e.g., by mail, contact form, email, telephone or via
				social media) and in the context of existing user and business
				relationships, the details of the inquiring persons are processed as far
				as necessary to respond to contact inquiries and any requested measures.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Types of Processed Data:</strong> Contact data (e.g., email,
					telephone numbers); Content data (e.g., entries in online forms);
					Usage data (e.g., visited websites, interest in content, access
					times); Meta-, communication, and procedural data (e.g., IP addresses,
					timestamps, identification numbers, consent status).
				</li>
				<li>
					<strong>Data Subjects:</strong> Communication partners.
				</li>
				<li>
					<strong>Purposes of Processing:</strong> Contact inquiries and
					communication; Management and response to inquiries; Feedback (e.g.,
					collecting feedback via online form). Provision of our online offer
					and user-friendliness.
				</li>
				<li class="">
					<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1 s.
					1 lit. f) GDPR). Performance of a contract and pre-contractual
					inquiries (Art. 6 para. 1 s. 1 lit. b) GDPR).
				</li>
			</ul>
			<p>
				<strong>
					Further Information on Processing Operations, Procedures and Services:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>Contact Form: </strong>When users contact us via our contact
					form, email or other communication channels, we process the data
					communicated to us in this context to handle the communicated concern;{" "}
					<span class="">
						<strong>Legal Basis:</strong> Performance of a contract and
						pre-contractual inquiries (Art. 6 para. 1 s. 1 lit. b) GDPR),
						Legitimate interests (Art. 6 para. 1 s. 1 lit. f) GDPR).
					</span>
				</li>
			</ul>
			<h2 id="m263">Web Analysis, Monitoring and Optimization</h2>
			<p>
				Web analysis (also referred to as "reach measurement") serves to
				evaluate the visitor flows of our online offer and may include behavior,
				interests, or demographic information about the visitors, such as age or
				gender, as pseudonymous values. With the help of reach analysis, we can,
				for example, recognize at what time our online offer or its functions or
				contents are most frequently used or invite to reuse. We can also
				understand which areas need optimization.
			</p>
			<p>
				In addition to web analysis, we can also use testing procedures to test
				and optimize different versions of our online offer or its components.
			</p>
			<p>
				If not specified otherwise below, profiles, i.e., data summarized into a
				usage process, can be created and stored in a browser or on an end
				device and read from it for these purposes. The collected data includes
				in particular visited websites and elements used there, as well as
				technical information such as the browser used, the computer system
				used, and information on usage times. If users have consented to the
				collection of their location data vis-à-vis us or the providers of the
				services we use, location data can also be processed.
			</p>
			<p>
				IP addresses of users are also stored. However, we use an IP masking
				process (i.e., pseudonymization by shortening the IP address) to protect
				users. Generally, no clear data of users (such as email addresses or
				names) are stored in the context of web analysis, A/B testing, and
				optimization, but pseudonyms. I.e., neither we nor the providers of the
				used software know the actual identity of the users, but only the
				information stored in their profiles for the purposes of the respective
				procedures.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Types of Processed Data:</strong> Usage data (e.g., visited
					websites, interest in content, access times); Meta-, communication,
					and procedural data (e.g., IP addresses, timestamps, identification
					numbers, consent status).
				</li>
				<li>
					<strong>Data Subjects:</strong> Users (e.g., website visitors, users
					of online services).
				</li>
				<li>
					<strong>Purposes of Processing:</strong> Reach measurement (e.g.,
					access statistics, detection of returning visitors). Profiles with
					user-related information (creation of user profiles).
				</li>
				<li>
					<strong>Security Measures:</strong> IP masking (pseudonymization of
					the IP address).
				</li>
			</ul>
			<h2 id="m328">Plugins and Embedded Functions and Content</h2>
			<p>
				We integrate functional and content elements into our online offer,
				which are obtained from the servers of their respective providers
				(hereinafter referred to as "third-party providers"). This may include,
				for example, graphics, videos, or city maps (hereinafter uniformly
				referred to as "contents").
			</p>
			<p>
				The integration always requires that the third-party providers of these
				contents process the IP address of the users, as without the IP address
				they could not send the contents to their browsers. The IP address is
				thus required for the display of these contents or functions. We strive
				to use only those contents whose respective providers use the IP address
				solely for the delivery of the contents. Third-party providers may also
				use so-called pixel tags (invisible graphics, also known as "web
				beacons") for statistical or marketing purposes. Through the "pixel
				tags," information such as visitor traffic on the pages of this website
				can be evaluated. The pseudonymous information can also be stored in
				cookies on the user's device and may include, among other things,
				technical information about the browser and operating system, referring
				web pages, visit time, as well as other information about the use of our
				online offer, and be linked with such information from other sources.
			</p>
			<ul class="m-elements">
				<li>
					<strong>Types of Processed Data:</strong> Usage data (e.g., visited
					websites, interest in content, access times); Meta-, communication,
					and procedural data (e.g., IP addresses, timestamps, identification
					numbers, consent status).
				</li>
				<li>
					<strong>Data Subjects:</strong> Users (e.g., website visitors, users
					of online services).
				</li>
				<li>
					<strong>Purposes of Processing:</strong> Provision of our online offer
					and user-friendliness.
				</li>
				<li class="">
					<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1 s.
					1 lit. f) GDPR).
				</li>
			</ul>
			<p>
				<strong>
					Further Information on Processing Operations, Procedures and Services:
				</strong>
			</p>
			<ul class="m-elements">
				<li>
					<strong>
						Integration of Third-Party Software, Scripts, or Frameworks (e.g.,
						jQuery):{" "}
					</strong>
					We integrate software into our online offer, which we retrieve from
					servers of other providers (e.g., function libraries that we use for
					display or user-friendliness of our online offer). In this process,
					the respective providers collect the IP address of the users and may
					process it for the purpose of transmitting the software to the user's
					browser as well as for security purposes, and also for evaluation and
					optimization of their offer. - We integrate software into our online
					offer, which we retrieve from servers of other providers (e.g.,
					function libraries that we use for display or user-friendliness of our
					online offer). In this process, the respective providers collect the
					IP address of the users and may process it for the purpose of
					transmitting the software to the user's browser as well as for
					security purposes, and also for evaluation and optimization of their
					offer;{" "}
					<span class="">
						<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1
						s. 1 lit. f) GDPR).
					</span>
				</li>
				<li>
					<strong>Google Fonts (obtained from Google servers): </strong>
					Obtaining fonts (and symbols) for the purpose of a technically secure,
					maintenance-free and efficient use of fonts and symbols with regard to
					currency and loading times, their uniform presentation, and
					consideration of possible licensing restrictions. The provider of the
					fonts is notified of the user's IP address so that the fonts can be
					made available in the user's browser. In addition, technical data
					(language settings, screen resolution, operating system, used
					hardware) are transmitted, which are necessary for the provision of
					the fonts depending on the devices used and the technical environment.
					This data can be processed on a server of the font provider in the USA
					- When visiting our online offer, the browsers of the users send their
					browser HTTP requests to the Google Fonts Web API (i.e., a software
					interface for retrieving the fonts). The Google Fonts Web API provides
					users with the Cascading Style Sheets (CSS) of Google Fonts and then
					the fonts specified in the CSS. These HTTP requests include (1) the IP
					address used by the respective user to access the Internet, (2) the
					requested URL on the Google server, and (3) the HTTP headers,
					including the User-Agent, which describes the browser and operating
					system versions of the website visitors, as well as the referring URL
					(i.e., the webpage where the Google font is to be displayed). IP
					addresses are neither logged nor stored on Google servers and they are
					not analyzed. The Google Fonts Web API logs details of the HTTP
					requests (requested URL, User-Agent, and referring URL). Access to
					this data is restricted and strictly controlled. The requested URL
					identifies the font families that the user wants to load fonts for.
					This data is logged so that Google can determine how often a
					particular font family is requested. With the Google Fonts Web API,
					the User-Agent must adjust the font that is generated for the
					respective browser type. The User-Agent is primarily logged for
					debugging purposes and used to generate aggregated usage statistics,
					which measure the popularity of font families. These aggregated usage
					statistics are published on the "Analytics" page of Google Fonts.
					Finally, the referring URL is logged so that the data can be used for
					production maintenance and an aggregated report on the top
					integrations based on the number of font requests can be generated.
					According to its own statements, Google does not use any of the
					information collected by Google Fonts to create profiles of end-users
					or to display targeted advertisements;{" "}
					<strong>Service provider:</strong> Google Ireland Limited, Gordon
					House, Barrow Street, Dublin 4, Ireland;{" "}
					<span class="">
						<strong>Legal Basis:</strong> Legitimate interests (Art. 6 para. 1
						s. 1 lit. f) GDPR);{" "}
					</span>
					<strong>Website:</strong>{" "}
					<a href="https://fonts.google.com/" target="_blank">
						https://fonts.google.com/
					</a>
					; <strong>Privacy Policy:</strong>{" "}
					<a href="https://policies.google.com/privacy" target="_blank">
						https://policies.google.com/privacy
					</a>
					; <strong>Third Country Transfer Basis:</strong>{" "}
					<span class=""> EU-US Data Privacy Framework (DPF)</span>.{" "}
					<strong>More information:</strong>{" "}
					<a
						href="https://developers.google.com/fonts/faq/privacy?hl=de"
						target="_blank"
					>
						https://developers.google.com/fonts/faq/privacy?hl=de
					</a>
					.
				</li>
			</ul>
			<h2 id="m16">Cookies</h2>
			<p>
				<Button className="my-1" onClick={() => setShowCookiePreferences(true)}>
					Manage preferences
				</Button>
				<h5> Google Analytics 4</h5>
				If you have given your consent, this website uses Google Analytics 4, a
				web analytics service provided by Google LLC. The controller for users
				in the EU/EEA and Switzerland is Google Ireland Limited, Google Building
				Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland ("Google"). Nature
				and purpose of the processing Google Analytics 4 uses cookies that
				enable an analysis of your use of our websites. The information
				collected by means of the cookies about your use of this website is
				generally transferred to a Google server in the USA and stored there.
				Google Analytics 4 has IP anonymisation enabled by default. Due to IP
				anonymisation, your IP address will be shortened by Google within Member
				States of the European Union or in other states party to the Agreement
				on the European Economic Area. Only in exceptional cases will the full
				IP address be transferred to a Google server in the USA and shortened
				there. According to Google, the IP address transferred by your browser
				as part of Google Analytics will not be merged with other Google data.
				During your website visit, your user behaviour is recorded in the form
				of "events". Events can be: Page views First visit to the website Start
				of session Web pages visited Your "click path", interaction with the
				website Clicks on external links Internal search queries Also recorded:
				Your approximate location (region) Date and time of your visit Your IP
				address (in shortened form) Technical information about your browser and
				the end devices you use (e.g. language setting, screen resolution) your
				internet service provider. the referrer URL (via which
				website/advertising medium you came to this website) Purposes of the
				data processing On behalf of the operator Google will use this
				information to evaluate your use of the website and to compile reports
				on website activity. The reports provided by Google Analytics 4 serve to
				analyse the performance of our website Recipients Recipients of the data
				are/may be Google Ireland Limited, Gordon House, Barrow Street, Dublin
				4, Ireland (as processor under Art. 28 DSGVO). Google LLC, 1600
				Amphitheatre Parkway Mountain View, CA 94043, USA Alphabet Inc, 1600
				Amphitheatre Parkway Mountain View, CA 94043, USA Third country transfer
				For the USA, the European Commission adopted a news adequacy decision on
				10 July 2023. Google LLC is certified under the EU-US Privacy Framework.
				Since Google servers are distributed worldwide and a transfer to third
				countries (for example to Singapore) cannot be completely ruled out, we
				have also concluded the EU standard contractual clauses with the
				provider to establish an appropriate level of data protection in those
				countries. Retention period The data sent by us and linked to cookies
				are automatically deleted after 2 . The maximum lifespan of Google
				Analytics cookies is 2 years. The deletion of data whose retention
				period has been reached occurs automatically once a month. Legal basis
				The legal basis for this data processing is your consent pursuant to
				Art.6 para.1 p.1 lit.a GDPR and § 25 para. 1 p.1 TTDSG. Withdrawal You
				can withdraw your consent at any time with effect for the future by
				accessing the cookie settings and changing your selection there. The
				lawfulness of the processing carried out on the basis of the consent
				until revocation remains unaffected. You can also prevent the storage of
				cookies from the outset by setting your browser software accordingly.
				However, if you configure your browser to reject all cookies, this may
				result in a restriction of functionalities on this and other websites.
				You can also prevent the collection of data generated by the cookie and
				relating to your use of the website (including your IP address) by
				Google, and the processing of this data by Google, by a. not giving your
				consent to the setting of the cookie or b. downloading and installing
				the browser add-on to deactivate Google Analytics HERE. For more
				information on Google Analytics' terms of use and Google's privacy
				policy, please visit
				https://marketingplatform.google.com/about/analytics/terms/us/ and at
				https://policies.google.com/?hl=en.
			</p>
			<h2 id="m555">Google OAuth 2.0 Authentication</h2>
			<p>
				We use Google OAuth 2.0 for user authentication to allow users to sign
				up and sign in using their Google account. By using this authentication
				method, we collect certain information from your Google profile,
				including but not limited to your email address, profile picture, and
				name.
			</p>
			<h3>Data Collection and Usage</h3>
			<p>
				The data collected through Google OAuth 2.0 is used to authenticate your
				identity, personalize your user experience, and for communication
				purposes. We do not share this data with third parties, except as
				necessary to provide our services or as required by law.
			</p>
			<h3>Data Storage and Protection</h3>
			<p>
				Your data is securely stored and protected with encryption and other
				security measures to prevent unauthorized access. We take the security
				of your personal information seriously and regularly review our security
				practices.
			</p>
			<h3>User Rights</h3>
			<p>
				You have the right to access, correct, or delete your personal
				information at any time. To exercise these rights, please contact us at{" "}
				<a href="mailto:mail@cornuvotia.net">mail@cornuvotia.net</a>.
			</p>
			<h3>Consent</h3>
			<p>
				By using Google OAuth 2.0 to authenticate your identity, you consent to
				the collection and use of your data as described in this privacy policy.
			</p>
			<h2 id="m556">Use of OpenAI API </h2>
			<h3>Features Involving OpenAI</h3>
			<p>
				We use the OpenAI API to enhance your experience on our platform.
				Specifically: Story Continuation: To provide AI-generated continuations
				for user-submitted creative text. Writing Feedback: To analyze
				user-submitted creative text and offer feedback on writing style.
			</p>
			<h3>Data Sent to OpenAI</h3>
			<p>
				When you use the story continuation or writing feedback features, the
				plain text you submit is sent to OpenAI's API for processing. It is your
				responsibility to ensure that the text you submit does not contain
				personal, sensitive, or confidential information. By using these
				features, you confirm that the content is intended solely for creative
				purposes.
			</p>
			<p>
				To further protect your data and comply with GDPR, we will anonymize the
				following entities before sending text to OpenAI:
			</p>
			<ul>
				<li>
					<strong>Credit Card Numbers:</strong> Patterns and checksums are used
					to detect and anonymize numbers resembling credit card details.
				</li>
				<li>
					<strong>Crypto Wallet Numbers:</strong> Bitcoin wallet addresses will
					be detected and anonymized.
				</li>
				<li>
					<strong>Email Addresses:</strong> Email addresses will be detected and
					anonymized using pattern matching, context, and RFC-822 validation.
				</li>
				<li>
					<strong>IBAN Codes:</strong> International Bank Account Numbers will
					be detected and anonymized based on pattern matching, context, and
					checksums.
				</li>
				<li>
					<strong>IP Addresses:</strong> Both IPv4 and IPv6 addresses will be
					detected and anonymized.
				</li>
				<li>
					<strong>Phone Numbers:</strong> Telephone numbers will be detected and
					anonymized using custom logic, pattern matching, and contextual
					analysis.
				</li>
				<li>
					<strong>Medical License Numbers:</strong> Common medical license
					numbers will be detected and anonymized.
				</li>
				<li>
					<strong>URLs:</strong> URLs will be detected and anonymized using
					pattern matching, context, and top-level URL validation.
				</li>
			</ul>
			<p>
				These measures are designed to minimize the likelihood of inadvertently
				transmitting personal or sensitive data. However, it remains the user's
				responsibility to ensure that sensitive information is not included in
				their submissions.
			</p>
			<h3>Purpose of Data Processing</h3>
			<p>
				The data is processed by OpenAI solely to: Generate story continuations
				for your submissions. Provide feedback on your writing style.
			</p>
			<h3>Data Handling </h3>
			<p>
				Logging: We record metadata (e.g., timestamps and user IDs) for API call
				tracking and service monitoring. We do not store the content of the text
				sent to or received from OpenAI.
			</p>
			<h3>Third Parties </h3>
			<p>
				Your data is shared only with OpenAI for the purposes outlined above.
				OpenAI processes the data in accordance with their Privacy Policy{" "}
				<a href="https://openai.com/policies/privacy-policy/">
					https://openai.com/policies/privacy-policy/
				</a>
				.
			</p>
			<h3>User Consent and Transparency</h3>
			<p>
				By using the story continuation and writing feedback features, you
				consent to your submitted text being sent to OpenAI for processing. You
				are informed of this processing when using the feature.
			</p>
			<h3>Your Rights </h3>
			<p>
				Under GDPR, you have the following rights: Access and Deletion: You can
				delete your account at any time, which will permanently remove your data
				from our systems. Data Concerns: If you have questions about how your
				data is handled, you can contact us at mail@cornuvotia.net. Withdraw
				Consent: You can stop using the features that involve OpenAI to withdraw
				your consent for data processing.
			</p>
			<h2 id="m15">Changes and Updates to the Privacy Policy</h2>
			<p>
				We ask you to regularly inform yourself about the content of our privacy
				policy. We adapt the privacy policy as soon as the changes in the data
				processing carried out by us require it. We will inform you as soon as
				the changes require an action on your part (e.g., consent) or other
				individual notification.
			</p>
			<p>
				If we provide addresses and contact information of companies and
				organizations in this privacy policy, please note that the addresses can
				change over time and it is advised to verify the information before
				contacting.
			</p>
		</>
	);
};

export { PrivacyPolicyGerman, PrivacyPolicyEnglish };
