import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { Discord } from "react-bootstrap-icons";

const FAQ = [
	{
		question: "What does 'Cornuvotia' mean?",
		answer:
			"Cornuvotia is a portmanteau from cornucopia and vote. The name of the platform captures its essence which is participating in collective creativity, having a rich abundance of choices and competing over whose fantasy engages the public mind most.",
	},
	{
		question: "Who is this platform for?",
		answer:
			"Don't know how to begin a story or continue your idea? Look no further! Cornuvotia is a platform for all writing enthusiasts. It offers the opportunity for collaborative and competitive creative work. Collaborative in the sense that you don't write a story on your own here, but suggest sequels to existing stories created by other users. Competitive in the sense that you should do your best, because other users also provide suggestions for sequels and each story only gets one sequel at a time (branching excluded). The best sequel, which then becomes part of the story, is decided by a voting system in which you can also participate.",
	},
	{
		question: "How do I join the platform?",
		answer:
			"You can join by signing up with an email and a unique username. Creating an account is necessary to participate in both writing and voting.",
	},
	{
		question: "Who can create a story?",
		answer:
			"Any registered user can create a story. You can create more stories as you gain levels on the platform, which is achieved by winning votes and contributing a certain amount of words.",
	},
	{
		question: "What are the guidelines for creating a story?",
		answer:
			"The creator sets the parameters for their story, including minimum and maximum word count, and can choose tags, write a short description to set a theme and many more. The initial and all following parts of the story must adhere to these parameters.",
	},
	{
		question: "Can I edit my story contribution?",
		answer:
			"Yes and no. You can edit your proposal during the writing cycle. After that your proposal is set in stone. During the voting cycle and when your proposal becomes a part it is no longer editable.",
	},
	{
		question: "How does the voting process work?",
		answer:
			"Voting is anonymous and open to all users. You cannot vote for your own proposal, and there's an option to limit voting to those who have participated in the story.",
	},
	{
		question:
			"What happens if multiple story proposals receive a similar number of votes?",
		answer:
			"If two or more proposals receive a certain percentage of votes (set by the story creator), the story can branch into independent stories. Users can contribute to multiple branches.",
	},
	{
		question: "What happens to a story after the set number of cycles?",
		answer:
			"After reaching the predetermined number of cycles, the story is archived and still accessible for reading or downloading. Participants receive achievements for various activities like proposing, voting, and more.",
	},
	{
		special: 2,
		question: "Is there a way to discuss stories outside the platform?",
		answer:
			"The platform itself does not offer a forum for discussion to minimize certain problems. However, there is a Discord server for the community to interact.",
	},
	{
		special: 3,
		question: "How can I provide feedback about the platform?",
		answer:
			"Users can provide feedback via the feedback page or on the Discord server.",
	},
	{
		question:
			"Why are some/most parts look like they are designed/developed by a prodigy chimpanzee?",
		answer: "'We' are a one man developer team and this is a hobby.",
	},
	{
		special: 1,
		question:
			"Can someone explain the core features of this platform without stupid cards?",
		answer: "Most certainly!, said the chimpanzee. ",
	},
];

function FaqComponent() {
	return (
		<div style={{ margin: "auto", paddingTop: "50px" }}>
			<h2
				style={{ textAlign: "center", marginBottom: "20px" }}
				className="themed-text"
			>
				Frequently Asked Questions
			</h2>
			<Accordion defaultActiveKey="0" flush>
				{FAQ.map((faq, index) => {
					return (
						<Accordion.Item eventKey={`${index}`} key={index}>
							<Accordion.Header>
								<b>{faq.question}</b>
							</Accordion.Header>
							<Accordion.Body>
								{faq.answer}
								{faq.special && (
									<>
										{faq.special === 1 && (
											<Link to="/about" state={{ openedItem: "guide" }}>
												Platform Guide
											</Link>
										)}
										{faq.special === 2 && (
											<>
												<Discord className="mx-1" />
												<Link to="https://discord.gg/grwJAzg8PH">
													Discord server
												</Link>
											</>
										)}
										{faq.special === 3 && (
											<>
												<div>
													<Link to="feedback">Feedback page</Link>
												</div>
												<div>
													<Link to="https://discord.gg/grwJAzg8PH">
														Discord server
														<Discord className="mx-1" />
													</Link>
												</div>
											</>
										)}
									</>
								)}
							</Accordion.Body>
						</Accordion.Item>
					);
				})}
			</Accordion>
		</div>
	);
}

export { FaqComponent };
