const tags = [
	"Adventure",
	"Alien",
	"Anthology",
	"Artificial intelligence",
	"Assassination",
	"Autobiography",
	"Ballad",
	"Betrayal",
	"Bildungsroman",
	"Biography",
	"Chick lit",
	"Comedy",
	"Coming of age",
	"Conspiracy",
	"Contemporary romance",
	"Courtroom drama",
	"Crime",
	"Cult",
	"Cybernetics",
	"Cyberpunk",
	"Dark fantasy",
	"Detective",
	"Diary",
	"Dragon",
	"Drama",
	"Dystopian",
	"Elegy",
	"Epic journey",
	"Epic poem",
	"Epistolary",
	"Erotic romance",
	"Espionage",
	"Essay",
	"Fairy tale",
	"Family saga",
	"Fantasy world",
	"Farce",
	"Flash fiction",
	"Forbidden love",
	"Free verse",
	"Friendship",
	"Ghost",
	"Gothic",
	"Graphic novel",
	"Haiku",
	"Hard science fiction",
	"Heist",
	"High fantasy",
	"Historical event",
	"Historical romance",
	"Horror",
	"Legal thriller",
	"Letter",
	"Limerick",
	"Locked room mystery",
	"Love triangle",
	"Magic",
	"Magical realism",
	"Medical thriller",
	"Medieval",
	"Memoir",
	"Military science fiction",
	"Modernism",
	"Monster",
	"Multiverse",
	"Musical",
	"Mystery",
	"Mythology",
	"Narrative poem",
	"Noir",
	"Novel",
	"Novella",
	"Ode",
	"Opera",
	"Parody",
	"Philosophical",
	"Pirate",
	"Play",
	"Poetry",
	"Police procedural",
	"Political intrigue",
	"Post-apocalyptic",
	"Postmodernism",
	"Prophecy",
	"Psychological",
	"Psychological thriller",
	"Quest",
	"Realism",
	"Rebellion",
	"Robot",
	"Romance",
	"Romantic suspense",
	"Royal court",
	"Satire",
	"School life",
	"Secret society",
	"Self-help",
	"Short story",
	"Slapstick",
	"Soft science fiction",
	"Sonnet",
	"Space exploration",
	"Speech",
	"Spy",
	"Steampunk",
	"Stream of consciousness",
	"Superhero",
	"Supernatural",
	"Survival",
	"Techno-thriller",
	"Terraforming",
	"Time paradox",
	"Time travel",
	"Tragedy",
	"Tragicomedy",
	"Travelogue",
	"Treasure hunt",
	"Underworld",
	"Urban fantasy",
	"Utopia/Dystopia",
	"Vampire",
	"War",
	"Werewolf",
	"Whodunit",
	"Witch/Wizard",
	"Zombie",
];

export default tags;
