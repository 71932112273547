import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

const KofiPanel = ({ height = "46px", marginTop = "7px" }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);

	return (
		<>
			<img
				style={{
					border: "0px",
					height: height,
					cursor: "pointer",
					marginTop: marginTop,
				}}
				src="https://storage.ko-fi.com/cdn/kofi4.png?v=3"
				alt="Buy Me a Coffee at ko-fi.com"
				onClick={() => setShow(true)}
				onKeyUp={() => setShow(true)}
			/>

			<Modal
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Support Me on Ko-fi
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{ background: <Spinner /> }}>
						<div className="mb-2">
							Thank you for considering donation. Enter your{" "}
							<b>Cornuvotia account name</b> as "name or nickname" to receive a
							special donor's badge (available 24h after donation).
						</div>
						<iframe
							id="kofiframe"
							src="https://ko-fi.com/cornuvotia/?hidefeed=true&widget=true&embed=true&preview=true"
							style={{
								border: "none",
								width: "100%",
								height: "712px",
								background: "#f9f9f9",
							}}
							title="cornuvotia"
						/>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default KofiPanel;
