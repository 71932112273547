import React, { useEffect, useState } from "react";
import {
	Badge,
	Button,
	ButtonGroup,
	Card,
	Col,
	Container,
	Row,
	Table,
} from "react-bootstrap";
import { GraphUp } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useUser } from "./contexts/UserContext.tsx";
import PendingRequestSpinner from "./ressources/PendingRequestSpinner.js";
import { urls } from "./ressources/urls.js";
import dayjs from "dayjs";

interface LeaderboardEntry {
	bow: Array<[string, number]>;
	users_languages: Array<[string, number]>;
	users_n_tokens: Array<[string, number]>;
	users_n_unique_words: Array<[string, number]>;
}

interface Leaderboards {
	[key: string]: LeaderboardEntry;
}

interface RootObject {
	leaderboards: Leaderboards;
}

function getLeaderBoardTitle(key: string) {
	const titles = {
		users_n_tokens: "Words written",
		users_n_unique_words: "Unique words written",
		users_languages: "Languages",
		bow: "Most common words",
	};
	return titles[key];
}

function LeaderboardPage() {
	const { user } = useUser();
	const [leaderboardData, setLeaderboardData] = useState<RootObject | null>(
		() => {
			if (
				window.sessionStorage.getItem("LeaderboardDataCreationDay") ===
				dayjs().get("day").toString()
			) {
				const storage = window.sessionStorage.getItem("LeaderboardData");
				if (storage) {
					return JSON.parse(storage);
				}
			}
			return null;
		},
	);
	const [activeInterval, setActiveInterval] = useState("1");
	const [jobberRequestError, setJobberRequestError] = useState(false);

	const handleChangeInterval = (interval: string) => {
		setActiveInterval(interval);
	};

	const renderRow = (
		userObject: (string | number)[],
		index: number,
		key: string,
	) => {
		let className = "";
		if (userObject[0] === user?.name) {
			className = "bg-write";
		}

		let entity_tag = <Badge bg="secondary">{userObject[0]}</Badge>;
		if (key !== "bow") {
			entity_tag = <Link to={`/profile/${userObject[0]}`}>{entity_tag}</Link>;
		}

		if (index > 2) {
			return (
				<tr key={userObject[0]} className={className}>
					<td>{index + 1}</td>
					<td>{entity_tag}</td>
					<td>{userObject[1]}</td>
				</tr>
			);
		}
	};

	const renderNameTag = (key: string, userName: string, number: number) => {
		if (key === "bow") {
			return (
				<>
					<Badge bg="dark">{userName}</Badge> {number}
				</>
			);
		}
		return (
			<>
				<Link to={`/profile/${userName}`}>
					<Badge bg="dark">{userName}</Badge>
				</Link>{" "}
				{number}
			</>
		);
	};

	useEffect(() => {
		const fetchLeaderboardData = async () => {
			try {
				const response = await fetch(urls.baseUrl + urls.getLeaderboards); // URL to your backend endpoint
				if (!response.ok) {
					setJobberRequestError(true);
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const data = await response.json();
				if (data.leaderboards) {
					setLeaderboardData(data.leaderboards);
					window.sessionStorage.setItem(
						"LeaderboardDataCreationDay",
						dayjs().get("date").toString(),
					);
					window.sessionStorage.setItem(
						"LeaderboardData",
						JSON.stringify(data.leaderboards),
					);
				} else {
					setJobberRequestError(true);
				}
			} catch (error) {
				setJobberRequestError(true);
				console.error("Error fetching leaderboard data:", error);
			}
		};
		if (leaderboardData === null) {
			fetchLeaderboardData();
		}
	}, []);

	return (
		<div className="LeaderboardPage">
			<Container fluid className="mb-5">
				<div className="d-flex justify-content-center">
					<Card className="mt-3 feature-card">
						<Card.Body>
							<GraphUp size={32} />
							<Card.Title>Witness the best. Climb the ranks.</Card.Title>
							<Card.Text>
								Check out the leaderboards for certain categories like "Unique
								words written".
								<br />
								Adjust the time span from 1 to 365 five days to see who
								dominated the past days or year.
								<br />
								<div className="text-muted">Updated every 24 h</div>
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
				{leaderboardData ? (
					<>
						<Row>
							<ButtonGroup className="my-3">
								{Object.keys(leaderboardData).map((interval) => (
									<Button
										key={interval}
										variant={
											interval === activeInterval ? "primary" : "secondary"
										}
										onClick={() => handleChangeInterval(interval)}
									>
										{interval} days
									</Button>
								))}
							</ButtonGroup>
						</Row>
						<Row>
							{Object.entries(
								leaderboardData[activeInterval] as {
									[key: string]: Array<[string, number]>;
								},
							).map((entry) => {
								const entities = entry[1];
								if (entities.length < 3) {
									return <div />;
								}
								return (
									<Col key={entry[0]} xs={12} lg={6}>
										<div className="display-6 mb-2 themed-text">
											{getLeaderBoardTitle(entry[0])}
										</div>
										<Row>
											<Col className="d-flex justify-content-center">
												<div
													className="display-6 mb-2 feature-card p-2"
													style={{ backgroundColor: "gold" }}
												>
													# 1{" "}
													{renderNameTag(
														entry[0],
														entities[0][0],
														entities[0][1],
													)}
												</div>
											</Col>
										</Row>
										<Row>
											<Col className="d-flex justify-content-center">
												<div
													className="display-6 mb-2 feature-card p-2"
													style={{ backgroundColor: "#c0c0c0" }}
												>
													#2{" "}
													{renderNameTag(
														entry[0],
														entities[1][0],
														entities[1][1],
													)}
												</div>
											</Col>
											<Col className="d-flex justify-content-center">
												<div
													className="display-6 mb-2 feature-card p-2"
													style={{ backgroundColor: "#b08d57" }}
												>
													#3{" "}
													{renderNameTag(
														entry[0],
														entities[2][0],
														entities[2][1],
													)}
												</div>
											</Col>
										</Row>
										<div style={{ overflow: "auto", maxHeight: "600px" }}>
											<Table striped bordered hover variant="light">
												<thead>
													<tr>
														<th>#</th>
														<th>Writer</th>
														<th>{getLeaderBoardTitle(entry[0])}</th>
													</tr>
												</thead>
												<tbody>
													{entities.map((e, index) =>
														renderRow(e, index, entry[0]),
													)}
												</tbody>
											</Table>
										</div>
									</Col>
								);
							})}
						</Row>
					</>
				) : (
					<div className="mt-2">
						<PendingRequestSpinner serverError={jobberRequestError} />
					</div>
				)}
			</Container>
		</div>
	);
}

export default LeaderboardPage;
