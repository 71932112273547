import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../images/icon.svg"; // Ensure the path is correct
import { Discord, Twitter } from "react-bootstrap-icons";
import KofiPanel from "./KofiPanel";

function Footer() {
	return (
		<footer className="footer" style={{ padding: "5px 0" }}>
			<Container>
				<Row className="align-items-center">
					<Col xs={12} md={4} className="text-center">
						<img
							src={logo}
							alt="Website Logo"
							style={{ height: "30px" }}
							className="svg-filter-white"
						/>
						<span className="ml-2 themed-text">Cornu</span>
						<span className="ml-2" style={{ color: "#ffa600" }}>
							votia
						</span>
					</Col>
					<Col xs={12} md={4} className="text-md-left text-center">
						<Link to="/impressum" className="themed-text">
							Impressum
						</Link>
						<Link to="/privacypolicy" className="themed-text  ms-3">
							Privacy policy
						</Link>
						<Link to="/changelog" className="themed-text ms-3">
							Changelog
						</Link>
					</Col>
					<Col xs={12} md={4} className="text-md-right text-center">
						<a
							href="https://www.twitter.com/cornuvotia"
							target="_blank"
							rel="noopener noreferrer"
							className="text-dark mr-2"
						>
							<Twitter className="me-2 themed-text" size={25} />
						</a>
						<a
							href="https://discord.gg/grwJAzg8PH"
							target="_blank"
							rel="noopener noreferrer"
							className="text-dark"
						>
							<Discord className="themed-text me-5" size={25} />
						</a>
						<KofiPanel height="30px" marginTop="0px" />
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;
