import { DateTime } from "luxon";
import { ImageProvider } from "./ImageProvider";
import { urls } from "./urls";
import dayjs from "dayjs";

function toLocalISOString(date) {
	const tzoffset = date.utcOffset() * 60000; // offset in milliseconds
	const localISOTime = date
		.add(tzoffset, "millisecond")
		.toISOString()
		.slice(0, 16);
	return localISOTime;
}

const postJson = async (url, body) => {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(body),
	};

	const response = await fetch(urls.baseUrl + url, requestOptions);
	if (response.ok) {
		return await response.json();
	}
	return null;
};

const removeItemFromList = (setter, getter, id) => {
	const list = getter.filter((row) => row.id !== id);
	setter(list);
};

function convertMinutesToTime(minutes) {
	let remainingMinutes = minutes;
	const d = Math.floor(remainingMinutes / 1440); // 60 minutes * 24 hours
	remainingMinutes -= d * 1440;
	const h = Math.floor(remainingMinutes / 60);
	remainingMinutes -= h * 60;
	const m = Math.floor(remainingMinutes);

	return `${d} days, ${h} hours, ${m} minutes`;
}

function getBackgroundImageStyle(name, vh = 100) {
	const background = ImageProvider.backgrounds[`background_${name}.jpg`];
	return {
		backgroundImage: `url(${background})`,
		height: `${vh}vh`,
		backgroundSize: "cover",
	};
}

function shortenDate(dateString) {
	const date = dayjs(dateString);
	const formattedDate = date.format("DD.MM.YYYY HH:mm");
	return formattedDate;
}

function compareDbDateToNow(dateString, bigger) {
	const date = DateTime.fromRFC2822(dateString, { zone: "utc" });
	const now = DateTime.utc().plus({ hours: 1 });
	if (bigger) {
		return date > now;
	}
	return date < now;
}

function levelToIcon(level) {
	const maxLevel = 10000;
	const iconLevels = [1, 10, 100, 500, 1000, maxLevel]; // Levels at which the icon changes, needs to be logarithmically spaced
	// Define icons
	const iconNames = [
		"feather_grey.svg",
		"feather_green.svg",
		"feather_orange.svg",
		"feather_red.svg",
		"feather_purple.svg",
	];
	const icons = iconNames.map((name) => ImageProvider.icons[name]);

	let iconName;
	let repetitions;

	// Find the highest level that is less than or equal to the user's level
	for (let i = 0; i < iconLevels.length; i++) {
		if (level < iconLevels[i]) {
			iconName = icons[i - 1];
			// Calculate the number of repetitions based on the remainder of the division
			if (Math.floor(level) === 2) {
				repetitions = 2;
			} else {
				repetitions =
					Math.floor(
						(level - iconLevels[i - 1]) /
							((iconLevels[i] - iconLevels[i - 1]) / 5),
					) + 1;
			}
			break;
		}
	}

	// If the level is higher than all the defined levels, use the last icon and maximum repetitions
	if (!iconName) {
		iconName = icons[icons.length - 1];
		repetitions = 5;
	}

	return [iconName, repetitions];
}

function createOffenseReport(reported_id, reported_type, content) {
	return postJson(urls.createOffenseReport, {
		reported_id: reported_id,
		reported_type: reported_type,
		content: content,
	});
}

// SLATE FUNCTIONS

function countSlateObjectWords(slateObject) {
	return slateObject
		.map((paragraph) => paragraph.children.map((node) => node.text).join(""))
		.join("\n")
		.replace(/\s{2,}/, " ")
		.split(" ").length;
}

function trimSlateContentInPlace(paragraphs) {
	let i = -1;
	while (
		paragraphs.length > i + 1 &&
		paragraphs[i + 1].children.length === 1 &&
		paragraphs[i + 1].children[0].text.trim() === ""
	) {
		i += 1;
	}
	i += 1;
	let j = paragraphs.length - 1;
	while (
		paragraphs[j].children.length === 1 &&
		paragraphs[j].children[0].text.trim() === "" &&
		j > i
	) {
		j -= 1;
	}
	j += 1;

	return paragraphs.slice(i, j);
}

function slateObjectToText(slateObject) {
	if (slateObject === null || slateObject === undefined) {
		return "";
	}
	const paragraphs = slateObject.map((paragraph) => {
		return paragraph.children.map((node) => node.text).join("");
	});
	return paragraphs.join("\n");
}

function getThresholdColorForRatio(ratio) {
	if (ratio === 1) {
		return "secondary";
	}
	if (ratio > 0.66) {
		return "warning";
	}
	return "light";
}

const toastColors = {
	proposal_good: "bg-write",
	proposal_bad: "bg-white",
	vote_good: "bg-vote",
	vote_bad: "bg-white",
	achievement: "bg-warning",
	new: "bg-write",
	praises: "bg-write",
	story_delete: "bg-danger",
	collaborator_good: "bg-white",
	collaborator_bad: "bg-danger",
	story_bad: "bg-white",
	story_good: "bg-explore",
};

const toastHeaders = {
	proposal_good: "Good news, everyone!",
	proposal_bad: "Next time",
	vote_good: "Majority vote!",
	vote_bad: "A quiet place",
	achievement: "New achievement!",
	new: "Hello there!",
	praises: "Some pats on the back!",
	story_delete: "Story deleted",
	collaborator_good: "You were invited!",
	collaborator_bad: "Collaboration ended",
	story_bad: "No proposals this cycle",
	story_good: "It's done!",
};

function generateUsername() {
	const creatures = [
		"Yuan-ti",
		"Roc",
		"Stirge",
		"Wendigo",
		"Boggart",
		"Wyvern",
		"Dryad",
		"Dragon",
		"Basilisk",
		"Aboleth",
		"Griffin",
		"Ent",
		"Rakshasa",
		"Rusalka",
		"Buckawn",
		"Gargoyle",
		"Banshee",
		"Sprite",
		"Ghoul",
		"Merman",
		"Wyrm",
		"Angel",
		"Yeti",
		"Pixie",
		"Imp",
		"Sylph",
		"Brownie",
		"Sahuagin",
		"Jinn",
		"Zombie",
		"Medusa",
		"Pegasus",
		"Kappa",
		"Aswang",
		"Peryton",
		"Mothman",
		"Elf",
		"Troll",
		"Gorgon",
		"Lamassu",
		"Korrigan",
		"Bullywug",
		"Giant",
		"Kraken",
		"Lycan",
		"Lich",
		"Oni",
		"Centaur",
		"Goblin",
		"Kobold",
		"Striga",
		"Hydra",
		"Knucker",
		"Roper",
		"Faun",
		"Archon",
		"Orc",
		"Chimera",
		"Satori",
		"Cyclops",
		"Barghest",
		"Siren",
		"Bulette",
		"Gnoll",
		"Ettin",
		"Wemic",
		"Zorn",
		"Fomorian",
		"Behir",
		"Valkyrie",
		"Nephilim",
		"Ogre",
		"Golem",
		"Baku",
		"Kelpie",
		"Bogeyman",
		"Djinn",
		"Vampire",
		"Unicorn",
		"Phoenix",
		"Satyr",
		"Naga",
		"Tengu",
		"Quasit",
		"Kenku",
		"Minotaur",
		"Harpy",
		"Cerberus",
		"Leshy",
		"Lupine",
		"Bugbear",
		"Beholder",
		"Wraith",
		"Mermaid",
		"Werewolf",
		"Fairy",
		"Kitsune",
		"Otyugh",
		"Taniwha",
		"Narwhal",
		"Couatl",
		"Dwarf",
		"Genie",
		"Sphinx",
		"Nymph",
		"Myconid",
		"Behemoth",
		"Wight",
		"Undine",
	];

	const adjectives = [
		"Bulky",
		"Eternal",
		"Forceful",
		"Titanic",
		"Void",
		"Sincere",
		"Crimson",
		"Vicious",
		"Silver",
		"Strong",
		"Resolute",
		"Vast",
		"Tangible",
		"Valiant",
		"Sturdy",
		"Infernal",
		"Rigid",
		"Emerald",
		"Concrete",
		"Tough",
		"Astral",
		"Ancient",
		"Temporal",
		"Compact",
		"Clear",
		"Stellar",
		"Hefty",
		"Nether",
		"Manifest",
		"Truthful",
		"Seraphic",
		"Gleaming",
		"Brave",
		"Immortal",
		"Fiendish",
		"Robust",
		"Demonic",
		"Colossal",
		"Magical",
		"Massive",
		"Solid",
		"Primeval",
		"Lunar",
		"Phantom",
		"Radiant",
		"Weighty",
		"Armored",
		"Devoted",
		"Faithful",
		"Thick",
		"Reliable",
		"Occult",
		"Powerful",
		"Mythical",
		"Distinct",
		"Palpable",
		"Blessed",
		"Evident",
		"Shielded",
		"Heroic",
		"Primal",
		"Fearless",
		"Open",
		"Firm",
		"Fiery",
		"Immense",
		"Amber",
		"Durable",
		"Dense",
		"Stiff",
		"Apparent",
		"Obvious",
		"Luminous",
		"Daring",
		"Galactic",
		"Savage",
		"Obsidian",
		"Intrepid",
		"Abyssal",
		"Ethereal",
		"Intense",
		"Sinister",
		"Shadowy",
		"Cosmic",
		"Heavenly",
		"Infinite",
		"Mystical",
		"Real",
		"Visible",
		"Hardy",
		"Vigorous",
		"Spectral",
		"Solar",
		"Divine",
		"True",
		"Frank",
		"Glowing",
		"Undying",
		"Stalwart",
		"Candid",
		"Majestic",
		"Hallowed",
		"Heavy",
		"Enormous",
		"Golden",
		"Empyrean",
		"Cryptic",
		"Elysian",
		"Genuine",
		"Arcane",
		"Potent",
		"Eldritch",
		"Loyal",
		"Honest",
		"Sapphire",
		"Fierce",
		"Cursed",
	];

	const randomCreature =
		creatures[Math.floor(Math.random() * creatures.length)];
	const randomAdjective =
		adjectives[Math.floor(Math.random() * adjectives.length)];
	const randomNumber = Math.floor(Math.random() * 100)
		.toString()
		.padStart(2, "0");

	return `${randomAdjective}${randomCreature}${randomNumber}`;
}

export {
	getThresholdColorForRatio,
	slateObjectToText,
	compareDbDateToNow,
	convertMinutesToTime,
	createOffenseReport,
	getBackgroundImageStyle,
	levelToIcon,
	postJson,
	shortenDate,
	toLocalISOString,
	removeItemFromList,
	countSlateObjectWords,
	toastColors,
	toastHeaders,
	trimSlateContentInPlace,
	generateUsername,
};
