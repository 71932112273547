import React, { useEffect, useRef, useState } from "react";
import {
	Badge,
	Button,
	Col,
	Image,
	InputGroup,
	Modal,
	Row,
	ToggleButton,
} from "react-bootstrap";
import {
	ArrowDownCircle,
	Eye,
	EyeSlash,
	Files,
	Fullscreen,
	FullscreenExit,
} from "react-bootstrap-icons";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/UserContext.tsx";
import AchievementBadgesComponent from "./AchievementBadgesComponent";
import { TextBoxStyle } from "./CustomStyles";
import "./CustomStyles.css";
import { ImageProvider } from "./ImageProvider";
import SlateReadOnly from "./SlateReadOnly";
import { VerticallyCenteredModal } from "./VerticallyCenteredModal";
import {
	postJson,
	createOffenseReport,
	levelToIcon,
	shortenDate,
	slateObjectToText,
} from "./utils";
import { Tooltip } from "react-tooltip";
import { urls } from "../ressources/urls.js";

function StoryAuthorsCard({
	storyParts,
	story,
	userIsCreator,
	preselectedPartId = null,
}) {
	const { user } = useUser();
	const memoryPostion = Number.parseInt(
		window.localStorage.getItem(
			`StoryPositionMemory${story.id}_${story.branch_id}`,
		),
	);
	const [rememberPositionChecked, setRememberPositionChecked] = useState(
		Number.isInteger(memoryPostion),
	);
	const [activeItem, setActiveItem] = useState(null);
	const [activeIndex, setActiveIndex] = useState(
		Number.isInteger(memoryPostion) ? memoryPostion : 0,
	);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [userReportedPart, setUserReportedPart] = useState(false);
	const reportCacheRef = useRef({});
	const [showEmptyParts, setShowEmptyParts] = useState(false);
	const [activeViewIndex, setActiveViewIndex] = useState(
		Number.isInteger(memoryPostion) ? memoryPostion : 0,
	);
	const [paginationMode, setPaginationMode] = useState(
		preselectedPartId !== null,
	);
	const [page, setPage] = useState(
		Number.isInteger(memoryPostion) ? memoryPostion : 0,
	);
	const [firstPartOfBranchId, setFirstPartOfBranchId] = useState(0);
	const [partsToRender, setPartsToRender] = useState(storyParts);

	const refs = storyParts ? storyParts.map(() => React.createRef()) : [];
	const nonEmptyParts = storyParts
		? storyParts.filter((part) => {
				return !slateObjectToText(part.content).startsWith(
					"NO PROPOSALS FOR PART",
				);
			})
		: [];
	// const partsToRender = showEmptyParts ? storyParts : nonEmptyParts;

	const cardTextRef = useRef(null);
	const level_icon_info = levelToIcon(activeItem?.creator_info.level);

	const getStoredPosition = () => {
		Number.parseInt(
			window.localStorage.getItem(
				`StoryPositionMemory${story.id}_${story.branch_id}`,
			),
		);
	};

	function getOffsetTop(element) {
		let offsetTop = 0;
		while (element) {
			offsetTop += element.offsetTop;
			element = element.offsetParent;
		}
		return offsetTop;
	}

	function setStoredPosition(index, rememberIsChecked) {
		if (rememberIsChecked) {
			window.localStorage.setItem(
				`StoryPositionMemory${story.id}_${story.branch_id}`,
				index,
			);
		}
	}

	const scrollToItem = (index) => {
		if (refs.length > 0) {
			const topPos =
				getOffsetTop(refs[index].current) - getOffsetTop(cardTextRef.current);
			// Scroll the container div to the item's position
			cardTextRef.current.scrollTop = topPos;
		}
	};

	useEffect(() => {
		const currentParts = showEmptyParts ? storyParts : nonEmptyParts;
		setPartsToRender(currentParts);
		if (currentParts.length > 0 && activeIndex >= currentParts.length) {
			setActiveIndex(currentParts.length - 1);
			setActiveViewIndex(currentParts.length - 1);
			setPage(currentParts.length - 1);
		}
		// setActiveItem(item);
	}, [showEmptyParts, storyParts]);

	useEffect(() => {
		if (paginationMode) {
			if (
				preselectedPartId &&
				storyParts.length > 0 &&
				partsToRender.length > 0
			) {
				setPage(
					partsToRender.findIndex((part) => part.id === preselectedPartId),
				);
			} else if (Number.isInteger(getStoredPosition())) {
				setPage(getStoredPosition());
			} else {
				if (
					partsToRender.length > 0 &&
					activeViewIndex < partsToRender.length
				) {
					setPage(activeViewIndex);
				} else {
					setPage(0);
				}
			}
		}
		for (let i = 0; i < storyParts.length; i++) {
			if (storyParts[i].branch_id === story.branch_id) {
				setFirstPartOfBranchId(storyParts[i].id);
				break;
			}
		}
		if (paginationMode === false) {
			scrollToItem(page);
		} else {
			setActiveItem(storyParts[page]);
			setActiveIndex(page);
		}
	}, [storyParts, paginationMode]);

	const handleRememberPosition = (checked) => {
		setRememberPositionChecked(checked);
		if (checked) {
			setStoredPosition(activeViewIndex, true);
		} else {
			window.localStorage.removeItem(
				`StoryPositionMemory${story.id}_${story.branch_id}`,
			);
		}
	};

	const handleOnClickStoryPart = (item, index) => {
		setActiveItem(item);
		setActiveIndex(index);
		setActiveViewIndex(index);
		if (rememberPositionChecked) {
			setStoredPosition(index, true);
		}

		const cacheKey = `${item.id}-part`;
		if (reportCacheRef.current[cacheKey] !== undefined) {
			setUserReportedPart(reportCacheRef.current[cacheKey]);
			return;
		}
		postJson(urls.getUserOffenseReportForId, {
			reported_id: item.id,
			reported_type: "part",
		}).then((reportData) => {
			reportCacheRef.current[cacheKey] = reportData?.reports.length > 0;
			setUserReportedPart(reportData?.reports.length > 0);
		});
	};

	const handleOnClickPaginationMode = (isPagination) => {
		setPaginationMode(isPagination);
	};

	function handleReportPart(text) {
		createOffenseReport(activeItem.id, "part", text).then((data) => {
			const cacheKey = `${activeItem.id}-part`;
			reportCacheRef.current[cacheKey] = true;
			setReportModalShow(false);
			setUserReportedPart(true);
		});
	}

	function renderStoryPart(index, item) {
		const span = (
			<span key={item.id}>
				{item.needs_replacement ? (
					"<SORRY, THIS PART WAS DELETED BECAUSE IT VIOLATED THE GUIDELINES. THE STORY CREATOR NEEDS TO REPLACE IT>"
				) : (
					<SlateReadOnly initialValue={item.content} />
				)}
			</span>
		);
		let render = (
			<div
				key={item.id}
				ref={refs[index]}
				onClick={() => {
					handleOnClickStoryPart(item, index);
				}}
				onKeyUp={() => {
					handleOnClickStoryPart(item, index);
				}}
				className="mb-2 me-1 bg-white rounded p-3"
				style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}
			>
				{span}
			</div>
		);
		if (item.id === firstPartOfBranchId) {
			render = (
				<div
					key={item.id}
					ref={refs[index]}
					onClick={() => {
						handleOnClickStoryPart(item, index);
					}}
					onKeyUp={() => {
						handleOnClickStoryPart(item, index);
					}}
					className="mb-2 me-1 bg-white rounded p-3"
					style={{
						borderTop: "7px solid #F7BB07",
						boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
					}}
				>
					{span}
				</div>
			);
		}
		return render;
	}

	const SAC = (
		<div style={{ maxWidth: "900px" }}>
			<div className="d-flex align-items-center justify-content-between">
				<div className="mb-2 display-6 themed-text">The story</div>
				<div>
					<InputGroup>
						<div
							className="themed-text d-flex align-items-end"
							style={{ cursor: "pointer" }}
						>
							{paginationMode ? (
								<ArrowDownCircle
									size={22}
									className="me-2 mb-2"
									onClick={() => handleOnClickPaginationMode(false)}
									data-tooltip-id="pagination-tooltip"
									data-tooltip-html="Change reading mode to: <b>Flow</b>"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							) : (
								<Files
									size={22}
									className="me-2 mb-2"
									onClick={() => handleOnClickPaginationMode(true)}
									data-tooltip-id="pagination-tooltip"
									data-tooltip-html="Change reading mode to: <b>Pagination</b>"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							)}
							{isFullscreen ? (
								<FullscreenExit
									size={22}
									className="me-2 mb-2"
									onClick={() => setIsFullscreen(false)}
									data-tooltip-id="fullscreen-tooltip"
									data-tooltip-html="Exit <b>fullscreen</b>fullscreen"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							) : (
								<Fullscreen
									size={22}
									className="me-2 mb-2"
									onClick={() => setIsFullscreen(true)}
									data-tooltip-id="fullscreen-tooltip"
									data-tooltip-html="Enter <b>fullscreen</b>"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							)}
							{showEmptyParts ? (
								<EyeSlash
									size={22}
									className="me-2 mb-2"
									onClick={() => {
										setShowEmptyParts(false);
									}}
									data-tooltip-id="empty-tooltip"
									data-tooltip-html="<b>Hide</b> empty parts"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							) : (
								<Eye
									size={22}
									className="me-2 mb-2"
									onClick={() => setShowEmptyParts(true)}
									data-tooltip-id="empty-tooltip"
									data-tooltip-html="<b>Show</b> empty parts"
									data-tooltip-variant="success"
									data-tooltip-place="left"
								/>
							)}
							<Tooltip id="pagination-tooltip" />
							<Tooltip id="fullscreen-tooltip" />
							<Tooltip id="empty-tooltip" />
						</div>
						<InputGroup.Text style={{ background: "#6074A1", color: "white" }}>
							<b>
								{paginationMode ? page + 1 : activeViewIndex + 1}/
								{partsToRender.length}
							</b>
						</InputGroup.Text>
						<ToggleButton
							data-tooltip-id="remember-tooltip"
							data-tooltip-html={`Toggle on to remember reading position (Currently: ${
								Number.parseInt(
									window.localStorage.getItem(
										`StoryPositionMemory${story.id}_${story.branch_id}`,
									),
								) + 1
							}/${String(partsToRender.length)})`}
							data-tooltip-variant="success"
							data-tooltip-place="left"
							id="remember-check"
							type="checkbox"
							variant="outline-primary"
							className="ms-1"
							checked={rememberPositionChecked}
							value="0"
							onChange={(e) => {
								handleRememberPosition(e.currentTarget.checked);
							}}
						>
							Remember
						</ToggleButton>
						<Tooltip id="remember-tooltip" />
					</InputGroup>
				</div>
			</div>
			<div className="mt-1">
				{paginationMode ? (
					<div
						style={{
							...TextBoxStyle,
							height: "auto",
							maxHeight: "75vh",
							fontSize: user.font_size,
						}}
					>
						{partsToRender.length > 0 &&
							renderStoryPart(page, partsToRender[page])}
					</div>
				) : (
					<div
						style={{
							...TextBoxStyle,
							height: "auto",
							maxHeight: "75vh",
							fontSize: user.font_size,
						}}
						ref={cardTextRef}
					>
						{(() => {
							return showEmptyParts && partsToRender.length > 0
								? partsToRender.map((item, index) => {
										return renderStoryPart(index, item);
									})
								: nonEmptyParts.map((item, index) => {
										return renderStoryPart(index, item);
									});
						})()}
					</div>
				)}
			</div>
			<div>
				{paginationMode && (
					<Row>
						<Col className="d-flex justify-content-center mt-2">
							<PaginationControl
								page={page + 1}
								between={3}
								total={partsToRender.length}
								limit={1}
								changePage={(page) => {
									setPage(page - 1);
									setActiveItem(partsToRender[page - 1]);
									setActiveIndex(page - 1);
									setActiveViewIndex(page - 1);
									setStoredPosition(page - 1, true);
								}}
								ellipsis={1}
								last={true}
							/>
						</Col>
					</Row>
				)}
				<div
					className={`author-card mb-2${paginationMode ? "" : " mt-2"}`}
					style={{ minHeight: "100px" }}
				>
					{activeItem ? (
						<Row>
							{activeItem?.needs_replacement && userIsCreator ? (
								<Col className="d-flex justify-content-end">
									<div>
										<Link
											to="/replace_part"
											state={{
												storyParts: storyParts,
												index: activeIndex,
												story: story,
											}}
										>
											<Button size="sm" variant="success">
												Replace part
											</Button>
										</Link>
									</div>
								</Col>
							) : (
								<Row>
									<Col>
										{activeItem && (
											<Row>
												<Col>
													<Link to={`/profile/${activeItem.creator_info.name}`}>
														<Image
															src={
																ImageProvider.avatars[
																	activeItem.creator_info.avatar
																]
															}
															thumbnail
															alt="Test"
															width="48"
															className="me-2 mb-2"
														/>
													</Link>
													<span
														className="me-1"
														style={{
															fontWeight: 350,
															lineHeight: 1.2,
															fontSize: "1.5rem",
														}}
													>
														{activeItem.creator_info.name}
													</span>
													{[...Array(level_icon_info[1])].map((e, i) => (
														<Image
															key={i}
															src={level_icon_info[0]}
															width="24"
															alt="Test"
														/>
													))}
													<AchievementBadgesComponent
														userAchievements={
															activeItem.creator_info.achievements
														}
													/>
												</Col>
											</Row>
										)}
									</Col>
									<Col>
										<div className="mb-2">
											Part created at:{" "}
											<Badge bg="secondary">
												{shortenDate(activeItem.creation_date)}
											</Badge>
										</div>
										<Button
											size="sm"
											variant="outline-danger"
											onClick={() => setReportModalShow(true)}
											disabled={userReportedPart}
										>
											Report part
										</Button>
										<VerticallyCenteredModal
											show={reportModalShow}
											onHide={() => setReportModalShow(false)}
											onConfirm={(text) => {
												handleReportPart(text);
											}}
											hastextinput="true"
											title="Create a report"
											body="Give your reasons for the report. It will be reviewed asap."
										/>
									</Col>
								</Row>
							)}
						</Row>
					) : (
						<Row>
							<Col>
								<Image
									src={ImageProvider.avatars["questionmark.svg"]}
									thumbnail
									width="48"
									className="me-2 mb-2"
								/>
								<span
									className="me-1"
									style={{
										fontWeight: 350,
										lineHeight: 1.2,
										fontSize: "1.5rem",
									}}
								>
									Writer
								</span>
								<Image
									src={ImageProvider.icons["feather_grey.svg"]}
									width="24"
									alt="Test"
								/>
								<div>
									<Badge bg="secondary">Click story part</Badge>
								</div>
							</Col>
						</Row>
					)}
				</div>
			</div>
		</div>
	);

	if (isFullscreen) {
		return (
			<Modal
				show={isFullscreen}
				fullscreen={true}
				onHide={() => setIsFullscreen(false)}
				dialogClassName="modal-100w"
			>
				<Modal.Body className="themed-component">
					<Row>
						<Col xs={12} lg={3} />
						<Col xs={12} lg={6}>
							{SAC}
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
	return <div>{SAC}</div>;
}

export default StoryAuthorsCard;
