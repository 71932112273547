import React, { useEffect, useState } from "react";
import { Col, Container, Tab, Tabs } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import roadmap from "../../shared_ressources/ROADMAP.md";
import type ChangelogEntry from "./types/types.ChangelogEntry";
import { shortenDate } from "./ressources/utils";
import { urls } from "./ressources/urls";

function ChangelogPage() {
	const [markdownContentRoadmap, setMarkdownContentRoadmap] = useState("");
	const [changelog, setChangelog] = useState<ChangelogEntry[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetch(roadmap) // Fetching local markdown file
			.then((response) => response.text())
			.then((text) => setMarkdownContentRoadmap(text));

		const fetchChangelog = async () => {
			try {
				const response = await fetch(urls.baseUrl + urls.changelog);
				const data = await response.json();
				setChangelog(data.changelog);
			} catch (error) {
				console.error("Error fetching changelog:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchChangelog();
	}, []);

	return (
		<div className="ChangelogPage">
			<Container className="mt-3 themed-text">
				<Tabs defaultActiveKey="changelog" justify>
					<Tab eventKey="changelog" title="Changelog">
						{loading ? (
							<p>Loading...</p>
						) : (
							changelog.map((entry) => (
								<div key={entry.id} className="mt-4">
									<h3>[{shortenDate(entry.creation_date)}]</h3>
									<ReactMarkdown>{entry.content}</ReactMarkdown>
								</div>
							))
						)}
					</Tab>
					<Tab eventKey="roadmap" title="Roadmap">
						<ReactMarkdown className="mt-2">
							{markdownContentRoadmap}
						</ReactMarkdown>
					</Tab>
				</Tabs>
			</Container>
		</div>
	);
}

export default ChangelogPage;
