const languages = {
	Afrikaans: "ZA", // South Africa
	Albanian: "AL", // Albania
	Arabic: "SA", // Saudi Arabia
	Armenian: "AM", // Armenia
	Azerbaijani: "AZ", // Azerbaijan
	Basque: "ES", // Spain
	Belarusian: "BY", // Belarus
	Bengali: "BD", // Bangladesh
	Bosnian: "BA", // Bosnia and Herzegovina
	Bulgarian: "BG", // Bulgaria
	Catalan: "ES", // Spain
	Chinese: "CN", // China
	Croatian: "HR", // Croatia
	Czech: "CZ", // Czech Republic
	Danish: "DK", // Denmark
	Dutch: "NL", // Netherlands
	English: "GB", // United Kingdom
	Esperanto: "EO", // Esperanto (artificial language, no country code, using EO as placeholder)
	Estonian: "EE", // Estonia
	Finnish: "FI", // Finland
	French: "FR", // France
	Ganda: "UG", // Uganda
	Georgian: "GE", // Georgia
	German: "DE", // Germany
	Greek: "GR", // Greece
	Gujarati: "IN", // India
	Hebrew: "IL", // Israel
	Hindi: "IN", // India
	Hungarian: "HU", // Hungary
	Icelandic: "IS", // Iceland
	Indonesian: "ID", // Indonesia
	Irish: "IE", // Ireland
	Italian: "IT", // Italy
	Japanese: "JP", // Japan
	Kazakh: "KZ", // Kazakhstan
	Korean: "KR", // South Korea
	Latin: "VA", // Vatican (Latin has no specific country, using Vatican as a placeholder)
	Latvian: "LV", // Latvia
	Lithuanian: "LT", // Lithuania
	Macedonian: "MK", // North Macedonia
	Malay: "MY", // Malaysia
	Maori: "NZ", // New Zealand
	Marathi: "IN", // India
	Mongolian: "MN", // Mongolia
	"Norwegian Nynorsk": "NO", // Norway
	"Norwegian Bokmal": "NO", // Norway
	Persian: "IR", // Iran
	Polish: "PL", // Poland
	Portuguese: "PT", // Portugal
	Punjabi: "IN", // India
	Romanian: "RO", // Romania
	Russian: "RU", // Russia
	Serbian: "RS", // Serbia
	Shona: "ZW", // Zimbabwe
	Slovak: "SK", // Slovakia
	Slovene: "SI", // Slovenia
	Somali: "SO", // Somalia
	Sotho: "ZA", // South Africa
	Spanish: "ES", // Spain
	Swahili: "TZ", // Tanzania
	Swedish: "SE", // Sweden
	Tagalog: "PH", // Philippines
	Tamil: "IN", // India
	Telugu: "IN", // India
	Thai: "TH", // Thailand
	Tsonga: "ZA", // South Africa
	Tswana: "ZA", // South Africa
	Turkish: "TR", // Turkey
	Ukrainian: "UA", // Ukraine
	Urdu: "PK", // Pakistan
	Vietnamese: "VN", // Vietnam
	Welsh: "GB", // United Kingdom
	Xhosa: "ZA", // South Africa
	Yoruba: "NG", // Nigeria
	Zulu: "ZA", // South Africa
};

export { languages };
