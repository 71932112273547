const CommunityGuidelines = () => (
	<div>
		<section>
			<h4>Welcome to Cornuvotia</h4>
			<p>
				Welcome to Cornuvotia, a collaborative writing platform where creativity
				and community thrive! By registering on Cornuvotia, you become part of a
				dynamic, and respectful writing community. To ensure a safe and positive
				experience for all, we ask that you carefully read and adhere to the
				following guidelines:
			</p>
		</section>

		<section>
			<h4>Respectful and Responsible Content Creation</h4>
			<ul>
				<li>
					<strong>No Excessive Violence or Graphic Content:</strong> While we
					appreciate the broad spectrum of writing, excessively violent or
					graphically disturbing content is not permitted. This includes, but is
					not limited to, detailed descriptions of gore, brutality, or content
					that glorifies violence.
				</li>
				<li>
					<strong>No Excessive Sexual Content:</strong> We aim to keep our
					platform accessible to a wide audience. As such, overtly excessive
					sexual content or any form of pornographic material is strictly
					prohibited.
				</li>
				<li>
					<strong>No Harassment or Hate Speech:</strong> Cornuvotia is a space
					for everyone. Content that harasses, threatens, demeans, or promotes
					hatred against individuals or groups based on race, ethnicity,
					national origin, religion, sex, gender, sexual orientation,
					disability, or disease is not allowed.
				</li>
			</ul>
		</section>

		<section>
			<h4>Community Engagement</h4>
			<ul>
				<li>
					<strong>Constructive Feedback:</strong> While providing feedback, be
					constructive and respectful. Avoid personal attacks or derogatory
					comments. We encourage a culture of support and constructive
					criticism.
				</li>
				<li>
					<strong>Report Violations:</strong> If you come across content that
					violates these guidelines, please report it to our moderation team. We
					rely on our community members to help maintain a safe and welcoming
					environment.
				</li>
				<li>
					<strong>Collaboration and Sharing:</strong> Celebrate creativity and
					support your fellow writers. Share ideas, collaborate on stories, and
					contribute positively to the community.
				</li>
			</ul>
		</section>

		<section>
			<h4>Consequences of Violating Guidelines</h4>
			<ul>
				<li>
					<strong>Account Review and Potential Ban:</strong> If you are found to
					be in violation of any of these guidelines, your account will be
					subject to review. Depending on the severity and nature of the
					violation, consequences may range from a warning to a permanent ban.
				</li>
				<li>
					<strong>Permanent Ban for Serious Violations:</strong> In cases of
					severe violations, such as posting content with excessive violence,
					explicit sexuality, or harassment, your account will be permanently
					banned without prior warning.
				</li>
			</ul>
		</section>

		<section>
			<h4>Agreement</h4>
			<p>
				By registering on Cornuvotia, you agree to these guidelines and commit
				to upholding the standards of our community. Your contribution to making
				Cornuvotia a safe, inclusive, and creative space is invaluable.
			</p>
		</section>
	</div>
);

export { CommunityGuidelines };
